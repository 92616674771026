<script src="../../../../../../../alias.config.js"></script>
<template>
  <r-e-dialog title="添加租房合同" :visible.sync="addDialogVisible" show-footer top="10vh" :width="rowItrstyle ? '1440px' : '724px'"
              :footerBtnName="footerBtnName"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel"
              @click-operation="clickoperation">
    <el-form ref="formPublish" label-width="125px" size="small" :model="contractData" :rules="rules"
             style="text-align:center;display: flex">
<div>
  <div class="el-descriptions__header">
    <div class="el-descriptions__title">
      <div data-v-037521bc="" class="title">承租人信息</div>
    </div>
  </div>
  <div class="flex justify-between">
    <el-form-item label="证件号码" prop="leasorIdcard" label-width="100px">
      <div class="flex justify-between" style="width: 550px">
        <el-input type="text" v-model="contractData.leasorIdcard" style="width: 480px" :maxlength="18"
                  placeholder="请填写租客证件号码"/>
        <el-button type="primary" @click="getLeasorData(contractData.leasorIdcard)">查询</el-button>
      </div>
    </el-form-item>
  </div>
  <div class="flex justify-between">
    <el-form-item ref="leasorType" label="承租人类型" prop="leasorType" label-width="100px">
      <el-select v-model="contractData.leasorType" style="width: 200px" @change="typeChange"
                 placeholder="请选择">
        <el-option label="企业" value="法人"/>
        <el-option label="个人" value="个人"/>
      </el-select>
    </el-form-item>
    <el-form-item ref="idType" label="证件类型" prop="idType">
      <el-select v-model="contractData.idType" @change="idTypeChange" style="width: 200px"
                 placeholder="请选择">
        <el-option label="税号" value="税号"/>
        <el-option label="身份证" value="身份证"/>
        <el-option label="军官证" value="军官证"/>
        <el-option label="驾驶证" value="驾驶证"/>
        <el-option label="户口簿" value="户口簿"/>
        <el-option label="其他" value="其他"/>
      </el-select>
    </el-form-item>
  </div>
  <div class="flex justify-between">
    <el-form-item label="名称" prop="leasorName" label-width="100px">
      <el-input type="text" v-model="contractData.leasorName" placeholder="请填写租客姓名"/>
    </el-form-item>
    <el-form-item label="手机号码" prop="leasorPhone">
      <el-input type="text" v-model="contractData.leasorPhone" :maxlength="13" placeholder="请填写租客手机号码"/>
    </el-form-item>
  </div>

  <div class="flex justify-between" v-if="electContractTypeEnumCode === '企业'">
    <el-form-item label="法人姓名" prop="juridicalPersonName" label-width="100px">
      <el-input type="text" v-model="contractData.juridicalPersonName" placeholder="请填写法人姓名"/>
    </el-form-item>
    <el-form-item label="法人身份证号" prop="juridicalPersonIdcard">
      <el-input type="text" v-model="contractData.juridicalPersonIdcard" :maxlength="18" placeholder="请填写法人身份证号"/>
    </el-form-item>
  </div>
  <div class="flex justify-between">
    <el-form-item label="紧急联系人" prop="contactName" label-width="100px">
      <el-input type="text" v-model="contractData.contactName" placeholder="请填写紧急联系人"/>
    </el-form-item>
    <el-form-item label="紧急联系人电话" prop="contactPhone">
      <el-input type="text" v-model="contractData.contactPhone" :maxlength="13" placeholder="请填写紧急联系人电话"/>
    </el-form-item>
  </div>
  <div class="flex justify-between">
    <el-form-item label="身份证地址" label-width="100px">
      <el-input type="text" v-model="contractData.addressId" placeholder="请填写身份证地址"/>
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input type="text" v-model="contractData.eMail" placeholder="请填写邮箱"/>
    </el-form-item>
  </div>
  <div class="el-descriptions__header">
    <div class="el-descriptions__title">
      <div data-v-037521bc="" class="title">合同信息</div>
    </div>
  </div>
  <div class="flex justify-between">
    <el-form-item label="经办人" label-width="100px" prop="manager">
      <!-- 2023年9月8号下午14:21分，天气晴，本人陈守亮遵纪守法，根据需求添加了经办人和管理人！ -->
      <el-input type="text" v-model="contractData.manager" @change="$forceUpdate()" placeholder="请填写经办人"/>
    </el-form-item>
    <el-form-item label="管理人" prop="keeper">
      <el-input type="text" v-model="contractData.keeper" @change="$forceUpdate()" placeholder="请填写管理人"/>
    </el-form-item>
  </div>

  <div class="flex justify-between">
    <el-form-item label="签约时间" label-width="100px">
      <el-date-picker v-model="contractData.signDate" type="date" format="yyyy-MM-dd" style="width: 200px"
                      value-format="yyyy-MM-dd" placeholder="选择签约时间" @change="$forceUpdate()"/>
    </el-form-item>
    <el-form-item label="月租">
      <el-input v-model="showFormAmount.rent" placeholder="请填写月租" disabled v-loading="loading"/>
    </el-form-item>
  </div>
  <div class="flex justify-between">
    <el-form-item label="押金" label-width="100px">
      <el-input v-model="showFormAmount.depositAmount" placeholder="请填写押金" disabled v-loading="loading"/>
    </el-form-item>
    <el-form-item label="服务费" v-if="isBzfOk">
      <el-input v-model="showFormAmount.serviceAmount" placeholder="请填写服务费" disabled v-loading="loading"/>
    </el-form-item>
  </div>
  <div class="flex justify-between" >
    <el-form-item label="是否生成账单" label-width="100px">
      <el-radio-group v-model="contractData.bill">
        <el-radio-button :label="true">是</el-radio-button>
        <el-radio-button :label="false">否</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="是否生成电子合同" label-width="200px" v-if="contractData.leasorType === '个人'">
      <div>
        <el-radio-group v-model="contractData.face">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </div>
    </el-form-item>
  </div>
  <div class="flex">
    <el-form-item label="合同周期" label-width="100px" prop="endDate">
      <el-date-picker type="daterange" v-model="zq" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>

    </el-form-item>
    <el-form-item label=""  label-width="10px" >
      <div>
        <div class="flex justify-between" >
          <el-input-number v-model="contractData.monthSize" style="width: 130px" :min="1" label="租聘时间"/>
          <span>(月)</span>
        </div>
      </div>
    </el-form-item>
  </div>
  <div class="flex">
<!--    <el-form-item label="人脸头像" v-if="contractData.leasorType === '个人'" prop="faceInfo" label-width="100px">-->
<!--      <upload-avatar @on-success="faceInfoUploadSuccess" :uuid="contractData.faceInfo"-->
<!--                     @on-remove="handleRemoveFile('faceInfo')"/>-->
<!--    </el-form-item>-->

  </div>
</div>


    <div class="bimgs_boder">
<!--            2024-8-20-陈守亮新增企业签约以下三行六处-->
<!--      <div class="flex" >-->
<!--        <div >-->
<!--          <el-form-item   label=""   label-width="20px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              营业执照</div>-->
<!--            <upload-avatar @on-remove="enterpriseRemove" :showTip="false" isType="营业执照" @on-success="enterpriseClick" :limit="1"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div >-->
<!--          <el-form-item label=""  label-width="20px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              法人身份证人脸面</div>-->
<!--            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证人脸面"  :showTip="false" @on-success="enterpriseClick" :limit="1"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-form-item label="" :pixel="[494,494]" label-width="-50px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              法人身份证国徽面</div>-->
<!--            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证国徽面"   :showTip="false" @on-success="enterpriseClick" :limit="1"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div v-if="electContractTypeEnumCode === '企业'">-->
<!--          <el-form-item  label=""  :pixel="[494,494]" label-width="-50px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              授权书</div>-->
<!--            <upload-pictureCard @on-remove="enterpriseRemove" :showTip="false"  isType="授权书" @on-success="enterpriseClick" :limit="3"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
      <el-form-item label="营业执照" v-if="electContractTypeEnumCode === '-1'" prop="businessLicense"
                    label-width="100px">
        <upload-avatar @on-success="businessLicenseUploadSuccess" :uuid="contractData.businessLicense"
                       @on-remove="handleRemoveFile('businessLicense')"/>
      </el-form-item>

      <div class="flex" v-if="electContractTypeEnumCode === '企业'">
        <div >
          <el-form-item   label=""   label-width="20px" class="samplePhotos">
            <div style="margin-right: 90px;width: 170px">
              <span style="color: red;margin-bottom: 20px">*</span>
              营业执照</div>
            <upload-avatar @on-remove="enterpriseRemove" :showTip="false" isType="营业执照" @on-success="enterpriseClick" :limit="1" :uuid="businessLicenseList[0]"/>
          </el-form-item>
        </div>
        <div >
          <el-form-item label=""  label-width="-50px" class="samplePhotos">
            <div style="margin-right: 90px;width: 170px">
              <span style="color: red;margin-bottom: 20px">*</span>
              法人身份证人脸面</div>
            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证人脸面"  :showTip="false" @on-success="enterpriseClick" :limit="1" :uuid="juridicalPersonFrontIdcardList[0]"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="" :pixel="[494,494]" label-width="-50px" class="samplePhotos">
            <div style="margin-right: 90px;width: 170px">
              <span style="color: red;margin-bottom: 20px">*</span>
              法人身份证国徽面</div>
            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证国徽面"   :showTip="false" @on-success="enterpriseClick" :limit="1" :uuid="juridicalPersonReverseIdcardList[0]"/>
          </el-form-item>
        </div>
      </div>



<!--      <div class="flex" v-if="electContractTypeEnumCode === '企业'">-->
<!--        <div >-->
<!--          <el-form-item label=""  label-width="20px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              法人身份证人脸面</div>-->
<!--            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证人脸面"  :showTip="false" @on-success="enterpriseClick" :limit="1"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-form-item label="" :pixel="[494,494]" label-width="-50px" class="samplePhotos">-->
<!--            <div style="margin-right: 90px;width: 170px">-->
<!--              <span style="color: red;margin-bottom: 20px">*</span>-->
<!--              法人身份证国徽面</div>-->
<!--            <upload-avatar @on-remove="enterpriseRemove" isType="法人身份证国徽面"   :showTip="false" @on-success="enterpriseClick" :limit="1"/>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
<!--      2024-8-20-陈守亮新增企业签约以下三行六处 并且优化了上传全局组件-->
      <div class="flex" v-if="electContractTypeEnumCode === '企业'">
        <div>
          <el-form-item label=""  label-width="20px" class="samplePhotos">
            <div style="margin-right: 90px;width: 170px">
              <span style="color: red;margin-bottom: 20px">*</span>
              紧急联系人身份证人脸面</div>
            <upload-avatar @on-remove="enterpriseRemove"  isType="紧急联系人身份证人脸面"  :showTip="false" @on-success="enterpriseClick" :limit="1" :uuid="contactFrontIdcardList[0]"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="" :pixel="[494,494]" label-width="-50px" class="samplePhotos">
            <div style="margin-right: 90px;width: 170px">
              <span style="color: red;margin-bottom: 20px">*</span>
              紧急联系人身份证国徽面</div>
            <upload-avatar @on-remove="enterpriseRemove"  isType="紧急联系人身份证国徽面"  :showTip="false" @on-success="enterpriseClick" :limit="1" :uuid="contactReverseIdcardList[0]"/>
          </el-form-item>
        </div>
                <div v-if="electContractTypeEnumCode === '企业'">
                  <el-form-item  label=""  :pixel="[494,494]" label-width="-50px" class="samplePhotos">
                    <div style="margin-right: 90px;width: 170px">
                      <span style="color: red;margin-bottom: 20px">*</span>
                      授权书</div>
                    <upload-pictureCard @on-remove="enterpriseRemove" :showTip="false"  isType="授权书" @on-success="enterpriseClick" :limit="3"/>
                  </el-form-item>
                </div>
      </div>



      <div class="flex" v-if="contractData.leasorType === '个人'">
        <el-form-item label="人脸头像" v-if="contractData.leasorType === '个人'" prop="faceInfo" label-width="100px">
          <upload-avatar :showTip="false"  @on-success="faceInfoUploadSuccess" :uuid="contractData.faceInfo"
                         @on-remove="handleRemoveFile('faceInfo')"/>
        </el-form-item>
        <el-form-item label="身份证人脸面" prop="frontIdcard" label-width="100px">
          <upload-avatar :showTip="false"  @on-success="frontIdcardUploadSuccess" :uuid="contractData.frontIdcard"
                         @on-remove="handleRemoveFile('frontIdcard')"/>
        </el-form-item>
        <el-form-item label="身份证国徽面" prop="reverseIdcard" label-width="100px">
          <upload-avatar  :showTip="false"  @on-success="reverseIdcardUploadSuccess" :uuid="contractData.reverseIdcard"
                         @on-remove="handleRemoveFile('reverseIdcard')"/>
        </el-form-item>
      </div>


      <!-- 房屋交割单 -->
      <div style="margin-left: 30px">
        <div class="el-descriptions__header" v-if="rowItr === 2 || rowItr === 0">
          <div class="el-descriptions__title">
            <div data-v-037521bc="" class="title">房屋交割单</div>
          </div>
<!--          <div>  (上传格式：jpg、png、jpeg、bmp、gif文件)</div>-->
          <div style="width: 200px"></div>
        </div>
        <div class="flex" v-if="rowItr === 2 || rowItr === 0">
          <div>
            <el-form-item label=""  label-width="20px" class="samplePhotos" >
             <div style="display: flex">
               <div style="width: 60px"><span style="color: red">*</span>厨房</div>
               <div><upload-avatar @on-remove="enterpriseRemove" isType="厨房"  :showTip="false" @on-success="afterUploadoen" :limit="1"/></div>
             </div>
            </el-form-item>
          </div>
          <div v-if="rowItr === 2 || rowItr === 0">
            <el-form-item label="" label-width="50px" class="samplePhotos">
              <div style="display: flex">
                <div style="width: 60px"><span style="color: red">*</span>客厅</div>
                <div> <upload-avatar @on-remove="enterpriseRemove"  isType="客厅" :showTip="false" @on-success="afterUploadoen" :limit="1"/></div>
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item label=""  label-width="50px" class="samplePhotos">
              <div style="display: flex">
                <div style="width: 60px"><span style="color: red">*</span>餐厅</div>
                <div>  <upload-avatar @on-remove="enterpriseRemove"  isType="餐厅" :showTip="false" @on-success="afterUploadoen" :limit="1"/></div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="flex" v-if="rowItr === 2 || rowItr === 0">
<!--          <div @click="itrTypePic ='餐厅'">-->
<!--            <el-form-item label="餐厅"  label-width="100px" class="samplePhotos">-->
<!--              <upload-pictureCard @on-remove="afterImgsRemove" :showTip="false" @on-success="afterUploadthree" :limit="1"/>-->
<!--            </el-form-item>-->
<!--          </div>-->
          <div>
            <el-form-item label=""  label-width="20px" class="samplePhotos">
              <div style="display: flex">
                <div style="width: 60px"><span style="color: red">*</span>卧室</div>
                <div><upload-pictureCard @on-remove="afterImgsRemove"  isType="卧室"  :showTip="false" @on-success="afterUploadoen" :limit="3"/></div>
              </div>

            </el-form-item>
          </div>
          <div>
            <el-form-item label=""  label-width="50px" class="samplePhotos">
              <div style="display: flex">
                <div style="width: 60px"><span style="color: red">*</span>其他</div>
                <div> <upload-pictureCard @on-remove="afterImgsRemove"  isType="其他"  :showTip="false" @on-success="afterUploadoen" :limit="4"/></div>
              </div>
            </el-form-item>
          </div>

        </div>

        <div  v-if="rowItr === 2 || rowItr === 0"  style="color: red;margin-left: -240px">注意：只能上传.jpg,.jpeg,.png,.gif文件</div>
      </div>

    </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {setUserContract, isbzf, getBzfAmount, previewapi,newPreviewapi,getHousecontpdf,getHousecontracttype} from "@/api/house-resource";
import {getLeasorDataByCardId} from "@/api/tenant-management";
import {getSignAmount, bzfCreate, getIsGregorin, bzfNewCreate, getContracTimeApi} from "@/api/contract";
import {timeFormat, numberFormat, ParseFloatFormat, ParseIntFormat, getMonthDiff} from "@custom/index";
import {MessageError, MessageSuccess, MessageWarning} from "@custom/message";
import uploadAvatar from "@/components/Upload/upload-avatar";
import {downloadByData} from "@/utils/hooks/download";
import {analyzeIDCard} from "@/utils/hooks";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";


export default {
  name: "dialog-add-contract",

  data() {
    // 判断是否成年自定义校验
    let validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('证件号码不能为空'));

      } else {
        // alert(this.contractData.leasorType)
        if (this.contractData.leasorType === '个人') {
          // let itr =  analyzeIDCard(value)
          // if (!itr) {
          //   callback(new Error('该租户未满十八周岁'));
          // }
        }
        callback();
      }
    };
    return {
      rowItr:null,
      rowItrstyle:true,
      itrTypePic: null,//房屋分割单的区分符--已经取消
      kitchenPic:[],//厨房照片数组
      drawingPic: [],//客厅照片数组
      restaurantPic: [],//餐厅照片数组
      bedroomPic: [],//卧室照片数组
      otherPic: [],//其他照片数组
      powerOfAttorneyImgList:[],//授权书照片数组
      businessLicenseList: [],//营业执照照片数组
      juridicalPersonFrontIdcardList:[],//法人身份证正面
      juridicalPersonReverseIdcardList:[],//法人身份证反面
      contactFrontIdcardList:[],//紧急联系人身份证正面
      contactReverseIdcardList:[],//紧急联系人身份证反面
      electContractTypeEnumCode:null,
      footerBtnName: ['取消', '确定', '预览'],
      addDialogVisible: false,
      contractData: {
        monthSize: 6,//签约时间是几个月
        leasorIdcard: null,
        leasorType: null,
        idType: null,
        leasorName: null,
        leasorPhone: null,
        contactName: null,
        contactPhone: null,
        addressId: null,
        eMail: null,
        signDate: null,
        rent: 0,
        depositAmount: 0,
        serviceAmount: 0,
        bill: true,
        face: true,
        faceInfo: null,
        frontIdcard: null,
        reverseIdcard: null,
        businessLicense: null,
        manager: null,
        keeper: null,
        startDate: null,
        endDate: null,
        beforeImgs: null,
      },
      rules: {
        leasorName: [{required: true, message: "承租人不能为空", trigger: "blur"}],
        juridicalPersonName: [{required: true, message: "法人姓名不能为空", trigger: "blur"}],
        juridicalPersonIdcard: [{required: true, message: "法人身份证号码不能为空", trigger: "blur"}],
        leasorPhone: [{required: true, message: "承租人电话不能为空", trigger: "blur"}],
        leasorIdcard: [
          // {required: true, message: "证件号码不能为空", trigger: "blur"},
          {validator: validatePass, trigger: 'blur'}
        ],
        contactName: [{required: true, message: "紧急联系人不能为空", trigger: "blur"}],
        contactPhone: [{required: true, message: "紧急联系人电话不能为空", trigger: "blur"}],
        leasorType: [{required: true, message: "请选择承租人类型", trigger: "blur"}],
        idType: [{required: true, message: "请选择证件类型", trigger: "blur"}],
        manager: [{required: true, message: "经办人不能为空", trigger: "blur"}],
        keeper: [{required: true, message: "管理人不能为空", trigger: "blur"}],
        faceInfo: [{required: true, message: "请上传人脸头像", trigger: "blur"}],
        frontIdcard: [{required: true, message: "请上传身份证正面", trigger: "blur"}],
        reverseIdcard: [{required: true, message: "请上传身份证反面", trigger: "blur"}],
        businessLicense: [{required: true, message: "请上传营业执照", trigger: "blur"}],
        endDate: [{required: true, message: "请选择租赁周期", trigger: "blur"}],
      },
      zq: [],
      zqoentime:'',//合同开始时间
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },

      loading: false,

      isBzfOk: null,

      showFormAmount: {
        rent: null,
        depositAmount: null,
        serviceAmount: null,
      }
    };
  },
  components: {uploadAvatar, uploadPictureCard},
  props: {
    currentHouse: {
      type: Object,
      default: () => ({}),
    },
    zUuid: {
      type: String,
      default: null,
    },
  },

  methods: {
    // 区分五个照片形式
    // funfenge(itr) {
    //   this.itrTypePic = itr
    //   alert('1')
    //   console.log(itr, this.itrTypePic)
    // },
    // \交接单删除
    afterImgsRemove({fileList,isType}) {
      let list = []
      fileList.forEach(item => {
        list.push(item.response.returnObject.info.uuid)
      })
      if(isType === '厨房') this.kitchenPic = list
      if(isType === '客厅') this.drawingPic = list
      if(isType === '餐厅') this.restaurantPic = list
      if(isType === '卧室') this.bedroomPic = list
      if(isType === '其他') this.otherPic = list
    },
    // 交接单上传
    afterImgsSuccess(uuid,response) {
       if(response === '厨房'){
         const hasElement = this.kitchenPic.includes(uuid);
         if(!hasElement)   this.kitchenPic.push(uuid)
         console.log(this.kitchenPic,'厨房')
       }else if (response === '客厅'){
         const hasElement = this.drawingPic.includes(uuid);
         if(!hasElement)   this.drawingPic.push(uuid)
         console.log(this.drawingPic,'客厅')
       }else if (response === '餐厅'){

         const hasElement = this.restaurantPic.includes(uuid);
         if(!hasElement)   this.restaurantPic.push(uuid)
         console.log(this.restaurantPic, '餐厅')
       }else if (response === '卧室'){

         const hasElement = this.bedroomPic.includes(uuid);
         if(!hasElement)   this.bedroomPic.push(uuid)
         console.log(this.bedroomPic, '卧室')
       }else if (response === '其他'){

         const hasElement = this.otherPic.includes(uuid);
         if(!hasElement)   this.otherPic.push(uuid)
         console.log(this.otherPic, '其他')
       }
    },
    enterpriseClick(response){
      //     powerOfAttorneyImgList//授权书照片数组
      //     businessLicenseList//营业执照照片数组
      //     juridicalPersonFrontIdcardList//法人身份证正面
      //     juridicalPersonReverseIdcardList//法人身份证反面
      //     contactFrontIdcardList//紧急联系人身份证正面
      //     contactReverseIdcardList//紧急联系人身份证反面

      console.log(response)
      const uuid = response.file.response.returnObject.info.uuid;
      let isType = response.isType
      if(isType === '营业执照') {
        const hasElement = this.businessLicenseList.includes(uuid);
        if(!hasElement)   this.businessLicenseList.push(uuid)
      }
      if(isType === '授权书') {
        response.fileList.forEach(item => {
          let itemUuid =   item.response.returnObject.info.uuid
          const hasElement = this.powerOfAttorneyImgList.includes(itemUuid);
          if(!hasElement)   this.powerOfAttorneyImgList.push(itemUuid)
        })

      }
      if(isType === '法人身份证人脸面') {
        const hasElement = this.juridicalPersonFrontIdcardList.includes(uuid);
        if(!hasElement)   this.juridicalPersonFrontIdcardList.push(uuid)
      }
      if(isType === '法人身份证国徽面') {
        const hasElement = this.juridicalPersonReverseIdcardList.includes(uuid);
        if(!hasElement)   this.juridicalPersonReverseIdcardList.push(uuid)
      }
      if(isType === '紧急联系人身份证人脸面') {
        const hasElement = this.contactFrontIdcardList.includes(uuid);
        if(!hasElement)   this.contactFrontIdcardList.push(uuid)
      }
      if(isType === '紧急联系人身份证国徽面') {
        const hasElement = this.contactReverseIdcardList.includes(uuid);
        if(!hasElement)   this.contactReverseIdcardList.push(uuid)
      }
      console.log(this.businessLicenseList,this.powerOfAttorneyImgList,this.juridicalPersonFrontIdcardList,this.juridicalPersonReverseIdcardList,this.contactFrontIdcardList,this.contactReverseIdcardList  )
    },
    enterpriseRemove(response){
      console.log(response)
      let {fileList,isType} = response
      let list = []
     if(fileList) {
       fileList.forEach(item => {
         list.push(item.response.returnObject.info.uuid)
       })
     }
      if(isType === '营业执照') this.businessLicenseList = []
      if(isType === '授权书') this.powerOfAttorneyImgList = list
      if(isType === '法人身份证人脸面') this.juridicalPersonFrontIdcardList = []
      if(isType === '法人身份证国徽面') this.juridicalPersonReverseIdcardList = []
      if(isType === '紧急联系人身份证人脸面') this.contactFrontIdcardList = []
      if(isType === '紧急联系人身份证国徽面') this.contactReverseIdcardList = []
      if(isType === '厨房') this.kitchenPic = list
      if(isType === '客厅') this.drawingPic = list
      if(isType === '餐厅') this.restaurantPic = list
    },
    // 陈守亮优化上传组件
    afterUploadoen(response,) {
        const uuid = response.file.response.returnObject.info.uuid;
        const isType = response.isType;
          this.afterImgsSuccess(uuid,isType)
    },


    // 打开添加合同弹框
    async openDialog(rowItr = null) {
      // console.log('111'); rowItr是 0 包租房 1青年公寓 2五象新区
      let that = this;
      that.rowItr = rowItr
       rowItr === null ? that.rowItrstyle = false : that.rowItrstyle = true
      //重置租期
      that.zq = [];
      that.zqoentime = that.getNowFormatDate()
      that.setEndDate(6)
      // 重置照片数据
      this.kitchenPic = []
      this.drawingPic = []
      this.restaurantPic = []
      this.bedroomPic = []
      this.otherPic = []
      // 保存旧数据
      let oldContractData = that.contractData;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //结构房间uuid和租户uuid
      const {apartmentUuid, leasorUuid} = that.currentHouse;
      let uuid = that.zUuid || apartmentUuid;
      // 查询当前房间是否是保租房
      const re = await isbzf(uuid);
      if (re.info) {
        that.footerBtnName = ['取消', '确定'];  // 当接口返回true时，只显示取消和确定按钮
      } else {
        that.footerBtnName = ['取消', '确定', '预览'];  // 其他情况下显示取消、确定和预览按钮
      }
      ;
      //保存结果
      that.isBzfOk = re.info;
      // console.log('isBzfOk',isBzfOk);
      //查询房间金额
      let ApartmentData = await getSignAmount({apartmentUuid: uuid});
      if (ApartmentData) {
        let {info} = ApartmentData;
        // 解构金额
        let {
          bone: depositAmount, deposit, displayAmount: rent, paymentMethod, rentAmount, rentComment,
          tipAmount: serviceAmount, tipComment, totalAmount, totalComment, totalTip,
        } = info;
        console.log(info, '99999999999999999999999')
        //转换金额
        depositAmount = ParseFloatFormat(depositAmount);
        rent = ParseFloatFormat(rent);
        serviceAmount = ParseFloatFormat(serviceAmount);
        deposit = ParseFloatFormat(deposit);
        rentAmount = ParseFloatFormat(rentAmount);
        totalTip = ParseFloatFormat(totalTip);
        totalAmount = ParseFloatFormat(totalAmount);
        that.showFormAmount.rent = numberFormat(rent, 2, '.', '');
        that.showFormAmount.depositAmount = numberFormat(depositAmount, 2, '.', '');
        that.showFormAmount.serviceAmount = numberFormat(serviceAmount, 2, '.', '');
        // 打包数据，并赋值
        that.contractData = {
          ...oldContractData, depositAmount, rent, serviceAmount, deposit, rentAmount, totalTip, totalAmount,
          rentComment, tipComment, totalComment, paymentMethod,
        };
      }

      //初始化 签约时间 房间uuid 租户uuid
      that.contractData.signDate = timeFormat(new Date()); //签约时间
      that.contractData.apartmentUuid = uuid; //房源uuid
      that.contractData.leasorUuid = leasorUuid; //承租人uuid

      loading.close();
      that.addDialogVisible = true;
    },

    //承租人类型切换事件
    typeChange(value) {
      this.rowItrstyle = true
      //2022/04/02 王江毅 承租人类型是法人证件类型就是税号,是个人证件类型默认身份证
      if (value === "法人") {
        this.contractData.idType = "税号";
        this.contractData.face = true;
        this.footerBtnName = ['取消', '确定'];
      } else {
        this.contractData.face = true;
        this.contractData.idType = "身份证";
      }
      const {apartmentUuid, } = this.currentHouse;
      this.powerOfAttorneyImgList = []//授权书照片数组
          this.businessLicenseList =  []//营业执照照片数组
          this.juridicalPersonFrontIdcardList = []//法人身份证正面
          this.juridicalPersonReverseIdcardList = []//法人身份证反面
          this.contactFrontIdcardList = []//紧急联系人身份证正面
          this.contactReverseIdcardList = []//紧急联系人身份证反面
      getHousecontracttype({leasorType:value,uuid:apartmentUuid}).then(({info}) => {
        console.log(info)
        //electContractPlatform 0无 1E签宝 2诺诺 3本系统企业pdf
        if(info.electContractPlatform == 0){
          this.electContractTypeEnumCode = '-1'
        }
        if(info.electContractPlatform == 1) {
          // e签宝
          this.electContractTypeEnumCode = 'e签宝'
        }
        if(info.electContractPlatform == 2) {
          //保租房
          this.electContractTypeEnumCode = '保租房'
        }
        if(info.electContractPlatform == 3) {
          //企业
          this.electContractTypeEnumCode = '企业'
        }
      })

      this.contractData.type = value;
      //清除校验
      this.$refs["leasorType"].clearValidate();
      this.$refs["idType"].clearValidate();
    },

    //证件类型切换事件
    idTypeChange(value) {
      //2022/04/02 王江毅 证件类型是税号承租人类型就是法人,其他类型承租人类型就是个人
      if (value === "税号") {
        this.contractData.leasorType = "法人";
      } else {
        this.contractData.leasorType = "个人";
      }
      this.contractData.idType = value;

      //清除校验
      this.$refs["leasorType"].clearValidate();
      this.$refs["idType"].clearValidate();
    },

    // 租户查询回显方法
    async getLeasorData(value) {
      let that = this;
      //调用接口查询租户
      let leasorDataRes = await getLeasorDataByCardId({cardId: value});

      if (leasorDataRes) {
        console.log(leasorDataRes)
        //解构出租户数组
        let {list = []} = leasorDataRes;
        console.log(list,'回显数据')
        //判断数量是否大于0，大于0表示存在
        if (list.length > 0) {
          //解构出租户姓名，用于查询租户
          let {leasorName} = that.contractData;
          //判断数组长度，长度为1，直接那第一个，不唯一，通过租户名筛选
          let leasorData = list.length === 1 ? list[0] : list.find((item) => item.name === leasorName);
          //解构租户信息
          let {
            name, tel1, contactName, contactPhone, type, idType, uuid, faceInfo, frontIdcard, reverseIdcard,businessLicense,juridicalPersonFrontIdcard,juridicalPersonReverseIdcard,
            contactFrontIdcard,contactReverseIdcard} = leasorData;
          if(type) this.rowItrstyle = true
          // 2024-11-1-陈守亮-新增-企业照片回显
          // businessLicense  营业执照
          // juridicalPersonFrontIdcard  法人身份证正面照片
          // juridicalPersonReverseIdcard  法人身份证反面照片
          // contactFrontIdcard  紧急联系人身份证正面照片
          // contactReverseIdcard  紧急联系人身份证反面照片
          //赋值回显数据
          this.contractData.leasorUuid = uuid;
          this.contractData.leasorName = name;
          this.contractData.leasorPhone = tel1;
          this.contractData.contactName = contactName;
          this.contractData.contactPhone = contactPhone;
          this.contractData.leasorType = type;
          this.contractData.idType = idType ? idType : type === "个人" ? "身份证" : "税号";
          const {apartmentUuid, } = this.currentHouse;
          getHousecontracttype({leasorType:type,uuid:apartmentUuid}).then(({info}) => {
            console.log(info)
            //electContractPlatform 0无 1E签宝 2诺诺 3本系统企业pdf
            if(info.electContractPlatform == 0){
              this.electContractTypeEnumCode = '-1'
              that.footerBtnName = ['取消', '确定'];
            }
            if(info.electContractPlatform == 1) {
              // e签宝
              this.electContractTypeEnumCode = 'e签宝'
            }
            if(info.electContractPlatform == 2) {
              //保租房
              this.electContractTypeEnumCode = '保租房'
            }
            if(info.electContractPlatform == 3) {
              //企业
              this.electContractTypeEnumCode = '企业'
              that.footerBtnName = ['取消', '确定'];
            }
          })
          if (type === '个人') {
            that.contractData.faceInfo = faceInfo;
            that.contractData.frontIdcard = frontIdcard;
            that.contractData.reverseIdcard = reverseIdcard;
          }
          if (type === '法人') {
            that.contractData.businessLicense = faceInfo;
            // 2024-11-1-陈守亮-新增-企业照片回显
            if(businessLicense) this.businessLicenseList.push(businessLicense)
            if(juridicalPersonFrontIdcard) this.juridicalPersonFrontIdcardList.push(juridicalPersonFrontIdcard)
            if(juridicalPersonReverseIdcard) this.juridicalPersonReverseIdcardList.push(juridicalPersonReverseIdcard)
            if(contactFrontIdcard) this.contactFrontIdcardList.push(contactFrontIdcard)
            if(contactReverseIdcard) this.contactReverseIdcardList.push(contactReverseIdcard)
          }
          //清空表单校验
          this.$refs["formPublish"].clearValidate();
        }
      }

    },
    clickoperation() {
      let that = this;
      const {isBzfOk} = that;
      //表单校验
      this.$refs["formPublish"].validate(async (valid) => {
        if (valid) {
          //校验租赁周期不能为空
          if (this.zq === null || this.zq.length === 0) {
            MessageError("合同周期不能为空");
            return;
          }
          if (this.contractData.leasorType === "个人" && this.contractData.faceInfo === null) {
            MessageError("请上传人脸头像");
            return;
          }
          //         if (
          //     this.contractData.leasorType === "个人" &&
          //     !this.$refs.faceInfoUpload.getUploadedFiles()
          //   ) {
          //     this.$message.error("请上传人脸头像");
          //     return;
          //   }
          const [startTime, endTime] = that.zq;
          //转换周期为月数
          const monthNumber = getMonthDiff(startTime, endTime);
          //判断租期月数是否是6-36个月
          if (monthNumber < 6 || monthNumber > 36) {
            MessageError("必须是6个月起租，最长不能超过3年");
            return;
          }
          console.log('this.contractData.face',this.contractData.face);
          if (this.rowItr === 2 || this.rowItr === 0) {
            // 仅在选择生成电子合同时进行校验
            if (this.contractData.face === true) {
              if (this.kitchenPic.length < 1) {
                MessageWarning('请上传厨房照片');
                return;
              }
              if (this.drawingPic.length < 1) {
                MessageWarning('请上传客厅照片');
                return;
              }
              if (this.restaurantPic.length < 1) {
                MessageWarning('请上传餐厅照片');
                return;
              }
              if (this.bedroomPic.length < 1) {
                MessageWarning('请上传卧室照片');
                return;
              }
              if (this.otherPic.length < 1) {
                MessageWarning('请上传其他照片');
                return;
              }
            }
          }

          that.contractData.kitchenImg = that.kitchenPic[0]
          that.contractData.drawingRoomImg = that.drawingPic[0]
          that.contractData.restaurantImg = that.restaurantPic[0]
          that.contractData.nuoBedroomImg = that.bedroomPic.join(',')
          that.contractData.nuoOtherDeliveryNoteImg = that.otherPic.join(',')
          console.log('预览上传内容',that.contractData)
          // previewapi
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          newPreviewapi(that.contractData).then(res => {
            console.log(res)
            downloadByData({data: res, filename: `合同预览${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.pdf`,});
          }).finally(() => loading.close());
        }
        // MessageWarning("研发中，敬请期待！");

      })


      // localStorage.setItem("paramData", JSON.stringify(that.contractData));
      // let contractData = localStorage.getItem('paramData') || null;
      // console.log(contractData,this.contractData)

    },
    // 点击添加合同弹框事件
    clickSubmit() {
      let that = this;
      const {isBzfOk} = that;
      // 判断日期是否6个月
      if (that.contractData.leasorType === '个人') {
        let itr = analyzeIDCard(that.contractData.leasorIdcard)
        if (!itr) {
          MessageWarning('该租户未满十八周岁');
          return
          // callback(new Error('该租户未满十八周岁'));
        }
      }
      //表单校验
      this.$refs["formPublish"].validate(async (valid) => {
        if (valid) {
          //校验租赁周期不能为空
          if (this.zq === null || this.zq.length === 0) {
            MessageError("合同周期不能为空");
            return;
          }
          //     itrTypePic: null,//房屋分割单的区分符
          //     kitchenPic:[],//厨房照片数组
          //     drawingPic: [],//客厅照片数组
          //     restaurantPic: [],//餐厅照片数组
          //     bedroomPic: [],//卧室照片数组
          //     otherPic: [],//其他照片数组
          // if(that.itrTypePic.length < 1) {
          //   MessageWarning('请上传房屋分割单');
          //   return
          // }
          console.log(that.kitchenPic,'厨房照片')
          console.log(that.drawingPic,'客厅照片')
          console.log(that.restaurantPic,'餐厅照片')
          console.log(that.bedroomPic,'卧室')
          console.log(that.otherPic,'其他照片')
          console.log('ssssssss');
          console.log('that.contractData.face',that.contractData.face);
          // 0 包租房 1青年公寓 2五象新区
          if (that.rowItr === 2 || that.rowItr === 0 ) {
            // 仅在选择生成电子合同时进行校验
            if ( this.electContractTypeEnumCode !== '-1') {
              if (that.kitchenPic.length < 1) {
                console.log('that.kitchenPic',that.kitchenPic)
                MessageWarning('请上传厨房照片');
                return;
              }
              if (that.drawingPic.length < 1) {
                console.log('that.drawingPic',that.drawingPic)
                MessageWarning('请上传客厅照片');
                return;
              }
              if (that.restaurantPic.length < 1) {
                console.log('that.restaurantPic',that.restaurantPic)
                MessageWarning('请上传餐厅照片');
                return;
              }
              if (that.bedroomPic.length < 1) {
                console.log('that.bedroomPic',that.bedroomPic)
                MessageWarning('请上传卧室照片');
                return;
              }
              if (that.otherPic.length < 1) {
                console.log('that.otherPic',that.otherPic)
                MessageWarning('请上传其他照片');
                return;
              }
            }
          }

          if (this.electContractTypeEnumCode === '企业') {
            // powerOfAttorneyImgList:[],//授权书照片数组
            //     businessLicenseList: [],//营业执照照片数组
            //     juridicalPersonFrontIdcardList:[],//法人身份证正面
            //     juridicalPersonReverseIdcardList:[],//法人身份证反面
            //     contactFrontIdcardList:[],//紧急联系人身份证正面
            //     contactReverseIdcardList:[],//紧急联系人身份证反面
            // 仅在选择生成电子合同时进行校验

              if (that.businessLicenseList.length < 1) {
                MessageWarning('请上传营业执照片');
                return;
              }
            if (that.powerOfAttorneyImgList.length < 1) {
              MessageWarning('请上传授权书照片');
              return;
            }
              if (that.juridicalPersonFrontIdcardList.length < 1) {
                MessageWarning('请上传法人身份证正面');
                return;
              }
              if (that.juridicalPersonReverseIdcardList.length < 1) {
                MessageWarning('请上传法人身份证反面');
                return;
              }
              if (that.contactFrontIdcardList.length < 1) {
                MessageWarning('请上传紧急联系人身份证正面');
                return;
              }
            if (that.contactReverseIdcardList.length < 1) {
              MessageWarning('请上传紧急联系人身份证反面');
              return;
            }
          }
          const [startTime, endTime] = that.zq;
          //转换周期为月数
          const monthNumber = getMonthDiff(startTime, endTime);
          //判断租期月数是否是6-36个月
          if (monthNumber < 6 || monthNumber > 36) {
            MessageError("必须是6个月起租，最长不能超过3年");
            return;
          }

          //调用查看合同到期时间在春节前后
          that.getIsGregorin();

          /*// 判断当前房间是不是富航房间, 不是富航房间需要判断租户类型是否是个人
          if (isBzfOk) {
              //调用查看合同到期时间在春节前后
              that.getIsGregorin();
          } else {
              //获取用户类型
              const {leasorType} = that.contractData;
              if (leasorType === "个人") {
                  //查看合同到期时间在春节前后
                  that.getIsGregorin();
              } else {
                  //查看合同到期时间在春节前后
                  that.getIsGregorin();
              }
          }*/
        }
      });
    },

    //查看合同到期时间在春节前后方法
    getIsGregorin() {
      let that = this;
      //解构开始时间，结束时间，房间uuid
      let {startDate, endDate, apartmentUuid, leasorIdcard, leasorName, idType} = that.contractData;
      // console.log(leasorIdcard,leasorName,leasorType)
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      console.log(endDate, '春节前后')
      //通过结束时间调用接口查询是否在春节前后
      getIsGregorin({dateTime: endDate}).then(({info}) => {
        //判断是否在春节前后，在春节前后提醒，不在不提醒
        if (info) {
          const message = "您选择的合同到期时间在春节前后，请确认选择？";
          const title = "提示";
          const options = {confirmButtonText: "确定", cancelButtonText: "取消", type: "warning",};
          this.$confirm(message, title, options).then(() => {
            //调用方法获取付款明细金额
            that.getApartmentAmount(startDate, endDate, apartmentUuid, leasorIdcard, leasorName, idType);
          }).catch(() => {
          });
        } else {
          console.log(startDate, endDate, apartmentUuid, '付款明细')
          //调用方法获取付款明细金额
          that.getApartmentAmount(startDate, endDate, apartmentUuid, leasorIdcard, leasorName, idType);
        }
      }).finally(() => loading.close());
    },

    //获取付款明细金额方法
    getApartmentAmount(startDate, endDate, apartmentUuid, idCard, name, idType) {
      console.log(idCard, name, idType)
      // idCard 证件号码
      // name  租客姓名
      // idType 证件类型
      let that = this;
      const {isBzfOk} = that;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      // 判断当前房间是不是富航房间,不同房间走不通房间接口
      if (isBzfOk) {
        // 调用接口获取富航房间金额
        getSignAmount({apartmentUuid, startDate, endDate}).then(({info}) => {
          //调用方法处理富航房间金额并展示
          that.setUserContract(info);
        }).finally(() => loading.close());
      } else {
        // ,idCard,name,idType    暂时隐藏
        // 调用接口获取保租房金额
        getBzfAmount({apartmentUuid, startDate, endDate, idCard, name, idType}).then(({info}) => {
          //调用方法处理保租房金额并展示
          that.exhibitionBzfAmount(info);
        }).finally(() => loading.close());
      }
    },

    //富航公寓付款明细展示
    setUserContract(data) {
      let that = this;
      //解构数据
      let {paymentMethod, totalAmount, totalComment, rentComment, tipComment,} = data;
      //转换金额
      totalAmount = numberFormat(ParseFloatFormat(totalAmount));
      //提示框展示金额HTML样式
      let htmlText = `<div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">月租金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.displayAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.tipAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">收款周期</div>
                                    <div style="width: 300px;padding-left: 20px;">${paymentMethod}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">押金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.bone))}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">租金备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;"> ${rentComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${tipComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计</div>
                                    <div style="width: 300px;padding-left: 20px;">${totalAmount}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${totalComment}</div>
                                </div>
                            </div>`;

      //提示付款明细
      this.$alert(htmlText, "付款明细", {dangerouslyUseHTMLString: true}).then(() => {
        const loadingOptions = that.loadingOptions;
        const loading = that.$loading({...loadingOptions});
        // 解构数据--
        let {
          leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate, signDate,
          contactName, contactPhone, bill, addressId, eMail, face, rent, depositAmount, serviceAmount,
          faceInfo, frontIdcard, reverseIdcard, businessLicense, manager, keeper,juridicalPersonName,juridicalPersonIdcard,
        } = that.contractData;
        //金额转换
        rent = ParseIntFormat(rent);
        depositAmount = ParseIntFormat(depositAmount);
        serviceAmount = ParseIntFormat(serviceAmount);
        // 封装数据
        const paramData = {
          leasorName,
          leasorPhone,
          leasorIdcard,
          leasorType,
          faceInfo,
          frontIdcard,
          reverseIdcard,
          apartmentUuid,
          startDate,
          endDate,
          payDate: timeFormat(new Date()),
          signDate,
          addressId,
          paymentMethodCode: 500003,
          signUrl: "",
          contactName,
          contactPhone,
          eMail,
          bill,
          roommates: [],
          face: leasorType === "法人" ? false : face,
          rent,
          depositAmount,
          serviceAmount,
          businessLicense,
          manager,
          keeper,

        };
        // console.log(paramData.kitchenImg, that.kitchenPic, that.drawingPic, that.restaurantPic, that.bedroomPic, that.otherPic,'分割前数据')
        if(this.electContractTypeEnumCode === '企业'){
          // 企业信息
          paramData.powerOfAttorneyImg =  that.powerOfAttorneyImgList.join(',')//授权书
          paramData.juridicalPersonFrontIdcard = that.juridicalPersonFrontIdcardList[0]//法人身份证正面
          paramData.juridicalPersonReverseIdcard = that.juridicalPersonReverseIdcardList[0]//法人身份证反面
          paramData.businessLicense = that.businessLicenseList[0]//营业执照
          paramData.contactFrontIdcard = that.contactFrontIdcardList[0]//紧急联系人身份证正面
          paramData.contactReverseIdcard = that.contactReverseIdcardList[0]//紧急联系人身份证反面
          paramData.juridicalPersonName = juridicalPersonName//法人名称
          paramData.juridicalPersonIdcard = juridicalPersonIdcard //法人身份证号
          paramData.otherDeliveryNoteImg = null
          paramData.bedroomImg = null
          //   交割单信息
          paramData.kitchenImg = that.kitchenPic.join(',')
          paramData.drawingRoomImg = that.drawingPic.join(',')
          paramData.restaurantImg = that.restaurantPic.join(',')
          paramData.bedroomImg = that.bedroomPic
          paramData.otherDeliveryNoteImg = that.otherPic
        }else {
          paramData.kitchenImg = that.kitchenPic.join(',')
          paramData.drawingRoomImg = that.drawingPic.join(',')
          paramData.restaurantImg = that.restaurantPic.join(',')
          paramData.bedroomImg = that.bedroomPic
          paramData.otherDeliveryNoteImg = that.otherPic

        }

        // 调用接口，传递数据，创建系统合同
        setUserContract(paramData).then((res) => {
          if(this.electContractTypeEnumCode === '企业'){
            getHousecontpdf({uuid:res.info.uuid}).then(res => {
              // console.log(res)
              if(this.electContractTypeEnumCode !== -1) downloadByData({data: res, filename: `企业签约.pdf`,});
            })
          }else {
            try {
              //解构返回数据
              let {info: {data},} = res;
              //判断data有没有数据，有数据拿出e签宝签署url
              if (data) {
                //解构出e签宝签署url
                const {url} = data;
                //新界面打开
                let tempwindow = window.open("", "_blank");
                //新界面更换地址
                tempwindow.location = url;
              }
            } catch (error) {
              MessageError("当前已禁止打开新弹框 请允许后签约");
            }
          }

          MessageSuccess("生成合同成功");
          //调用方法，关闭添加合同弹框
          that.clickCancel();
        }).finally(() => loading.close());
      }).catch(() => {
      });
    },

    //保租房付款明细展示
    exhibitionBzfAmount(data) {
      let that = this;
      //解构数据
      let {paymentMethod, totalAmount, totalComment, rentComment, tipAmount, tipComment} = data;
      console.log(tipComment)
      // 陈守亮-1-11-最新代码标记
      //转换金额
      totalAmount = numberFormat(ParseFloatFormat(totalAmount));
      //提示框展示金额HTML样式
      let htmlText = `<div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">月租金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.displayAmount))}元/月</div>
                                </div>
                                 <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.tipAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">收款周期</div>
                                    <div style="width: 300px;padding-left: 20px;">${paymentMethod}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">押金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.bone))}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">租金备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;"> ${rentComment}</div>
                                </div>
                                  <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;"> ${tipComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计</div>
                                    <div style="width: 300px;padding-left: 20px;">${totalAmount}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${totalComment}</div>
                                </div>
                            </div>`;
      //提示付款明细
      that.$alert(htmlText, "付款明细", {dangerouslyUseHTMLString: true}).then(() => {
        //调用保租房创建合同方法
        that.bzfCreateContract();
      }).catch((action) =>
          this.$message({type: "info", message: action === "cancel" ? "放弃保存并离开页面" : "停留在当前页面",})
      );
    },

    //月租金查询
    getSignAmount() {
      const that = this;
      //解构出房间uuid、开始时间，结束时间
      const {apartmentUuid} = this.currentHouse;
      const uuid = that.zUuid || apartmentUuid;
      const {startDate, endDate} = this.contractData;
      //开启加载状态
      that.loading = true;
      //调用接口查询租金
      getSignAmount({apartmentUuid: uuid, startDate, endDate}).then((res) => {
        //解构数据
        let {info = {}} = res;
        info = info || {};
        let {displayAmount = 0, bone = 0, tipAmount = 0} = info;
        displayAmount = displayAmount || 0;
        bone = bone || 0;
        tipAmount = tipAmount || 0;
        //金额转换并赋值回显
        that.contractData.rent = ParseFloatFormat(displayAmount);
        that.contractData.serviceAmount = ParseFloatFormat(tipAmount);
        that.contractData.depositAmount = ParseFloatFormat(bone);
        that.showFormAmount.rent = numberFormat(ParseFloatFormat(displayAmount), 2, '.', '');
        that.showFormAmount.depositAmount = numberFormat(ParseFloatFormat(bone), 2, '.', '');
        that.showFormAmount.serviceAmount = numberFormat(ParseFloatFormat(tipAmount), 2, '.', '');
        //关闭加载状态
        that.loading = false;
      });
    },

    //保租房创建合同方法
    bzfCreateContract() {
      // alert('9')
      let that = this;
      // 解构数据
      let {
        leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate, signDate,
        contactName, contactPhone, bill, addressId, eMail, face, rent, depositAmount, serviceAmount, faceInfo,
        frontIdcard, reverseIdcard, businessLicense, manager, keeper,juridicalPersonName,juridicalPersonIdcard,
      } = that.contractData;
      //金额转换
      rent = ParseIntFormat(rent);
      depositAmount = ParseIntFormat(depositAmount);
      serviceAmount = ParseIntFormat(serviceAmount);
      //清楚空格
      leasorPhone = leasorPhone.trim();
      contactPhone = contactPhone.trim();
      leasorIdcard = leasorIdcard.trim();

      //封装数据
      const paramData = {
        leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate,
        payDate: timeFormat(new Date()), signDate, paymentMethodCode: 500003, signUrl: "", contactName,
        contactPhone, eMail, addressId, bill, roommates: [], face, rent, depositAmount, serviceAmount, faceInfo,
        frontIdcard, reverseIdcard, businessLicense, manager, keeper,
      };

      const loadingOptions = that.loadingOptions;
      //判断租户类型走不同接口，创建系统合同
      if (leasorType === "个人") {
        const h = that.$createElement;
        paramData.kitchenImg = that.kitchenPic[0]
          paramData.drawingRoomImg = that.drawingPic[0]
          paramData.restaurantImg = that.restaurantPic[0]
          paramData.bedroomImg = that.bedroomPic
          paramData.otherDeliveryNoteImg = that.otherPic
          // alert('线上2')
          // console.log(paramData.private)
          paramData.isCreateElectContract = true;
          console.log("线上", paramData);
          let loading = that.$loading({...loadingOptions});
          //调用接口创建保租房个人系统合同
          // 2024/08/19 张晓瑜更换新的保租房创建合同接口 (使用e签宝)
          bzfNewCreate(paramData).then((res) => {
            console.log(res, '合同')
            try {
            //解构返回数据
            let {info: {data}} = res;
            //判断data有没有数据，有数据拿出e签宝签署url
            if (data) {
              //解构出e签宝签署url
              const {url} = data;
              //新界面打开
              let tempwindow = window.open("", "_blank");
              //新界面更换地址
              tempwindow.location = url;
            }
          } catch (error) {
            MessageError("当前已禁止打开新弹框 请允许后签约");
          }
          MessageSuccess("生成合同成功");
          //调用方法，关闭添加合同弹框
          that.clickCancel();
          }).finally(() => loading.close());
        // that.$msgbox({
        //   title: '提示',
        //   message: h('p', null, [
        //     h('p', {style: 'font-size: 20px'}, '请选择合同签约方式 '),
        //     h('p', {style: 'color: red'}, '线上签约是签约电子合同，线下签约是签约纸质合同')
        //   ]),
        //   showClose: false,
        //   showCancelButton: true,
        //   showConfirmButton: true,
        //   closeOnPressEscape: false,
        //   closeOnClickModal: false,
        //   center: true,
        //   cancelButtonText: '线下签约',
        //   confirmButtonText: '线上签约'
        // }).then(action => {
        //   // alert('线上1')
        //   //     kitchenPic:[],//厨房照片数组
        //   //     drawingPic: [],//客厅照片数组
        //   //     restaurantPic: [],//餐厅照片数组
        //   //     bedroomPic: [],//卧室照片数组
        //   //     otherPic: [],//其他照片数组
        //   // 封装房租交接单五个参数
        //   paramData.kitchenImg = that.kitchenPic[0]
        //   paramData.drawingRoomImg = that.drawingPic[0]
        //   paramData.restaurantImg = that.restaurantPic[0]
        //   paramData.nuoBedroomImg = that.bedroomPic.join(',')
        //   paramData.nuoOtherDeliveryNoteImg = that.otherPic.join(',')
        //   // alert('线上2')
        //   // console.log(paramData.private)
        //   paramData.isCreateElectContract = true;
        //   console.log("线上", paramData);
        //   let loading = that.$loading({...loadingOptions});
        //   //调用接口创建保租房个人系统合同
        //   bzfCreate(paramData).then((res) => {
        //     console.log(res, '合同')
        //     // 解构返回数据，获取合同uuid
        //     // let {info} = res;

        //     // let minuuid = info.apartment.mainUuid
        //     // //赋值uuid
        //     // paramData.uuid = info.uuid;
        //     // paramData.contractUuid = info.uuid;
        //     // //保存数据到localStorage
        //     // localStorage.setItem("paramData", JSON.stringify(paramData));
        //     //新建路由---保租房区分是否带子房间
        //     // if (minuuid) {
        //     //   const newWindows = this.$router.resolve({path: "/test-canvas-son"});
        //     //   //新界面打开
        //     //   window.open(newWindows.href, "_blank");
        //     // } else {
        //     //   const newWindows = this.$router.resolve({path: "/test-canvas"});
        //     //   //新界面打开
        //     //   window.open(newWindows.href, "_blank");
        //     // }

        //     //调用方法，关闭添加合同弹框
        //     // that.clickCancel();
        //   }).finally(() => loading.close());
        // }).catch(() => {
        //   paramData.isCreateElectContract = false;
        //   paramData.face = false;
        //   let loading = that.$loading({...loadingOptions});
        //   //调用接口创建保租房个人系统合同
        //   // 2024/08/19 张晓瑜更换新的保租房创建合同接口 (使用e签宝)
        //   // bzfCreate
        //   bzfNewCreate(paramData).then((res) => {
        //     MessageSuccess("生成合同成功");
        //     //调用方法，关闭添加合同弹框
        //     that.clickCancel();
        //   }).finally(() => loading.close());
        // });
      } else {
        paramData.isCreateElectContract = false;
        paramData.face = false;
        // 封装企业新增参数
        paramData.powerOfAttorneyImg =  that.powerOfAttorneyImgList.join(',')//授权书
        paramData.juridicalPersonFrontIdcard = that.juridicalPersonFrontIdcardList[0]//法人身份证正面
        paramData.juridicalPersonReverseIdcard = that.juridicalPersonReverseIdcardList[0]//法人身份证反面
        paramData.businessLicense = that.businessLicenseList[0]//营业执照
        paramData.contactFrontIdcard = that.contactFrontIdcardList[0]//紧急联系人身份证正面
        paramData.contactReverseIdcard = that.contactReverseIdcardList[0]//紧急联系人身份证反面
        paramData.juridicalPersonName = juridicalPersonName//法人名称
        paramData.juridicalPersonIdcard = juridicalPersonIdcard //法人身份证号
        paramData.otherDeliveryNoteImg = null
        paramData.bedroomImg = null

        paramData.kitchenImg = that.kitchenPic.join(',')
        paramData.drawingRoomImg = that.drawingPic.join(',')
        paramData.restaurantImg = that.restaurantPic.join(',')
        paramData.bedroomImg = that.bedroomPic
        paramData.otherDeliveryNoteImg = that.otherPic
        console.log("法人", paramData);
        let loading = that.$loading({...loadingOptions});
        //调用接口创建保租房企业系统合同
        setUserContract(paramData).then((res) => {
          MessageSuccess("生成合同成功");
          getHousecontpdf({uuid:res.info.uuid}).then(res => {
            // console.log(res)
           if(this.electContractTypeEnumCode !== -1) downloadByData({data: res, filename: `企业签约.pdf`,});
          })
          //调用方法，关闭添加合同弹框
          that.clickCancel();
        }).finally(() => loading.close());
      }
    },

    //添加合同窗体关闭
    clickCancel() {
      //调用方法刷新合同列表
      this.$emit("handleSearch");
      //重置数据
      this.contractData = {
        leasorIdcard: null, leasorType: null, idType: null, leasorName: null, leasorPhone: null,
        contactName: null, contactPhone: null, addressId: null, eMail: null, signDate: null, rent: 0,
        depositAmount: 0, serviceAmount: 0, bill: true, face: true, faceInfo: null, frontIdcard: null,
        reverseIdcard: null, businessLicense: null, manager: null, keeper: null, startDate: null, endDate: null,
      };
      //关闭弹框
      this.addDialogVisible = false;
    },

    //人脸头像上传
    faceInfoUploadSuccess({info}) {
      this.contractData.faceInfo = info.uuid;
    },

    //身份证正面上传
    frontIdcardUploadSuccess({info}) {
      this.contractData.frontIdcard = info.uuid;
    },

    //身份证反面上传
    reverseIdcardUploadSuccess({info}) {
      this.contractData.reverseIdcard = info.uuid;
    },

    //营业执照上传
    businessLicenseUploadSuccess({info}) {
      this.contractData.businessLicense = info.uuid;
    },
    //获取当前日期函数
    getNowFormatDate() {
      let date = new Date(),
          year = date.getFullYear(), //获取完整的年份(4位)
          month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

      return `${year}-${month}-${strDate}`
    },
    //计算续租结束时间方法
    setEndDate(value) {
      let that = this;
      if (value && value !== 0) {
        let startDate = this.zqoentime;
        let date = new Date(startDate);
        let startYear = date.getFullYear();     //获取开始年
        let startMonth = date.getMonth() + 1;   // 获取开始月
        let startDay = date.getDate();          //获取开始日

        if (startDay === 1) value -= 1;          //开始日为1号，增加月数减1

        let year = parseInt(value / 12);     //月数换算年数
        value = value % 12;
        startMonth = startMonth + value;
        let endMonth = null;    //结束月
        if (startMonth > 12) {     //开始月加 余月数 大于12 表示还有满年，年数加1 结束月减12
          year += 1;
          endMonth = startMonth - 12;
        } else endMonth = startMonth;   //其他情况 结束月加月数

        let endYear = startYear + year; //结束年
        let endDay = null;  //结束日
        let d = new Date(endYear, endMonth, 0);         // 获取结束月当月最后一天
        if (startDay === 1) endDay = d.getDate();            // 开始日 为1号 结束日为结束月最后一天
        else if (endMonth === 2 && startDay > 29) endDay = d.getDate(); //结束月为2月 开始日 大于29号 结束日为当月(2月)最后一天
        else if (startDay - 1 === d.getDate()) endDay = d.getDate() - 1; //开始日减1 等于结束月最后一天 结束日为当月最后一天减1
        else if (startDay >= 2) endDay = startDay - 1;   //开始日大于等于2 结束日为开始日减1
        // 月份从0开始，所以结束月减1 endMonth - 1
        let zqtowtime = timeFormat(new Date(endYear, endMonth - 1, endDay));
        that.zq = [this.zqoentime,zqtowtime]
        //获取房间金额
        that.getSignAmount();
      }
    },
  },
  watch: {
    zq(value) {
      console.log(value)
      console.log(value)
      if (value && value.length > 0 && value.length === 2) {
        let [startDate, endDate] = value;
        this.contractData.startDate = startDate;
           this.zqoentime = startDate
            this.contractData.endDate = endDate;
            getContracTimeApi({startDate, endDate}).then(res => {
              console.log(res.info)
              this.contractData.monthSize = res.info
              // this.setEndDate(res.info)
            })
             this.getSignAmount();

      } else {
        this.contractData.startDate = null;
        this.contractData.endDate = null;
      }
    },
    "contractData.leasorIdcard"(value) {
      if (value) if (value.length >= 18) this.getLeasorData(value);
    },
    'contractData.monthSize'(value) {
      if (value && value !== 0 ) this.setEndDate(value);
    },
  },
};
</script>
<style lang="scss" scoped>
#canvas-broad {
  margin: 0 auto;
  /*text-align: center;*/
}

#canvas {
  border: 2px solid #ff6700;
  cursor: crosshair;
  /*不能用这种方式给canvas设置宽高*/
  /*width: 600px;*/
  /*height: 300px;*/
}

.btn {
  width: 70px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #aaa; /*去掉<button>默认边框*/
  outline: none; /*去掉<button>选中时的默认边框*/
  cursor: pointer;
}

.input-color {
  width: 70px;
  height: 40px;
  border-radius: 10px;
  border: 0; /*去掉<button>默认边框*/
  outline: none; /*去掉<button>选中时的默认边框*/
}

#image_png {
  width: 300px;
  height: 150px;
  border: 2px solid #ff6700;
  display: block;
  margin: 10px auto;
}

.section {
  margin-top: 10px;
}

.info {
  color: #f0f;
  font-size: 14px;
  line-height: 40px;
}

.bg {
  background: #ff6700;
}

.fw {
  font-weight: 700;
}
.bimgs_boder{
  border-left: 1px dashed #ccc;
  margin-left: 50px;
}
</style>
