<template>
  <r-e-dialog
      title="水电表抄表"
      class="layer-contract-detail"
      :visible.sync="dialogVisible"
      width="1280px"
      top="2vh"
      @close="clickCancel"
  >
    <div
        class="flex justify-between align-center flex-warp"
        style="
        background-color: #eff9ff;
        height: 50px;
        color: #333333;
        font-size: 15px;
      "
    >
      <div style="margin-left: 20px">房间号 : {{ equipmentData.name }}</div>
    </div>
    <el-form
        ref="formPublish"
        label-width="135px"
        size="small"
        :model="formPublish"
        :rules="rules"
    >
      <div class="flex" >
        <el-form-item label="类型" prop="type">
          <div style="width: 290px">
            <el-radio-group v-model="formPublish.type">
              <el-radio :label="0">智能</el-radio>
              <el-radio :label="1">手动</el-radio>
              <el-radio :label="2">自动</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!-- <el-form-item label="是否生成账单" prop="isBill">
          <div style="width: 190px">
            <el-radio-group v-model="formPublish.isBill">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </div>
        </el-form-item> -->
      </div>
      <div class="flex" >
        <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:28%">
          设备编号
        </div>
        <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:27%" >
          当前房间
        </div>
        <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:22%">
          其他房间
        </div>
      </div>

      <div class="flex" style="height: 25px">
        <el-form-item label="水表编号">
          <div style="width: 190px">{{ equipmentData.waterNum }}</div>
        </el-form-item>
        <el-form-item label="电表编号">
          <div style="width: 190px">{{ equipmentData.electNum }}</div>
        </el-form-item>
      </div>
      <div class="flex" style="height: 30px">
        <el-form-item label="水表数据来源">
          <div style="width: 190px">
            <el-tag
                :type="
                formPublish.waterDataSources === 0
                  ? ''
                  : formPublish.waterDataSources === 1
                  ? 'success'
                  : formPublish.waterDataSources === 2
                  ? 'danger'
                  : formPublish.waterDataSources === 3
                  ? 'warning'
                  : 'warning'
              "
            >
              {{
                formPublish.waterDataSources === 0
                    ? '设备'
                    : formPublish.waterDataSources === 1
                        ? '数据库'
                        : formPublish.waterDataSources === 2
                            ? '无水表'
                            : formPublish.waterDataSources === 3
                                ? '人工输入'
                                : '无法连接'
              }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电表数据来源"  v-if="formPublish.type === 2">
          <div style="width: 190px">
            <el-tag
                :type="
                formPublish.electDataSources === 0
                  ? ''
                  : formPublish.electDataSources === 1
                  ? 'success'
                  : formPublish.electDataSources === 2
                  ? 'danger'
                  : formPublish.electDataSources === 3
                  ? 'warning'
                  : 'warning'
              "
            >
              {{
                formPublish.electDataSources === 0
                    ? '设备'
                    : formPublish.electDataSources === 1
                        ? '数据库'
                        : formPublish.electDataSources === 2
                            ? '无设备'
                            : formPublish.electDataSources === 3
                                ? '人工输入'
                                : '无法连接'
              }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电表数据来源" v-if="formPublish.type === 1 || formPublish.type === 0">
          <div style="width: 190px">
            <el-tag type="warning">
              人工输入
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label=""  style="margin-left:-220px"   v-if="formPublish.currentElectFail === true">
          <div style="width: 190px">
            <el-tag type="danger">
              抄表失败
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电表数据来源" v-if="formPublish.type === 2" style="margin-left:10px">
          <div style="width: 190px">
            <el-tag
                :type="
                formPublish.otherElectDataSources === 0
                  ? ''
                  : formPublish.otherElectDataSources === 1
                  ? 'success'
                  : formPublish.otherElectDataSources === 2
                  ? 'danger'
                  : formPublish.otherElectDataSources === 3
                  ? 'warning'
                  : 'warning'
              "
            >
              {{
                formPublish.otherElectDataSources === 0
                    ? '设备'
                    : formPublish.otherElectDataSources === 1
                        ? '数据库'
                        : formPublish.otherElectDataSources === 2
                            ? '无设备'
                            : formPublish.otherElectDataSources === 3
                                ? '人工输入'
                                : '无法连接'
              }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电表数据来源" v-if="formPublish.type === 1 || formPublish.type === 0" style="margin-left:10px">
          <div style="width: 190px">
            <el-tag type="warning">
              人工输入
            </el-tag>
          </div>
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px">
        上次抄表
      </div>
      <div class="flex">
        <el-form-item label="上次水表读数" prop="lastWater" class="itemWater">
          <el-input-number
              v-model="formPublish.lastWater"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              :disabled="formPublish.type === 2 || !equipmentData.waterNum"
          />
        </el-form-item>
        <el-form-item label="上次电表读数" prop="lastElect" class="itemElect">
          <el-input-number
              v-model="formPublish.lastElect"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              :disabled="formPublish.type === 2"
              @change="getAmountDatablur()"
          />
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="公摊电表读数" prop="lastElect" class=""  v-if="formPublish.type === 1 || formPublish.type === 0" style="flex-wrap: nowrap;width:75%;margin-left:20px" >
            <div>(上次读数:{{formPublish.parentLastElect ? formPublish.parentLastElect : 0 }})</div>
            <!-- <el-input-number
              v-model="formPublish.lastElect"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px;margin-bottom:10px"
              :disabled="formPublish.type === 2"
              @change="getHydropowerBill"
            /> -->
            <el-input-number
                v-model="formPublish.parentElect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2"
                @change="getAmountDatablur()"
            />
          </el-form-item>
          <i  v-if="formPublish.type === 1|| formPublish.type === 0" class="el-icon-refresh-right" 
                style="font-size: 20px;margin-top: 38px;margin-left: 5px;color: #1954f5;"
                @click="clickParentMeterReading(formPublish.parentApartmentUuid)"   
          ></i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1) &&formPublish.parentCurrentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px"  class="samplePhotos " v-if="formPublish.type === 1 || formPublish.type === 0">
            <upload-pictureCard   :uuidList="formPublish.publicImgs" :limit="1" @on-success="handlePublicSuccess"
                                  @on-remove="handlePublicRemove"/>
          </el-form-item>
        </div>
        <el-form-item  v-if="formPublish.type === 2" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width:550px;margin-left:-95px;">公摊电表编号<span style="margin-left:15px">{{formPublish.parentElectNum}}</span></div>
          <div style="width:100px;margin-left:-95px;">公摊电表读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.parentLastElect ? formPublish.parentLastElect : 0 }}</div>
          <div style="width:250px">当前电表读数: {{ formPublish.parentElectOne === -2 ? '无效值' : (formPublish.parentElectOne === -1 ? '抄表失败' : formPublish.parentElectOne) }}</div>

        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px;margin-top:-20px">
        当前抄表
      </div>
      <div class="flex">
        <el-form-item label="当前水表读数" prop="water" class="itemWater">
          <el-input-number
              v-model="formPublish.water"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              :disabled="formPublish.type === 2 || !equipmentData.waterNum"
          />
        </el-form-item>
        <el-form-item label="当前电表读数" prop="elect" class="itemElect">
          <el-input-number
              v-model="formPublish.elect"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              :disabled="formPublish.type === 2"
              @change="getAmountDatablur()"
          />

        </el-form-item>
        <i  v-if="formPublish.type === 1|| formPublish.type === 0" 
            class="el-icon-refresh-right" style="font-size: 20px;margin-top: 6px;margin-left: 5px;color: #1954f5;"  
            @click="clickMeterReading(formPublish.apartmentUuid)"    
        ></i>
        <div style="display:flex;">
          <el-form-item :label="'房间' + formPublish.otherApartmentMeterReadings[0].apartmentNo + '电表读数'"  prop="lastElect" 
          class="" v-if="formPublish.type === 1|| formPublish.type === 0" style="width:85%">
            <div>(上次读数:{{formPublish.firstLastMeterReading}})</div>
            <span v-if="formPublish.otherApartmentMeterReadings[0].containRentUser === false" style="color: red;margin-left: 5px;">无租户</span>
            <el-input-number
                v-model="formPublish.otherApartmentMeterReadings[0].elect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2 || formPublish.otherApartmentMeterReadings[0].containRentUser === false"
                @change="getAmountDatablur()"
            />
          </el-form-item>
          <i  v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings && formPublish.otherApartmentMeterReadings[0] && formPublish.otherApartmentMeterReadings[0].containRentUser === true" 
            class="el-icon-refresh-right" style="font-size: 20px;margin-top: 38px;margin-left: 5px;color: #1954f5;"
            @click="clickFirstMeterReading(formPublish.otherApartmentMeterReadings[0].apartmentUuid)"  
          ></i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1)&&formPublish.otherApartmentMeterReadings[0].currentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px" class="samplePhotos " v-if="(formPublish.type === 0 || formPublish.type === 1)  && formPublish.otherApartmentMeterReadings[0].containRentUser === true">
            <upload-pictureCard   :uuidList="formPublish.otherApartmentMeterReadings[0].attachment" :limit="1"
                                  @on-success="(file) => otherHandleSuccess(file, 0)"
                                  @on-remove="(file) => otherHandleRemove(file, 0)"/>
          </el-form-item>
        </div>

        <el-form-item  v-if="formPublish.type === 2" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width: 441px;display: flex">
            <div style="margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[0].apartmentNo}}房电表编号   <span style="margin-left:15px">{{formPublish.otherApartmentMeterReadings[0].electNum}}</span></div>
            <div v-if="formPublish.otherApartmentMeterReadings[0].containRentUser === false"  style="margin-left:45px"> <el-tag type="danger">无租户</el-tag></div>
          </div>
          <div style="width:100px;margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[0].apartmentNo}}房间读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.otherApartmentMeterReadings[0].lastElect ? formPublish.otherApartmentMeterReadings[0].lastElect : 0 }}</div>
          <div style="width:250px">当前电表读数:
            {{
              formPublish.otherApartmentMeterReadings[0].elect === -2 ? '无效值' :
                  (formPublish.otherApartmentMeterReadings[0].elect === -1 ? '抄表失败' :
                      formPublish.otherApartmentMeterReadings[0].elect)
            }}
          </div>
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px;margin-top:-20px;">
        使用数量
      </div>
      <div class="flex" style="min-height: 60px;">
        <el-form-item label="水表使用读数">
          <el-input-number
              :value="formPublish.water - formPublish.lastWater"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              disabled
          />
        </el-form-item>
        <el-form-item label="电表使用读数">
          <el-input-number
              :value="formPublish.elect - formPublish.lastElect"
              :precision="2"
              :step="1.0"
              :min="0"
              style="width: 190px"
              disabled
          />
        </el-form-item>
        <div style="display:flex;">
          <el-form-item :label="'房间' + formPublish.otherApartmentMeterReadings[1].apartmentNo + '电表读数'" prop="lastElect" class="" v-if="(formPublish.type === 0 || formPublish.type === 1)  && formPublish.otherApartmentMeterReadings.length >=2" style="width:75%;margin-left:20px">
            <div >(上次读数:{{formPublish.secondLastMeterReading}})</div>
            <span v-if="formPublish.otherApartmentMeterReadings[1].containRentUser === false" style="color: red;margin-left: 5px;">无租户</span>
            <el-input-number
                v-model="formPublish.otherApartmentMeterReadings[1].elect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2|| formPublish.otherApartmentMeterReadings[1].containRentUser === false"
                @change="getAmountDatablur"
            />
          </el-form-item>
          <i v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings && formPublish.otherApartmentMeterReadings[1] !== null && formPublish.otherApartmentMeterReadings[1] !== undefined && formPublish.otherApartmentMeterReadings[1].containRentUser === true" 
            class="el-icon-refresh-right" 
            style="font-size: 20px; margin-top: 38px; margin-left: 5px; color: #1954f5;"
            @click="clickSecondMeterReading(formPublish.otherApartmentMeterReadings[1].apartmentUuid)"  >
          </i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1) &&formPublish.otherApartmentMeterReadings.length >= 2&&formPublish.otherApartmentMeterReadings[1].currentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px" class="samplePhotos " v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings.length >= 2 && formPublish.otherApartmentMeterReadings[1].containRentUser === true">
            <upload-pictureCard   :uuidList="formPublish.otherApartmentMeterReadings[1].attachment" :limit="1"
                                  @on-success="(file) => otherHandleSuccess(file, 1)"
                                  @on-remove="(file) => otherHandleRemove(file, 1)"/>
          </el-form-item>
        </div>
        <el-form-item  v-if="formPublish.type === 2 && formPublish.otherApartmentMeterReadings.length >=2" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width: 441px;display: flex">
            <div style="margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[1].apartmentNo}}房电表编号   <span style="margin-left:15px">{{formPublish.otherApartmentMeterReadings[1].electNum}}</span></div>
            <div v-if="formPublish.otherApartmentMeterReadings[1].containRentUser === false"  style="margin-left:45px"> <el-tag type="danger">无租户</el-tag></div>
          </div>
          <div style="width:100px;margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[1].apartmentNo}}房间读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.otherApartmentMeterReadings[1].lastElect ? formPublish.otherApartmentMeterReadings[1].lastElect : 0 }}</div>
          <div style="width:250px">
            当前电表读数:
            {{
              formPublish.otherApartmentMeterReadings[1].elect === -2 ? '无效值' :
                  (formPublish.otherApartmentMeterReadings[1].elect === -1 ? '抄表失败' :
                      formPublish.otherApartmentMeterReadings[1].elect)
            }}
          </div>
        </el-form-item>
      </div>
      <div class="flex" style="min-height: 60px;">
        <el-form-item label="水费单价m³/元">
          <el-input-number
              v-model="formPublish.priceWater"
              :precision="4"
              :step="1.0"
              disabled
              style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="电费单价KW·h/元">
          <el-input-number
              v-model="formPublish.priceElect"
              :precision="4"
              :step="1.0"
              disabled
              style="width: 190px"
          />
        </el-form-item>
        <div style="display:flex;">
          <el-form-item :label="'房间' + formPublish.otherApartmentMeterReadings[2].apartmentNo + '电表读数'" prop="lastElect" class="" v-if="(formPublish.type === 0 || formPublish.type === 1)  && formPublish.otherApartmentMeterReadings.length >= 3" style="width:85%;margin-left:20px">
            <div >(上次读数:{{formPublish.thirdLastMeterReading}})</div>
            <span v-if="formPublish.otherApartmentMeterReadings[2].containRentUser === false" style="color: red;margin-left: 5px;">无租户</span>
            <el-input-number
                v-model="formPublish.otherApartmentMeterReadings[2].elect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2 || formPublish.otherApartmentMeterReadings[2].containRentUser === false"
                @change="getAmountDatablur"
            />
          </el-form-item>
          <i  v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings && formPublish.otherApartmentMeterReadings[2] && formPublish.otherApartmentMeterReadings[2].containRentUser === true"
            class="el-icon-refresh-right" style="font-size: 20px;margin-top: 38px;margin-left: 5px;color: #1954f5;"
            @click="clickThirdMeterReading(formPublish.otherApartmentMeterReadings[2].apartmentUuid)" 
          ></i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1) &&formPublish.otherApartmentMeterReadings.length >= 3&&formPublish.otherApartmentMeterReadings[2].currentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px" class="samplePhotos " v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings.length >= 3 && formPublish.otherApartmentMeterReadings[2].containRentUser === true">
            <upload-pictureCard   :uuidList="formPublish.otherApartmentMeterReadings[2].attachment" :limit="1"
                                  @on-success="(file) => otherHandleSuccess(file, 2)"
                                  @on-remove="(file) => otherHandleRemove(file, 2)"/>
          </el-form-item>
        </div>
        <el-form-item  v-if="formPublish.type === 2 && formPublish.otherApartmentMeterReadings.length >= 3" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width: 441px;display: flex">
            <div style="margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[2].apartmentNo}}房电表编号   <span style="margin-left:15px">{{formPublish.otherApartmentMeterReadings[2].electNum}}</span></div>
            <div v-if="formPublish.otherApartmentMeterReadings[2].containRentUser === false"  style="margin-left:45px"> <el-tag type="danger">无租户</el-tag></div>
          </div>
          <div style="width:100px;margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[2].apartmentNo}}房间读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.otherApartmentMeterReadings[2].lastElect ? formPublish.otherApartmentMeterReadings[2].lastElect : 0 }}</div>
          <div style="width:250px">
            当前电表读数:
            {{
              formPublish.otherApartmentMeterReadings[2].elect === -2 ? '无效值' :
                  (formPublish.otherApartmentMeterReadings[2].elect === -1 ? '抄表失败' :
                      formPublish.otherApartmentMeterReadings[2].elect)
            }}
          </div>
        </el-form-item>
      </div>
      <div class="flex" style="min-height: 60px;">
        <el-form-item label="当前水费合计(元)">
          <el-input-number
              v-loading="loading"
              v-model="formPublish.amountWater"
              :precision="2"
              :step="1.0"
              disabled
              style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="当前电费合计(元)">
          <el-input-number
              v-loading="loading"
              v-model="formPublish.amountElectTotal"
              :precision="2"
              :step="1.0"
              disabled
              style="width: 190px"
          />
        </el-form-item>
        <div style="display:flex;">
          <el-form-item :label="'房间' + formPublish.otherApartmentMeterReadings[3].apartmentNo + '电表读数'" prop="lastElect" class="" v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings.length >= 4" style="width:85%;margin-left:20px">
            <div >(上次读数:{{formPublish.fourthLastMeterReading}})</div>
            <span v-if="formPublish.otherApartmentMeterReadings[3].containRentUser === false" style="color: red;margin-left: 5px;">无租户</span>
            <el-input-number
                v-model="formPublish.otherApartmentMeterReadings[3].elect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2 || formPublish.otherApartmentMeterReadings[3].containRentUser === false"
                @change="getAmountDatablur"
            />
          </el-form-item>
          <i  v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings && formPublish.otherApartmentMeterReadings[3] && formPublish.otherApartmentMeterReadings[3].containRentUser === true"
              @click="clickFAourthMeterReading(formPublish.otherApartmentMeterReadings[3].apartmentUuid)"
          ></i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1) &&formPublish.otherApartmentMeterReadings.length >= 4&&formPublish.otherApartmentMeterReadings[3].currentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px" class="samplePhotos " v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings.length >= 4 && formPublish.otherApartmentMeterReadings[3].containRentUser === true">
            <upload-pictureCard   :uuidList="formPublish.otherApartmentMeterReadings[3].attachment" :limit="1"
                                  @on-success="(file) => otherHandleSuccess(file, 3)"
                                  @on-remove="(file) => otherHandleRemove(file, 3)"/>
          </el-form-item>
        </div>
        <el-form-item  v-if="formPublish.type === 2 && formPublish.otherApartmentMeterReadings.length >= 4" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width: 441px;display: flex">
            <div style="margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[3].apartmentNo}}房电表编号   <span style="margin-left:15px">{{formPublish.otherApartmentMeterReadings[3].electNum}}</span></div>
            <div v-if="formPublish.otherApartmentMeterReadings[3].containRentUser === false"  style="margin-left:45px"> <el-tag type="danger">无租户</el-tag></div>
          </div>
          <div style="width:100px;margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[3].apartmentNo}}房间读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.otherApartmentMeterReadings[3].lastElect ? formPublish.otherApartmentMeterReadings[3].lastElect : 0 }}</div>
          <div style="width:250px">
            当前电表读数:
            {{
              formPublish.otherApartmentMeterReadings[3].elect === -2 ? '无效值' :
                  (formPublish.otherApartmentMeterReadings[3].elect === -1 ? '抄表失败' :
                      formPublish.otherApartmentMeterReadings[3].elect)
            }}
          </div>
        </el-form-item>
      </div>
      <div class="flex" style="min-height: 60px;">
        <el-form-item label="上次抄表时间" prop="lastTime">
          <el-date-picker
              v-model="formPublish.lastTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="当前抄表时间" prop="createdTime">
          <el-date-picker
              v-model="formPublish.createdTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              style="width: 190px"
          />
        </el-form-item>
        <div style="display:flex;">
          <el-form-item  :label="'房间' + formPublish.otherApartmentMeterReadings[4].apartmentNo + '电表读数'"  prop="lastElect" class=""  v-if="(formPublish.type === 0 || formPublish.type === 1)&& formPublish.otherApartmentMeterReadings.length >= 5" style="width:85%;margin-left:20px">
            <div>(上次读数:{{formPublish.fifthLastMeterReading}})</div>
            <span v-if="formPublish.otherApartmentMeterReadings[4].containRentUser === false" style="color: red;margin-left: 5px;">无租户</span>
            <el-input-number
                v-model="formPublish.otherApartmentMeterReadings[4].elect"
                :precision="2"
                :step="1.0"
                :min="0"
                style="width: 190px;margin-left:-100px"
                :disabled="formPublish.type === 2 || formPublish.otherApartmentMeterReadings[4].containRentUser === false"
                @change="getAmountDatablur"
            />
          </el-form-item>
          <i  v-if="(formPublish.type === 0 || formPublish.type === 1) && formPublish.otherApartmentMeterReadings && formPublish.otherApartmentMeterReadings[4] && formPublish.otherApartmentMeterReadings[4].containRentUser === true"
            @click="clickFifthMeterReading(formPublish.otherApartmentMeterReadings[4].apartmentUuid)"
          ></i>
          <div v-if="(formPublish.type === 0 || formPublish.type === 1) &&formPublish.otherApartmentMeterReadings.length >= 5&&formPublish.otherApartmentMeterReadings[4].currentElectFail===true" style="width: 19%;margin-top: 10px;color: red;">抄表失败</div>
          <el-form-item label="抄表图片" label-width="100px" class="samplePhotos " v-if="(formPublish.type === 0 || formPublish.type === 1)&& formPublish.otherApartmentMeterReadings.length >= 5 && formPublish.otherApartmentMeterReadings[4].containRentUser === true">
            <upload-pictureCard   :uuidList="formPublish.otherApartmentMeterReadings[4].attachment" :limit="1"
                                  @on-success="(file) => otherHandleSuccess(file, 4)"
                                  @on-remove="(file) => otherHandleRemove(file, 4)"/>
          </el-form-item>
        </div>
        <el-form-item  v-if="formPublish.type === 2 && formPublish.otherApartmentMeterReadings.length >= 5" style="width:1200px;display:flex;flex-wrap: wrap;">
          <div style="width: 441px;display: flex">
            <div style="margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[4].apartmentNo}}房电表编号   <span style="margin-left:15px">{{formPublish.otherApartmentMeterReadings[4].electNum}}</span></div>
            <div v-if="formPublish.otherApartmentMeterReadings[4].containRentUser === false"  style="margin-left:45px"> <el-tag type="danger">无租户</el-tag></div>
          </div>
          <div style="width:100px;margin-left:-95px;">{{formPublish.otherApartmentMeterReadings[4].apartmentNo}}房间读数</div>
          <div style="width:150px">上次电表读数:{{ formPublish.otherApartmentMeterReadings[4].lastElect ? formPublish.otherApartmentMeterReadings[4].lastElect : 0 }}</div>
          <div style="width:250px">
            当前电表读数:
            {{
              formPublish.otherApartmentMeterReadings[4].elect === -2 ? '无效值' :
                  (formPublish.otherApartmentMeterReadings[4].elect === -1 ? '抄表失败' :
                      formPublish.otherApartmentMeterReadings[4].elect)
            }}
          </div>
        </el-form-item>
      </div>
      <div class="flex" v-if="formPublish.type === 1 || formPublish.type === 0" style="margin-left: 350px;margin-top:-10px">

        <el-form-item label="手动抄表图片" label-width="100px" class="samplePhotos " >
          <upload-pictureCard :uuidList="formPublish.beforeImgs" :limit="1" @on-success="handleSuccess"
                              @on-remove="handleRemove"/>
        </el-form-item>
      </div>
    </el-form>
    <div class="submit-btn-group" style="text-align: center;" >
      <el-button class="cancel"  @click="clickCancel" >
        取消
      </el-button>
      <el-button class="submit" :loading="loadingbtn"  @click="clickSubmit" :disabled="disableSubmitButton">
        确定
      </el-button>
    </div>

  </r-e-dialog>
</template>

<script>
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import { hydropowerBill, hydropowerSettlement,getCalculationData,getElectDatabaseAPI,getElectMetereadingAPI,getElectCalculationAmount,hydropowerSaveAPI } from '@/api/door-management'
import { MessageSuccess } from '@custom/message'
import { timeFormat, ParseFloatFormat,ParseFloatFormatFour } from '@custom/index'
export default {
  name: 'dialog-add-surface',
  data () {
    return {
      dialogVisible: false,
      loadingbtn: false,
      formPublish: {
        type: 0,
        lastWater: '',
        lastElect: '',
        lastTime: '',
        water: '',
        elect: '',
        createdTime: '',
        priceWater: '',
        priceElect: '',
        amountWater: '',
        amountElect: '',
        isBill: 1,
        waterDataSources: 0,
        electDataSources: 0,
        transactionCode:'', // 随机码
        otherApartmentMeterReadings:[],
        parentElect:'',//公摊电表当前读数
        firstMeterReading:'', // 第一个 房间电表读数
        firstLastMeterReading:'', // 第一个 房间上次电表读数
        secondMeterReading:'', // 第二个 房间电表读数
        secondLastMeterReading:'', // 第二个 房间上次电表读数
        thirdMeterReading:'', // 第三个 房间电表读数
        thirdLastMeterReading:'', // 第三个 房间上次电表读数
        fourthMeterReading:'', // 第四个 房间电表读数
        fourthLastMeterReading:'', // 第四个 房间上次电表读数
        fifthMeterReading:'', // 第五个 房间电表读数
        fifthLastMeterReading:'', // 第五个 房间上次电表读数
        attachment: null, // 当前房间抄表附件
        public:null, // 公摊房间抄表
        parentApartmentUuid:'',
        currentElectFail:null,
        updateBeforeLastElect:'', //更改前上次读数
        updateBeforeCurrentElect:'' // 更改前当前读数
      },
      beforeImgslist:[],//手动抄表图片
      publicImgslist:[], // 公摊房间图片
      publicArea: {} ,// 用于存放提交公摊的数据
      rules: {
        lastWater: [
          { required: true, message: '上次水表读数不能为空', trigger: 'blur' }
        ],
        lastElect: [
          { required: true, message: '上次电表读数不能为空', trigger: 'blur' }
        ],
        water: [
          { required: true, message: '当前水表读数不能为空', trigger: 'blur' }
        ],
        elect: [
          { required: true, message: '当前电表读数不能为空', trigger: 'blur' }
        ],
        createdTime: [
          { required: true, message: '当前抄表时间不能为空', trigger: 'change' }
        ],
        lastTime: [
          { required: true, message: '上次抄表时间不能为空', trigger: 'change' }
        ],
        // beforeImgs: [{required: true, message: '请上传手动抄表图片', trigger: 'blur'}],
      },
      // loading: true,
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      disableSubmitButton:false,
      photos: [],
    }
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {uploadPictureCard},
  methods: {
    handleSuccess(response) {
      const uuid = response.file.response.returnObject.info.uuid;
      console.log(uuid,response)
      const hasElement = this.beforeImgslist.includes(uuid);
      if (hasElement) {
        console.log("数组中包含元素3");
      } else {
        console.log("数组中不包含元素3");
        this.beforeImgslist.push(uuid)
      }
      // console.log(this.formPublish.beforeImgs)
    },

    handleRemove({fileList}) {
      console.log('删除',fileList)
      let list = []
      fileList.forEach(item => {
        list.push(item.response.returnObject.info.uuid)
      })
      this.beforeImgslist = list
      console.log(this.beforeImgslist)
    },
    handlePublicSuccess(response) {
      const uuid = response.file.response.returnObject.info.uuid;
      console.log(uuid,response)
      const hasElement = this.publicImgslist.includes(uuid);
      if (hasElement) {
        console.log("数组中包含元素3");
      } else {
        console.log("数组中不包含元素3");
        this.publicImgslist.push(uuid)
      }
      // console.log(this.formPublish.beforeImgs)
    },

    handlePublicRemove({fileList}) {
      console.log('删除',fileList)
      let list = []
      fileList.forEach(item => {
        list.push(item.response.returnObject.info.uuid)
      })
      this.publicImgslist = list
      console.log(this.publicImgslist)
    },
    otherHandleSuccess(response, index) {
      console.log('this.formPublish.type',this.formPublish.type);
      const uuid = response.file.response.returnObject.info.uuid;
      console.log(uuid, response);

      // 获取当前房间的 attachment 字符串
      let currentAttachments = this.formPublish.otherApartmentMeterReadings[index].attachment || '';

      // 检查 UUID 是否已经存在
      if (currentAttachments.split(',').includes(uuid)) {
        console.log("数组中包含元素");
      } else {
        console.log("数组中不包含元素");
        // 将新的 UUID 添加到字符串中，以逗号分隔
        this.formPublish.otherApartmentMeterReadings[index].attachment = currentAttachments ? `${currentAttachments},${uuid}` : uuid;
      }
      console.log(this.formPublish.otherApartmentMeterReadings[index].attachment);
    },

    otherHandleRemove({ fileList }, index) {
      console.log('删除', fileList);

      // 获取当前房间的 attachment 字符串
      let currentAttachments = this.formPublish.otherApartmentMeterReadings[index].attachment || '';

      // 从 fileList 中提取出要删除的 UUID
      const uuidsToRemove = fileList.map(item => item.response.returnObject.info.uuid);

      // 将字符串转为数组，过滤掉要删除的 UUID
      const updatedAttachments = currentAttachments.split(',').filter(uuid => !uuidsToRemove.includes(uuid)).join(',');

      // 更新对应房间的 attachment 字符串
      this.formPublish.otherApartmentMeterReadings[index].attachment = updatedAttachments;

      console.log(this.formPublish.otherApartmentMeterReadings[index].attachment);
    },

    async openDialog () {
      let that = this
      let oldFormPublish = this.formPublish
      console.log("type",that.formPublish.type);
      // let transactionCode = Math.random();
      // that.formPublish.transactionCode = transactionCode
      const { uuid } = this.equipmentData
      console.log('this.equipmentData',this.equipmentData);
      const loadingOptions = that.loadingOptions;
      console.log('that.loadingOptions',that.loadingOptions);
      const loading = that.$loading({ ...loadingOptions });
      console.log('loading',loading);
      await getElectDatabaseAPI({ uuid, }).then(res=> {
        this.dialogVisible = true
        if (res) {
          let {
            info: {
              lastElect,
              lastWater,
              elect,
              water,
              priceWater,
              priceElect,
              amountWater,
              amountElect,
              lastTime,
              createTime,
              waterDataSources,
              electDataSources,
              otherElectDataSources,
              parentElect,
              parentLastElect,
              parentElectNum,
              otherApartmentMeterReadings,
              parentApartmentUuid,
              currentElectFail,
              containRentUser,
              parentWater,
              lasParenttWater,
              electParentPrice,
              waterParentPrice,
              waterParentAmount,
              electParentAmount,
              containRentUserParent,
              waterParentDataDevice,
              electParentDataDevice,
              apartmentUuid,
              amountElectTotal
            }
          } = res
          this.parentLastElect = parentLastElect
          console.log("priceElect",priceElect);
          console.log("priceWater",priceWater);
          console.log('apartmentUuid',apartmentUuid); 
          console.log('parentElect',parentElect);     
          that.formPublish.updateBeforeLastElect =  lastElect  // 更改前上次读数
          that.formPublish.updateBeforeCurrentElect =  elect   //更改前当前读数
          that.formPublish.parentElectOne = parentElect;
          console.log('that.formPublish.parentElectOne',that.formPublish.parentElectOne);
          that.formPublish.parentLastElecttOne = parentLastElect;
          that.formPublish.containRentUser = containRentUser;
          that.formPublish.parentWater = parentWater;
          that.formPublish.lasParenttWater = lasParenttWater;
          that.formPublish.electParentPrice = electParentPrice;
          that.formPublish.waterParentPrice = waterParentPrice;
          that.formPublish.waterParentAmount = waterParentAmount;
          that.formPublish.electParentAmount = electParentAmount;
          that.formPublish.containRentUserParent = containRentUserParent;
          that.formPublish.waterParentDataDevice = waterParentDataDevice;
          that.formPublish.electParentDataDevice = electParentDataDevice;
          that.formPublish.priceElects = priceElect;
          that.formPublish.priceWaters = priceWater;
          that.formPublish.amountWaters = amountWater
          that.formPublish.amountElects = amountElect
          console.log("amountWaters", that.formPublish.amountWaters);
          priceWater = ParseFloatFormatFour(priceWater)
          priceElect = ParseFloatFormatFour(priceElect)
          console.log("priceElectsss",priceElect);
          that.formPublish.priceElect = priceElect
          console.log("that.formPublish.priceElect",that.formPublish.priceElect);
          amountWater = ParseFloatFormat(amountWater)
          amountElect = ParseFloatFormat(amountElect)
          waterDataSources = parseInt(waterDataSources)
          electDataSources = parseInt(electDataSources)
          otherElectDataSources = parseInt(otherElectDataSources)
          console.log('lastTime',lastTime);
          console.log("electParentPrice",electParentPrice);     
          amountElectTotal = parseInt(amountElectTotal)
          that.formPublish.amountElectTotal =  amountElectTotal  // 	当前房源电费金额合计（金额+公摊）
          lastTime = lastTime
              ? timeFormat(new Date(lastTime),'yyyy-MM-dd HH:mm:ss')
              : timeFormat(new Date(),'yyyy-MM-dd HH:mm:ss')
          console.log('timeFormat(new Date(lastTime))',timeFormat(new Date(lastTime)),'yyyy-MM-dd HH:mm:ss');
          let createdTime = createTime
              ? timeFormat(new Date(createTime),'yyyy-MM-dd HH:mm:ss')
              : timeFormat(new Date(),'yyyy-MM-dd HH:mm:ss')
          console.log('timeFormat(new Date(createdTime))',timeFormat(new Date(createdTime)),'yyyy-MM-dd HH:mm:ss');
          const other  = otherApartmentMeterReadings
          console.log('otherApartmentMeterReadings前',otherApartmentMeterReadings);
          this.otherApartmentMeterReadings = other.map(item => {
            return {
              ...item, // 保留原有属性
              updateBeforeLastElect: item.lastElect,
              updateBeforeCurrentElect: item.elect == null ? 0 : item.elect,
            };
          });
          console.log('this.otherApartmentMeterReadings后',this.otherApartmentMeterReadings);
          that.formPublish = {
            ...oldFormPublish,
            lastElect,
            lastWater,
            elect,
            water,
            type: 0,
            priceWater,
            priceElect,
            amountWater,
            amountElect,
            lastTime,
            createdTime,
            waterDataSources,
            electDataSources,
            otherElectDataSources,
            parentElect,
            parentLastElect,
            parentElectNum,
            parentApartmentUuid,
            otherApartmentMeterReadings:this.otherApartmentMeterReadings,
            currentElectFail,
            apartmentUuid
          }
         }
      }).finally(() => loading.close());
        // await this.handleMeterReadings(this.formPublish.apartmentUuid)
        // await this.clickParentMeterReading(this.formPublish.parentApartmentUuid)
        await this.handleReadings();
        await this.getAmountDatablur();
      // 2023/08/16张晓瑜新增弹窗提示
      let isAlertShown = false
      if (
          (this.formPublish.elect === -1 && this.formPublish.electDataSources === 1) ||
          (this.formPublish.water === -1 && this.formPublish.waterDataSources === 1)
      ) {
        let message = ''
        if (
            this.formPublish.elect === -1 && this.formPublish.electDataSources === 1
        ) {
          message += '电表'
        }
        if (
            this.formPublish.water === -1 && this.formPublish.waterDataSources === 1
        ) {
          if (message !== '') {
            message += '和水表都'
          } else {
            message += '水表'
          }
        }

        if (!isAlertShown) {
          this.$alert(message + '不在线，请手动进行输入', '提示', {
            confirmButtonText: '确定'
          })
          this.formPublish.type = 1
          isAlertShown = true
        }

      }

    },
    // 假设你在点击父房间电表之后，继续执行每个房间的电表操作
    async handleReadings() {
      let that = this;
      const { otherApartmentMeterReadings } = that.formPublish
        try {
            // 当前房间的电表读取
            await this.clickMeterReading(this.formPublish.apartmentUuid)
            // 先点击父房间的电表读取
            await this.clickParentMeterReading(this.formPublish.parentApartmentUuid);
            
            // 然后依次检查其他房间的电表读取条件
            if (otherApartmentMeterReadings.length >= 1) {
                that.formPublish.firstMeterReading = otherApartmentMeterReadings[0].elect; // 第1个房间当前电表读数
                that.formPublish.firstLastMeterReading = otherApartmentMeterReadings[0].lastElect !== null ? otherApartmentMeterReadings[0].lastElect : 0; // 第1个房间上次电表读数，若为null则设置为0
                await that.clickFirstMeterReading(that.formPublish.otherApartmentMeterReadings[0].apartmentUuid);
            }
            if (otherApartmentMeterReadings.length >= 2) {
                that.formPublish.secondMeterReading = otherApartmentMeterReadings[1].elect; // 第2个房间当前电表读数
                that.formPublish.secondLastMeterReading = otherApartmentMeterReadings[1].lastElect !== null ? otherApartmentMeterReadings[1].lastElect : 0; // 第2个房间上次电表读数
                await this.clickSecondMeterReading(this.formPublish.otherApartmentMeterReadings[1].apartmentUuid);
            }
            if (otherApartmentMeterReadings.length >= 3) {
                that.formPublish.thirdMeterReading = otherApartmentMeterReadings[2].elect; // 第3个房间当前电表读数
                that.formPublish.thirdLastMeterReading = otherApartmentMeterReadings[2].lastElect !== null ? otherApartmentMeterReadings[2].lastElect : 0; // 第3个房间上次电表读数
                await this.clickThirdMeterReading(this.formPublish.otherApartmentMeterReadings[2].apartmentUuid);
            }
            if (otherApartmentMeterReadings.length >= 4) {
                that.formPublish.fourthMeterReading = otherApartmentMeterReadings[3].elect; // 第4个房间当前电表读数
                that.formPublish.fourthLastMeterReading = otherApartmentMeterReadings[3].lastElect !== null ? otherApartmentMeterReadings[3].lastElect : 0; // 第4个房间上次电表读数
                await this.clickFourthMeterReading(this.formPublish.otherApartmentMeterReadings[3].apartmentUuid);
            }
            if (otherApartmentMeterReadings.length >= 5) {
                that.formPublish.fifthMeterReading = otherApartmentMeterReadings[4].elect; // 第5个房间当前电表读数
                that.formPublish.fifthLastMeterReading = otherApartmentMeterReadings[4].lastElect !== null ? otherApartmentMeterReadings[4].lastElect : 0; // 第5个房间上次电表读数
                await this.clickFifthMeterReading(this.formPublish.otherApartmentMeterReadings[4].apartmentUuid);
            }
        } catch (error) {
            console.error('处理电表读取时发生错误:', error);
        }
    },

    clickSubmit() {
      let that = this;
      // that.loadingbtn = true
      that.formPublish.attachment = that.beforeImgslist.join()
      console.log('that.formPublish.attachment',that.formPublish.attachment);
      that.formPublish.public = that.publicImgslist.join()
      console.log('that.formPublish.public',that.formPublish.public);
      that.publicArea.elect = that.formPublish.parentElect; // 当前电表读数
      that.publicArea.lastElect = that.formPublish.parentLastElect; // 上次电表读数
      that.publicArea.attachment = that.formPublish.public; // 抄表图片
      that.publicArea.apartmentUuid = that.formPublish.parentApartmentUuid; // 抄表图片
      that.publicArea.updateBeforeCurrentElect = that.formPublish.parentElectOne;
      that.publicArea.updateBeforeLastElect =  that.formPublish.parentLastElecttOne;
      that.publicArea.containRentUserParent = that.formPublish.containRentUserParent; // 公区是否有无租户
      that.publicArea.priceElect = that.formPublish.electParentPrice; //公区电费单价
      that.publicArea.priceWater = that.formPublish.waterParentPrice; //公区水费单价
      that.publicArea.amountWater = that.formPublish.waterParentAmount; //公区水费总额
      that.publicArea.amountElect = that.formPublish.electParentAmount; //公区电费总额
      that.publicArea.electNum = that.formPublish.parentElectNum; //公区电表号
      that.publicArea.water = that.formPublish.parentWater; //公区水表当前读数
      that.publicArea.lastWater = that.formPublish.lasParenttWater; //公区上次水表读数
      that.publicArea.waterDataSources = that.formPublish.waterParentDataDevice; //公区水表数据是否异常
      that.publicArea.electParentDataDevice = that.formPublish.electParentDataDevice; //公区水表数据是否异常
      console.log('公摊数据',that.publicArea);
      // console.log('that.formPublish.otherApartmentMeterReadings',that.formPublish.otherApartmentMeterReadings);

      if (that.disableSubmitButton) {
        // 如果按钮已被禁用，则直接返回
        return;
      }
      this.$refs['formPublish'].validate(valid => {
        if (valid) {
          // 禁用按钮
          that.disableSubmitButton = true;
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({ ...loadingOptions });
          let { uuid } = that.equipmentData;
          let {
            lastElect,
            lastWater,
            elect,
            water,
            type,
            isBill,
            lastTime,
            createdTime,
            attachment,
            otherApartmentMeterReadings,
            parentElect,
            updateBeforeLastElect,
            updateBeforeCurrentElect,
            containRentUser,
            amountElects,
            amountWaters,
            priceElects,
            priceWaters,
            waterDataDevice,
            electDataDevice,
            publicAmount,
            priceElect
          } = that.formPublish;
          console.log('updateBeforeCurrentElect',updateBeforeCurrentElect);
          // 假设要为每一项增加的参数为 newParam
          // const others = otherApartmentMeterReadings.map(item => {
          //   return {
          //     ...item, // 保留原有属性
          //     updateBeforeLastElect: item.lastElect,
          //     updateBeforeCurrentElect: item.elect,
          //   };
          // });
          const others = otherApartmentMeterReadings;
          // console.log('other',other);
          let data = {
            uuid,
            lastElect,
            lastWater,
            elect,
            water,
            type,
            isBill,
            lastTime,
            createdTime,
            attachment,
            others,
            publicArea:that.publicArea,
            updateBeforeLastElect,
            updateBeforeCurrentElect,
            containRentUser,
            amountElect:amountElects,
            amountWater:amountWaters,
            priceElect,
            priceWater:priceWaters,
            waterDataDevice,
            electDataDevice,
            publicAmount
          };
          if(type === 2){
            data = {
              uuid,
              lastElect,
              lastWater,
              elect,
              water,
              type,
              isBill,
              others,
              lastTime,
              createdTime,
              publicArea:that.publicArea ,
              updateBeforeLastElect,
              updateBeforeCurrentElect,
              containRentUser,
              amountElect:amountElects,
              amountWater:amountWaters,
              priceElect:priceElects,
              priceWater:priceWaters,
              waterDataDevice,
              electDataDevice,
              publicAmount
            };
          }
          console.log('lastElect',lastElect);
          console.log('elect',elect);
          const readings = this.formPublish.otherApartmentMeterReadings;

          // 检查是否有任何电表读数为0
          const hasZeroReading = readings.some(reading => reading.elect === 0);

          // 检查是否所有电表读数为-1
          const allReadingsAreMinusOne = readings.every(reading => reading.elect === -1);
          // // 获取containRentUser的值
          const hasContainRentUserFalse = readings.some(reading => reading.containRentUser === false);
          const handleSettlement = () => {
            hydropowerSaveAPI(data)
                .then(res => {
                  // that.loadingbtn = false
                  MessageSuccess('结算成功');
                  loading.close();
                  that.disableSubmitButton = false;
                  that.clickCancel();
                })
                .catch(err => {
                  // that.loadingbtn = false
                  loading.close();
                  that.disableSubmitButton = false;
                });
          };
          // 综合判断
          const hasInvalidReadings = lastElect === 0 || elect === 0 || parentElect === 0 || hasZeroReading || allReadingsAreMinusOne || parentElect === -1;
          if (hasInvalidReadings && !hasContainRentUserFalse) {
            this.$confirm('存在为0的电表读数，是否进行抄表？', '注意', {
              confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
            }).then(() => {
              handleSettlement();
            }).catch(() => {
              loading.close();
              that.disableSubmitButton = false;
            });
          }else{
            handleSettlement();
          }
        }
      });
    },
    clickCancel () {
      this.$emit('handleSearch', false)
      this.dialogVisible = false
    },
    async getHydropowerBill () {
      let that = this
      const {
        lastElect,
        lastWater,
        elect,
        water,
        isBill,
        lastTime,
        createdTime,
        type,
        waterDataSources,
        electDataSources
      } = that.formPublish
      const { uuid } = this.equipmentData
      // that.loading = true
      const data = {
        uuid,
        type,
      }
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      console.log('loadingaaaaaaaaa',loading);
      let oldFormPublish = this.formPublish
      let res = await hydropowerBill(data).catch(err => {loading.close();})
      if (res) {
        if (res) {
          let {
            info: {
              lastElect,
              lastWater,
              elect,
              water,
              priceWater,
              priceElect,
              amountWater,
              amountElect,
              lastTime,
              createTime,
              waterDataSources,
              electDataSources,
              otherElectDataSources,
              parentElect,
              parentLastElect,
              parentElectNum,
              otherApartmentMeterReadings,
              parentApartmentUuid,
              currentElectFail,
              containRentUser,
              parentWater,
              lasParenttWater,
              electParentPrice,
              waterParentPrice,
              waterParentAmount,
              electParentAmount,
              containRentUserParent,
              waterParentDataDevice,
              electParentDataDevice,
              apartmentUuid,
              amountElectTotal
            }
          } = res
          this.parentLastElect = parentLastElect
          console.log("priceElect",priceElect);
          console.log("priceWater",priceWater);
          console.log('apartmentUuid',apartmentUuid); 
          console.log('parentElect',parentElect);     
          that.formPublish.updateBeforeLastElect =  lastElect  // 更改前上次读数
          that.formPublish.updateBeforeCurrentElect =  elect   //更改前当前读数
          that.formPublish.parentElectOne = parentElect;
          console.log('that.formPublish.parentElectOne',that.formPublish.parentElectOne);
          that.formPublish.parentLastElecttOne = parentLastElect;
          that.formPublish.containRentUser = containRentUser;
          that.formPublish.parentWater = parentWater;
          that.formPublish.lasParenttWater = lasParenttWater;
          that.formPublish.electParentPrice = electParentPrice;
          that.formPublish.waterParentPrice = waterParentPrice;
          that.formPublish.waterParentAmount = waterParentAmount;
          that.formPublish.electParentAmount = electParentAmount;
          that.formPublish.containRentUserParent = containRentUserParent;
          that.formPublish.waterParentDataDevice = waterParentDataDevice;
          that.formPublish.electParentDataDevice = electParentDataDevice;
          that.formPublish.priceElects = priceElect;
          that.formPublish.priceWaters = priceWater;
          that.formPublish.amountWaters = amountWater
          that.formPublish.amountElects = amountElect
          console.log("amountWaters", that.formPublish.amountWaters);
          priceWater = ParseFloatFormatFour(priceWater)
          priceElect = ParseFloatFormatFour(priceElect)
          console.log("priceElectsss",priceElect);
          that.formPublish.priceElect = priceElect
          console.log("that.formPublish.priceElect",that.formPublish.priceElect);
          amountWater = ParseFloatFormat(amountWater)
          amountElect = ParseFloatFormat(amountElect)
          waterDataSources = parseInt(waterDataSources)
          electDataSources = parseInt(electDataSources)
          otherElectDataSources = parseInt(otherElectDataSources)
          console.log('lastTime',lastTime);
          console.log("electParentPrice",electParentPrice);     
          amountElectTotal = parseInt(amountElectTotal)
          that.formPublish.amountElectTotal =  amountElectTotal  // 	当前房源电费金额合计（金额+公摊）
          lastTime = lastTime
              ? timeFormat(new Date(lastTime),'yyyy-MM-dd HH:mm:ss')
              : timeFormat(new Date(),'yyyy-MM-dd HH:mm:ss')
          console.log('timeFormat(new Date(lastTime))',timeFormat(new Date(lastTime)),'yyyy-MM-dd HH:mm:ss');
          let createdTime = createTime
              ? timeFormat(new Date(createTime),'yyyy-MM-dd HH:mm:ss')
              : timeFormat(new Date(),'yyyy-MM-dd HH:mm:ss')
          console.log('timeFormat(new Date(createdTime))',timeFormat(new Date(createdTime)),'yyyy-MM-dd HH:mm:ss');
          const other  = otherApartmentMeterReadings
          this.otherApartmentMeterReadings = other.map(item => {
            return {
              ...item, // 保留原有属性
              updateBeforeLastElect: item.lastElect,
              updateBeforeCurrentElect: item.elect,
            };
          });
          console.log('this.otherApartmentMeterReadings',this.otherApartmentMeterReadings);
          that.formPublish = {
            ...oldFormPublish,
            lastElect,
            lastWater,
            elect,
            water,
            priceWater,
            priceElect,
            amountWater,
            amountElect,
            lastTime,
            createdTime,
            waterDataSources,
            electDataSources,
            otherElectDataSources,
            parentElect,
            parentLastElect,
            parentElectNum,
            parentApartmentUuid,
            otherApartmentMeterReadings:this.otherApartmentMeterReadings,
            currentElectFail,
            apartmentUuid
          }
         }

      }
      loading.close();
    },
    async getAmountDatablur () {
      let that = this
      let { uuid } = that.equipmentData;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      const {
        parentApartmentUuid,parentLastElect,parentElect,otherApartmentMeterReadings,lastElect, elect, apartmentUuid,
      } = that.formPublish
      console.log('otherApartmentMeterReadings',otherApartmentMeterReadings);
    const  params = {
        apartmentUuid, lastElect, elect,
        parentApartmentUuid,parentLastElect,parentElect,otherApartmentMeterReadings
      }
      // 根据类型确定接口传递的参数
    //   if(type === 'apartment'){
    //      params={ apartmentUuid, lastElect, elect}
    //   } else if(type === 'parent'){
    //      params={  parentApartmentUuid	: that.formPublish.parentApartmentUuid, parentLastElect: that.formPublish.parentLastElect, parentElect: that.formPublish.parentElect}
    //   }else if(type === 'otherApartmentMeterReadings[0]'){
    //     params = {
    //     otherApartmentMeterReadings: [
    //         {
    //             apartmentUuid: that.formPublish.otherApartmentMeterReadings[0].apartmentUuid,
    //             lastElect: that.formPublish.otherApartmentMeterReadings[0].lastElect,
    //             elect: that.formPublish.otherApartmentMeterReadings[0].elect
    //         }
    //     ]
    // };
    //   }

      // const params = type === 'apartment'
      //   ? { apartmentUuid, lastElect, elect }
      //   : { parentApartmentUuid	: that.formPublish.parentApartmentUuid, parentLastElect: that.formPublish.parentLastElect, parentElect: that.formPublish.parentElect };
      // getCalculationData
      console.log('params',params);
      console.log('parentElect',this.formPublish.parentElect);
      await getElectCalculationAmount(params).then(res => {
        this.dialogVisible = true
        console.log("res",res);
        let {
          info: { amountWater, amountElect,otherApartmentMeterReadings,electParentAmount,amountElectTotal,publicAmount,priceElect },
          info
        } = res
        that.formPublish.amountWaters = amountWater
        that.formPublish.amountElects = amountElect
        amountWater = ParseFloatFormat(amountWater)
        amountElect = ParseFloatFormat(amountElect)
        amountElectTotal = ParseFloatFormat(amountElectTotal)
        console.log("amountElect",amountElect);
        console.log("amountWater",amountWater);

        that.formPublish.amountWater = amountWater
        that.formPublish.amountElect = amountElect
        that.formPublish.amountElectTotal = amountElectTotal
        that.formPublish.priceElect = priceElect
        console.log('parentElect杀杀杀',this.formPublish.parentElect);
        console.log('that.formPublish.parentLastElect',that.formPublish.parentLastElect);
        if(that.formPublish.parentLastElect === null ||that.formPublish.parentLastElect === 0 ){
          that.formPublish.parentLastElect = that.formPublish.parentElect
        }

        // 假设 originalData 包含了原始数据
        const originalData = [...otherApartmentMeterReadings];  // 保存原始数据
        otherApartmentMeterReadings = that.formPublish.otherApartmentMeterReadings.map((item, index) => {
          // 在这里检查并赋值 lastElect，如果为 null 或 0
          if (item.lastElect === null || item.lastElect === 0) {
            item.lastElect = item.elect;  // 如果 lastElect 为 null 或 0，赋值为 elect
          }
          return {
            ...item,  // 保留当前对象的其他属性
            amountElect: originalData[index].amountElect,  // 使用原始数据的 amountElect
            priceElect:originalData[index].priceElect
          };
        });
      that.formPublish.otherApartmentMeterReadings = otherApartmentMeterReadings;
      console.log('that.formPublish.otherApartmentMeterReadings',that.formPublish.otherApartmentMeterReadings);
      that.formPublish.publicAmount = publicAmount
        loading.close();
      })
      .catch(err => {
            loading.close();
            // that.disableSubmitButton = false;
      });
    },
    // 当前电表实时数据
    async clickMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.elect = elect;
        that.formPublish.updateBeforeCurrentElect =  elect   //更改前当前读数
        that.formPublish.currentElectFail = currentElectFail;      
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },
    // 公摊电表实时数据
    async clickParentMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.parentElect = elect
        that.formPublish.parentElectOne = elect;
        that.formPublish.publicArea.updateBeforeCurrentElect = elect
        console.log('that.formPublish.publicArea.updateBeforeCurrentElect',that.formPublish.publicArea.updateBeforeCurrentElect);
        that.formPublish.parentCurrentElectFail = currentElectFail
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },
    // 第一个子房间电表实时数据 
    async clickFirstMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('1 Apartment UUID:', apartmentUuid);
      console.log("1",that.formPublish.otherApartmentMeterReadings[0].lastElect); 
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("resaaaa",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.otherApartmentMeterReadings[0].elect = elect
        that.formPublish.otherApartmentMeterReadings[0].updateBeforeCurrentElect =  elect   //更改前当前读数
        console.log('that.formPublish.otherApartmentMeterReadings[0].updateBeforeCurrentElect ',that.formPublish.otherApartmentMeterReadings[0].updateBeforeCurrentElect );
        that.formPublish.otherApartmentMeterReadings[0].currentElectFail = currentElectFail
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },
    // 第二个子房间电表实时数据 
    async clickSecondMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.otherApartmentMeterReadings[1].elect = elect
        that.formPublish.otherApartmentMeterReadings[1].updateBeforeCurrentElect =  elect   //更改前当前读数
        that.formPublish.otherApartmentMeterReadings[1].currentElectFail = currentElectFail
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    }, 
    // 第三个子房间电表实时数据 
    async clickThirdMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.otherApartmentMeterReadings[2].elect = elect
        that.formPublish.otherApartmentMeterReadings[2].updateBeforeCurrentElect =  elect   //更改前当前读数
        that.formPublish.otherApartmentMeterReadings[2].currentElectFail = currentElectFail
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },     
    // 第四个子房间电表实时数据 
    async clickFAourthMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.otherApartmentMeterReadings[3].elect = elect
        that.formPublish.otherApartmentMeterReadings[3].updateBeforeCurrentElect =  elect   //更改前当前读数
        that.formPublish.otherApartmentMeterReadings[3].currentElectFail = currentElectFail 
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },   
    // 第五个子房间电表实时数据 
    async clickFifthMeterReading(apartmentUuid){
      let that = this;
      // 打印 apartmentUuid，检查是否正确传递
      console.log('Apartment UUID:', apartmentUuid);
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({ ...loadingOptions });
      await getElectMetereadingAPI({uuid:apartmentUuid}).then(res => {
        console.log("res",res);
        let {
          info: { elect,currentElectFail },
        } = res
        console.log('elect',elect);
        that.formPublish.otherApartmentMeterReadings[4].elect = elect
        that.formPublish.therApartmentMeterReadings[4].updateBeforeCurrentElect =  elect   //更改前当前读数
        that.formPublish.otherApartmentMeterReadings[4].currentElectFail = currentElectFail
        loading.close();
      })
      .catch(err => {
            loading.close();
      });
    },
  },
  watch: {
    'formPublish.type' (value) {
      if (value === 2) {
        this.getHydropowerBill();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item__content {
  flex-wrap: wrap;
}
.itemWater /deep/ .el-form-item__label {
  color: blue !important;
  font-size: 16.5px !important;
}

.itemElect /deep/ .el-form-item__label {
  color: green !important;
  font-size: 16.5px !important;
}
/deep/ .el-dialog--center .el-dialog__body {
  padding:15px 25px 20px
}
.el-form-item--small.el-form-item{
  margin-bottom:10px;
}
/deep/ .el-form-item__content{
  display:flex
}
/deep/ .upload-picture-card {
  $imageWidth: 48px;
  $imageHeight: 48px;

  .el-upload--picture-card {
    width: $imageWidth;
    height: $imageHeight;
    line-height: $imageHeight;
    box-sizing: border-box;

  }

  // 预览
  .el-upload-list--picture-card {
    line-height: 0;
    .el-upload-list__item {
      width: $imageWidth;
      height: $imageHeight;
      box-sizing: border-box;
    }

    .el-upload-list__item-thumbnail {
      width: $imageWidth;
      height: $imageHeight;
      // padding: 5px;
      box-sizing: border-box;
    }

    
    .el-upload-list__item-actions span {
      margin: 0;

      &:hover {
        color: #409EFF;
      }

      &.el-upload-list__item-download {
        // margin: 0 5px;
      }
    }
  }

  .el-upload__tip {
    line-height: 15px;
  }
}
/deep/ .el-upload--picture-card i{
  font-size:12px
}
/deep/.el-upload-list__item-actions{
  font-size:14px
}

</style>
