import request from '@http';

// 2022/05/20 王江毅 日志列表
export function getLogs(params) {
    return request({
        method: 'GET',
        url: `/api/audit`,
        params
    })
}

// 2022/05/20 王江毅 消息列表
export function getMsgs(params) {
    return request({
        method: 'GET',
        url: `/api/alerts/type`,
        params
    })
}

// 2022/06/14 王江毅 消息已读处理
export function dismiss(uuid) {
    return request({
        method: 'put',
        url: `/api/alerts/outstanding/${uuid}/dismiss`
    })
}

// 2023/02/21 王江毅 操作日志列表
export function getOperationLogList(params) {
    return request({
        method: 'GET',
        url: `/api/system/log/list`,
        params
    })
}

// 2023-12-8-陈守亮-短信日志
export function shortmessageLogList(params) {
    return request({
        method: 'GET',
        url: `/api/system/log/sms/list`,
        params
    })
}
// 2023-12-8-张晓瑜-隐私政策
export function privacyAgreementList(params) {
    return request({
        method: 'GET',
        url: `/api/privacy_agreement/web_page`,
        params
    })
}
//电表提醒列表
export function privacyAgreementSave(params) {
    return request({
        method: 'GET',
        url: `/api/elect_meter_abnormal_log/community/page`,
        params
    })
}
// 读小区异常信息  已读小区异常信息
export function getread_message(params) {
    return request({
        method: 'put',
        url: `/api/elect_meter_abnormal_log/community/read_message`,
        params
    })
}
// 查询所有房间异常消息列表
export function getread_message_all(params) {
    return request({
        method: 'get',
        url: `/api/elect_meter_abnormal_log/apartment/page`,
        params
    })
}
// 2023/04/17 王珂 消息推送管理
export function read_message(params) {
    return request({
        method: 'put',
        url: `/api/elect_meter_abnormal_log/apartment/read_message`,
        params,
    });
}
