<template>
  <r-e-dialog :title="title" :visible.sync="dialogVisible"  width="800px"
               @close="handleClose">
    <r-e-table style="margin-top: -10px" class="bg-white" ref="orderTableRef" :dataRequest="getMsgs" :columns="tableColumn" :query="formSearch"
               :height="430">
      <el-table-column prop="title" label="房间名称" :show-overflow-tooltip="true">
        <template slot-scope="{ row }">
          <div class="table-tools">
            <span class="table-btn" v-if="!row.isRead"  @click="lookIten(row)">{{row.apartmentName}}</span>
            <span class="table-btn" v-if="row.isRead" style="color: #8c939d" @click="lookIten(row)">{{row.apartmentName}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="电表号" :show-overflow-tooltip="true">
        <template slot-scope="{ row }">
          <div class="table-tools">
            <span class="table-btn" v-if="!row.isRead"  >{{row.electNumber}}</span>
            <span class="table-btn" v-if="row.isRead" style="color: #8c939d" >{{row.electNumber}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="操作" width="80">

        <template slot-scope="{ row }">
          <div class="table-tools" v-if="!row.isRead">
            <span class="table-btn" @click="read(row)">已读</span>
          </div>
        </template>
      </el-table-column>
    </r-e-table>

  </r-e-dialog>
</template>

<script>
//暂时隐藏了消息提醒

import REDialog from "@/components/Gloabl/r-e-dialog/index.vue";
import {tableColumnlook} from "@/views/system-management/meter-reminder/data";
import {getread_message_all, read_message} from "@/api/log";
import {MessageSuccess} from "@custom/message";

export default {
  name: "meter-reminder",
  data() {
    return {
      dialogVisible:false,
      title:'连续三天及以上抄表数据异常房间',
      tableColumn:tableColumnlook(this),
      uuid:'',
    };
  },
  components: {REDialog},
  async mounted() {

  },

  methods: {
    lookIten(row){
      this.dialogVisible = false
      this.$router.push({name: "door-management", params: {data: {apartmerntName: row.apartmentName}}})
    },
    getMsgs(params){
     return getread_message_all({...params,uuid:this.uuid})
    },
    handleClose(){
      this.dialogVisible = false;
    },
    open(uuid){
      this.uuid = uuid
      this.dialogVisible = true;
    },
    gohome(row){

    },
    //已读
    read(row){
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      read_message({uuid:row.uuid}).then(res => {
        if (res.msg === '操作成功') {
          MessageSuccess("操作成功");
          this.$refs["orderTableRef"].getTableData();
        }
      }).finally(() => {loading.close()})
    }
  }
}
</script>
<style lang="scss" scoped>
.message-reminder {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: flex-start;
  }

  /deep/ .r-e-table {
    .el-table .cell {
      white-space: nowrap;
      text-align: center;
    }
  }
}
</style>
