<template>
    <section class="call">
        <div>
            <el-select v-model="formSearch.type" placeholder="请选择" size="small">
<!--              2023-11-2-陈守亮新增全部和押金字段，今天天气良好，但是我肚子好饿，中午吃皮蛋瘦肉粥！！！-->
                <el-option label="全部" value=""/>
                <el-option label="押金" :value="0"/>
              <el-option label="杂费" :value="1"/>
              <el-option label="房租" :value="2"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>
        <r-e-table ref="tableRef" :dataRequest="balanceWaterList" :columns="tableColumns" :query="formSearch" :height="650">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import { callColumns } from "@/views/rental-management/tenant-management/data";
    import {balanceWaterList} from '@/api/tenant-management'
    export default {
        name: "call",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                tableColumns: callColumns,
                formSearch:{}
            };
        },

        components:{  },
        async created() {

        },
        methods: {
            balanceWaterList(params){
                params.uuid = this.tenantData.uuid;
                return balanceWaterList(params);
            },
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {type:""};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
        }
    }
</script>

<style scoped>

</style>