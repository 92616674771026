import {timeFormat,numberFormat} from "@custom";
import router from "@/router/index"
export const tableColumn = [
    {
        label: "序号",
        prop: "serialNumber",
        width: 200
    },
    /*{
        prop: "title",
        label: "消息",
        showOverflowTooltip: true
    },*/
    // {
    //     prop: "type",
    //     label: "模块",
    //     width: 100
    // },
    {
        prop: "abnormalTime",
        label: "提醒时间",
        width: 200,
        render: (h, {row}) => {
            const timeStamp = !row.abnormalTime ? "" : timeFormat(new Date(row.abnormalTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }
    },
    /*{
        prop: "operation",
        label: "操作"
    },
    {
        prop: "recordType",
        label: "记录类型"
    },
    {
        prop: "record",
        label: "操作记录"
    }*/
];
export function tableColumnlook(_this){
    return  [
        {
            label: "序号",
            prop: "serialNumber",
            width: 70
        },
        // {
        //     prop: "type",
        //     label: "模块",
        //     width: 100
        // },
        {
            prop: "abnormalTime",
            label: "最后抄表时间",
            render: (h, {row}) => {
                const timeStamp = !row.abnormalTime ? "" : timeFormat(new Date(row.abnormalTime), 'yyyy-MM-dd HH:mm:ss');
                return h("span", timeStamp);
            }
        },
        // {
        //     prop: "apartmentName",
        //     width: 300,
        //     label: "房间名称",
        //     render: (h, {row}) => {
        //         const {apartmentName,isRead} = row;
        //
        //         if(!isRead){
        //             return h("el-link", {
        //                 props: {type: "primary", underline: false},
        //                 on: {
        //                     click() {
        //                         _this.dialogVisible = false
        //                         _this.$router.push({name: "door-management", params: {data: {apartmerntName: apartmentName}}})
        //                     }
        //                 }
        //             }, apartmentName);
        //         }else {
        //             return apartmentName
        //         }
        //
        //     }
        // },
        // {
        //     prop: "electNumber",
        //     label: "电表号"
        // },
        /*{
            prop: "operation",
            label: "操作"
        },

        {
            prop: "record",
            label: "操作记录"
        }*/
    ];
}

