<template>
  <r-e-dialog title="续租" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
              width="700px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <el-form ref="formPublish" label-width="125px" size="small" :model="formPublish" :rules="rules">
      <div class="flex">
        <el-form-item label="房源信息">
          <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）"
                    style="width: 520px;"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="租赁时间" prop="monthSize">
          <div class="flex justify-between" style="width: 200px;">
            <el-input-number v-model="formPublish.monthSize" style="width: 160px" :min="1" label="租聘时间"/>
            <span>(月)</span>
          </div>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="续租开始日期" prop="startDate">
          <el-date-picker disabled v-model="formPublish.startDate" type="date" placeholder="选择日期"
                          style="width: 200px;"/>
        </el-form-item>
        <el-form-item label="续租结束日期" prop="endDate">
          <el-date-picker disabled v-model="formPublish.endDate" type="date" placeholder="选择日期"
                          style="width: 200px;"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="紧急联系人" prop="contact">
          <el-input type="text" v-model="formPublish.contact" placeholder="请填写紧急联系人"/>
        </el-form-item>
        <el-form-item label="紧急联系人电话" prop="contactTel">
          <el-input type="text" v-model="formPublish.contactTel" :maxlength="11" placeholder="请填写紧急联系人电话"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="身份证地址">
          <el-input type="text" v-model="formPublish.addressId" placeholder="请填写身份证地址"/>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input type="text" v-model="formPublish.eMail" placeholder="请填写邮箱"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="月租金" prop="amount">
          <el-input type="number" v-model="formPublish.amount" placeholder="请填写月租金" disabled
                    v-loading="loading"/>
        </el-form-item>
        <el-form-item label="服务费" prop="tip">
          <el-input type="number" v-model="formPublish.tip" placeholder="请填写服务费" disabled
                    v-loading="loading"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="是否生成账单">
          <div style="width: 200px;">
            <el-radio-group v-model="formPublish.bill">
              <el-radio-button :label="true">是</el-radio-button>
              <el-radio-button :label="false">否</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="是否生成电子合同">
          <el-radio-group v-model="formPublish.face">
            <el-radio-button :label="true">是</el-radio-button>
            <el-radio-button :label="false">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
      <!-- 2024/04/09 张晓瑜新增经办人 管理人字段 -->
      <div class="flex">
        <el-form-item label="经办人" prop="manager">
          <el-input type="text" v-model="formPublish.manager" placeholder="请输入经办人"/>
        </el-form-item>
        <el-form-item label="管理人" prop="keeper">
          <el-input type="text" v-model="formPublish.keeper" placeholder="请输入管理人"/>
        </el-form-item>
      </div>
      <!-- 房屋交割单 -->
      <div v-if="isBzfOkType === 0 || isBzfOkType === 2">
        <div class="el-descriptions__header">
          <div class="el-descriptions__title">
            <div data-v-037521bc="" class="title">房屋交割单</div>
          </div>
          <div> (上传格式：jpg、png、jpeg、bmp、gif文件)</div>
          <div style="width: 200px"></div>
        </div>
        <div class="flex">
          <div>
            <el-form-item label="厨房" label-width="100px" class="samplePhotos">
              <upload-avatar isType="厨房" :uuid="kitchenPic[0]" @on-remove="enterpriseRemove" :showTip="false"
                             @on-success="enterpriseClick" :limit="1"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="客厅" label-width="100px" class="samplePhotos">
              <upload-avatar :uuid="drawingPic[0]" @on-remove="enterpriseRemove" :showTip="false" isType="客厅"
                             @on-success="enterpriseClick" :limit="3"/>
            </el-form-item>
          </div>
        </div>

        <div class="flex">
          <div>
            <el-form-item label="餐厅" label-width="100px" class="samplePhotos">
              <upload-avatar :uuid="restaurantPic[0]" isType="餐厅" @on-remove="enterpriseRemove" :showTip="false"
                             @on-success="enterpriseClick" :limit="1"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="卧室" label-width="100px" class="samplePhotos">
              <upload-pictureCard :uuidList="bedroomPic" isType="卧室" @on-remove="enterpriseRemove" :showTip="false"
                                  @on-success="enterpriseClick" :limit="3"/>
            </el-form-item>
          </div>
        </div>
        <div class="flex">
          <div>
            <el-form-item label="其他" label-width="100px" class="samplePhotos">
              <upload-pictureCard :uuidList="otherPic" isType="其他" @on-remove="enterpriseRemove" :showTip="false"
                                  @on-success="enterpriseClick" :limit="4"/>
            </el-form-item>
          </div>
          <div>

          </div>
        </div>

      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {renewalContract, getSignAmount, isRelet, bzfCreate, apartmentapi, getcontract,getEqbContractUrlApi2} from "@/api/contract"
import {isbzf, isApartmentType, getHousecontracttype} from "@/api/house-resource";
import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
import {timeFormat, ParseFloatFormat, ParseIntFormat} from "@custom";
import {openNewWindow} from "@/utils/hooks";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import UploadAvatar from "@/components/Upload/upload-avatar.vue";

export default {
  name: "dialog-contract-xuqian",
  components: {UploadAvatar, uploadPictureCard},
  data() {
    return {
      electContractTypeEnumCode:null,//是什么类型合同  -1管弹框  20是企业关弹框
      isBzfOkType:null,
      tiaozhuanis: false,
      kitchenPic: [],//厨房照片数组
      drawingPic: [],//客厅照片数组
      restaurantPic: [],//餐厅照片数组
      bedroomPic: [],//卧室照片数组
      otherPic: [],//其他照片数组
      isCreateElectContract: null,
      dialogVisible: false,
      formPublish: {
        monthSize: 6,
        startDate: "",
        endDate: "",
        contact: "",
        contactTel: "",
        amount: 1050,
        tip: 100,
        bill: true,
        face: true,
        manager: "",//经办人
        keeper: "", //管理人
        bone: 0
      },
      rules: {
        dt: [{required: true, message: '请选择日期', trigger: 'change'}],
        monthSize: [{required: true, message: '请输入租赁时间', trigger: 'blur'}],
        amount: [{required: true, message: '请输入月租金', trigger: 'blur'}],
        tip: [{required: true, message: '请输入服务费', trigger: 'blur'}],
        endDate: [{required: true, message: '请选择结束日期', trigger: 'change'}],
        contact: [{required: true, message: '请输入紧急联系人', trigger: 'blur'}],
        contactTel: [{required: true, message: '请输入紧急联系人电话', trigger: 'blur'}],
        manager: [{required: true, message: '请输入经办人', trigger: 'blur'}],
        keeper: [{required: true, message: '请输入管理人', trigger: 'blur'}],
      },

      loading: false,

      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      isBzfOk: null,
    };
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    //打开续租弹框方法
    openDialog(data, contractInfo) {
      console.log('data', data, contractInfo, 'contractInfo');
      // getHousecontracttype({uuid：})
      let that = this;
      that.openDialogdata = data
      // kitchenPic:[],//厨房照片数组
      // drawingPic: [],//客厅照片数组
      // restaurantPic: [],//餐厅照片数组
      // bedroomPic: [],//卧室照片数组
      // otherPic: [],//其他照片数组
      if (contractInfo.kitchenImg) this.kitchenPic = contractInfo.kitchenImg.split(',')
      if (contractInfo.drawingRoomImg) this.drawingPic = contractInfo.drawingRoomImg.split(',')
      if (contractInfo.restaurantImg) this.restaurantPic = contractInfo.restaurantImg.split(',')
      if (contractInfo.bedroomImgList) this.bedroomPic = contractInfo.bedroomImgList
      if (contractInfo.otherDeliveryNoteImgList) this.otherPic = contractInfo.otherDeliveryNoteImgList

            //获取当前房间
            const {apartmentUuid} = this.currentHouse;
            //获取续租合同uuid，结束时间，服务费，房租，紧急联系人联系人电话
            let {uuid, endDate, serviceAmount, rent, contactName, contactPhone,manager,keeper} = data;
            //服务费，房租金额转换
            serviceAmount = ParseFloatFormat(serviceAmount);
            rent = ParseFloatFormat(rent);
            //续租开始时间计算加转换（开始时间=上一份合同结束时间+1天）
            let startDate = timeFormat(new Date(endDate + (8.64e7)));
            //保存旧续租数据
            let oldData = this.formPublish;
            //初始化数据
            this.formPublish = {
                ...oldData, bill: true, face: true, amount: rent, tip: serviceAmount, contact: contactName,
                contactTel: contactPhone, monthSize: 6, contractUuid: uuid, startDate,manager,keeper
            }
            //计算续租结束时间
            this.setEndDate(6);
            //获取房间金额
            this.getSignAmount();
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //调用接口查当前房间是否是保租房
            // isbzf(apartmentUuid).then(({info: isok}) => {
            //     that.isBzfOk = isok;
            //     //打开弹窗
            //     this.dialogVisible = true;
            // }).finally(() => loading.close());
            // const ress =  isApartmentType(apartmentUuid);
            // console.log('ress',ress);
      getEqbContractUrlApi2({uuid: data.uuid}).then(({info}) => {
        this.electContractTypeEnumCode = info.electContractTypeEnumCode
      })
            // 2024/07/12 张晓瑜修改为判断房源是那个 0 保租房 1青年公寓 2五象新区
            isApartmentType(apartmentUuid).then(({info}) => {
                // 0 或者 2 值为false
                if(info === 0 ){
                  that.isBzfOk = false
                }else{
                  that.isBzfOk = true
                }
                this.isBzfOkType = info//把合同类型保存
                //打开弹窗
                this.dialogVisible = true;
            }).finally(() => loading.close());
        },

    // 点击确认按钮事件
    clickSubmit() {
      let that = this;
      // console.log(that.bedroomPic,this.otherPic)

      if (this.isBzfOkType === 0 || this.isBzfOkType === 2) {
        if (that.kitchenPic.length < 1) {
          console.log('that.kitchenPic', that.kitchenPic)
          MessageWarning('请上传厨房照片');
          return;
        }
        if (that.drawingPic.length < 1) {
          console.log('that.drawingPic', that.drawingPic)
          MessageWarning('请上传客厅照片');
          return;
        }
        if (that.restaurantPic.length < 1) {
          console.log('that.restaurantPic', that.restaurantPic)
          MessageWarning('请上传餐厅照片');
          return;
        }
        if (that.bedroomPic.length < 1) {
          console.log('that.bedroomPic', that.bedroomPic)
          MessageWarning('请上传卧室照片');
          return;
        }
        if (that.otherPic.length < 1) {
          console.log('that.otherPic', that.otherPic)
          MessageWarning('请上传其他照片');
          return;
        }
      }
      const {apartmentUuid} = that.currentHouse;
      const {loadingOptions, isBzfOk} = that;
      console.log('isBzfOk', isBzfOk);
      //表单校验
      this.$refs["formPublish"].validate((valid) => {
        if (valid) {
          //解构数据
          let {
            contractUuid, monthSize, amount, tip, startDate, endDate, addressId, contact, contactTel,
            eMail, bill, face, manager, keeper, bone
          } = that.formPublish;
          monthSize = monthSize + 0
          //金额转换
          amount = ParseIntFormat(amount);
          tip = ParseIntFormat(tip);
          //判断续租时间是否在范围内 6-36个月
          if (monthSize < 6 || monthSize > 36) {
            MessageError('必须是6个月起租，最长不能超过3年');
            return;
          }
          //封装数据
          const data = {
            contractUuid, monthSize, amount, tip, startDate, endDate, addressId, contact, contactTel, eMail,
            // isNewElectronics 新的true,  旧的是false
            bill, face, isNewElectronics: !isBzfOk, manager, keeper, bone
          }

          console.log('datass', data);
          const h = that.$createElement;
          // 2024-1-31-陈守亮修改续租-x
          let loading = that.$loading(loadingOptions);
          //调用接口查询续租的合同是否有未支付账单  async
          isRelet(contractUuid).then(async res => {
            let {info} = res;
            //判断是否有未支付账单
            if (info) {
              console.log(that.openDialogdata)
              apartmentapi(apartmentUuid).then(res => {
                // console.log(res)
                if (res.info) {
                  // alert(that.openDialogdata.idType)
                  if (isBzfOk === true && that.openDialogdata.idType === '法人') {
                    // 2024/05/27 张晓瑜修改
                    // 传isCreateElectContract区分是否有合同，isCreateElectContract   true签约   false不签约
                    data.isCreateElectContract = false
                    // alert( data.isCreateElectContract)
                  }
                  if (isBzfOk === true && that.openDialogdata.idType === '个人') {
                    // 2024/05/28 张晓瑜修改为值为face一样的值
                    data.isCreateElectContract = face
                    // alert( data.isCreateElectContract)
                  }
                  console.log('提交数据', data);
                  let loading = that.$loading(loadingOptions);
                  if(this.isBzfOkType === 0 || this.isBzfOkType === 2){
                    data.kitchenImg = that.kitchenPic[0]
                    data.drawingRoomImg = that.drawingPic[0]
                    data.restaurantImg = that.restaurantPic[0]
                    data.bedroomImg = that.bedroomPic.join(',')
                    data.otherDeliveryNoteImg = that.otherPic.join(',')
                  }
                  renewalContract(data).then(res => {
                    // console.log(res)
                    let {info: {code, message, contract}} = res;
                    if (this.electContractTypeEnumCode === -1 || this.electContractTypeEnumCode === 20) {
                      // 不是217就刷新列表去完善续签-2024-9-4-csl
                      this.clickCancel()
                      return
                    }
                    //code=200续租成功
                    if (code === 200) {

                      //判断contract有无数据
                      if (contract) {
                        // alert('获取url')
                        //有数据个人续租成功
                        let {data} = contract;
                        //判断data是否有数据
                        if (data) {
                          console.log('isBzfOk', isBzfOk);
                          // alert('e签宝')
                          //有数据，拿出e签宝签署连接url
                          if (that.openDialogdata.idType === '个人' && isBzfOk === true) {
                            let {url} = data;
                            // alert(url,'跳转url')
                            //新界面打开
                            let tempwindow = window.open("", '_blank');
                            //新界面更换地址
                            tempwindow.location = url;
                          } else {
                            // alert('保租房')
                            // //赋值合同uuid
                            // contract.contractUuid = contract.uuid;
                            // //保存数据到localStorage
                            // localStorage.setItem("paramData", JSON.stringify(contract));
                            // //新建路由
                            // const newWindows = that.$router.resolve({path: "/test-canvas"});
                            // //新界面打开
                            // window.open(newWindows.href, '_blank');
                          }
                        } else {
                          MessageSuccess('合同续租成功');
                        }
                      }
                      //调用取消按钮事件，关闭弹窗
                      that.clickCancel();
                    } else {
                      MessageError(message)
                    }
                  }).finally(() => loading.close());
                } else {
                  data.isCreateElectContract = true;
                  // isNewElectronics 用来区分是诺诺还是e签宝  e签宝false
                  data.isNewElectronics = false;
                  console.log("线上", data);
                  let loading = that.$loading({...loadingOptions});
                  if(this.isBzfOkType === 0 || this.isBzfOkType === 2){
                    data.kitchenImg = that.kitchenPic[0]
                    data.drawingRoomImg = that.drawingPic[0]
                    data.restaurantImg = that.restaurantPic[0]
                    data.bedroomImg = that.bedroomPic.join(',')
                    data.otherDeliveryNoteImg = that.otherPic.join(',')
                  }
                  //调用接口创建保租房个人系统合同
                  renewalContract(data).then(res => {
                    // console.log(res)
                    let {info: {code, message, contract}} = res;
                    // alert(code)
                    //code=200续租成功
                    if (this.electContractTypeEnumCode === -1 || this.electContractTypeEnumCode === 20) {
                      // 不是217就刷新列表去完善续签-2024-9-4-csl
                      this.clickCancel()
                      return
                    }
                    if (code === 200) {
                      if (contract) {
                        // alert('获取url')
                        //有数据个人续租成功
                        let {data} = contract;
                        //判断data是否有数据
                        if (data) {
                          // MessageSuccess('合同续租成功');

                          // alert('e签宝')
                          //有数据，拿出e签宝签署连接url
                          if (that.openDialogdata.idType === '个人') {
                            let {url} = data;
                            console.log('url', url);
                            // alert(url,'跳转url')
                            //新界面打开
                            let tempwindow = window.open("", '_blank');
                            //新界面更换地址
                            tempwindow.location = url;
                          } else {
                            // alert('保租房')
                            // //赋值合同uuid
                            // contract.contractUuid = contract.uuid;
                            // //保存数据到localStorage
                            // localStorage.setItem("paramData", JSON.stringify(contract));
                            // //新建路由
                            // const newWindows = that.$router.resolve({path: "/test-canvas"});
                            // //新界面打开
                            // window.open(newWindows.href, '_blank');
                          }
                        } else {
                          MessageSuccess('合同续租成功');
                        }
                      }
                      //调用取消按钮事件，关闭弹窗
                      that.clickCancel();
                    } else {
                      MessageError(message);
                    }
                  }).finally(() => loading.close());
                  // 保租房签约
                  // that.$msgbox({
                  //   title: '提示',
                  //   message: h('p', null, [
                  //     h('p', {style: 'font-size: 20px'}, '请选择合同签约方式 '),
                  //     h('p', {style: 'color: red'}, '线上签约是签约电子合同，线下签约是签约纸质合同')
                  //   ]),
                  //   showClose: false,
                  //   showCancelButton: true,
                  //   showConfirmButton: true,
                  //   closeOnPressEscape: false,
                  //   closeOnClickModal: false,
                  //   center: true,
                  //   cancelButtonText: '线下签约',
                  //   confirmButtonText: '线上签约'
                  // }).then( action => {
                  //   // alert('线上保租房')
                  //   // data.isCreateElectContract =  false;
                  //   // 2024/04/11 张晓瑜修改isCreateElectContract值线上true 线下false
                  //   data.isCreateElectContract =  true;
                  //   console.log("线上", data);
                  //   let loading = that.$loading({...loadingOptions});

                  //   //调用接口创建保租房个人系统合同
                  //   renewalContract(data).then(res => {
                  //     console.log(res.info.contract.uuid)
                  //     getcontract(res.info.contract.uuid).then(res => {
                  //       // contract  合同对象
                  //       // isSon  是否为子房间  true 是  false 否
                  //       console.log(res)
                  //       res.info.contract.contractUuid = res.info.contract.uuid;
                  //       localStorage.setItem("paramData", JSON.stringify(res.info.contract));
                  //       // 判断唤起哪个合同
                  //       if(res.info.isSon){
                  //         openNewWindow(this.$router, "/test-canvas-son");
                  //       }else {
                  //         openNewWindow(this.$router, "/test-canvas");
                  //         const newWindows = this.$router.resolve({path: "/test-canvas"});
                  //       }
                  //       MessageSuccess('合同续租成功');
                  //       that.clickCancel();
                  //     }).finally(() => loading.close());


                  //   })


                  //   loading.close()
                  // }).catch(() => {
                  //   let loading = that.$loading({...loadingOptions});
                  //   // alert('线下保租房')
                  //   // 2024/04/11 张晓瑜修改isCreateElectContract值线上true 线下false
                  //   data.isCreateElectContract = false;
                  //   that.tiaozhuanis = false
                  //   renewalContract(data).then(res => {
                  //     let {info: {code, message, contract}} = res;
                  //     //code=200续租成功
                  //     if (code === 200) {
                  //       MessageSuccess('合同续租成功');
                  //       //调用取消按钮事件，关闭弹窗
                  //       loading.close()
                  //       that.clickCancel();
                  //     } else MessageError(message);
                  //   }).finally(() => loading.close());
                  // });

                }
              })
              //


              // //调用接口，传递数据，续租合同


            } else {
              //有未支付账单提示支付
              const h = that.$createElement;
              that.$msgbox({
                title: '注意',
                message: h('div',
                    {style: {color: 'red', fontSize: 16, fontWeight: 600}},
                    '存在未缴纳账单，需要缴纳完毕后方可续租'),
                type: 'warning'
              }).then(() => {
              }).catch(() => {
              });
            }
          }).finally(() => loading.close());
        }
      });
    },

    // 点击取消按钮事件
    clickCancel() {
      //调用上层方法刷新合同列表
      this.$emit('handleSearch');
      //关闭弹窗
      this.dialogVisible = false;
      //重置表单
      this.$refs["formPublish"].resetFields();
    },

    //计算续租结束时间方法
    setEndDate(value) {
      let that = this;
      if (value && value !== 0) {
        let {startDate} = this.formPublish;
        let date = new Date(startDate);
        let startYear = date.getFullYear();     //获取开始年
        let startMonth = date.getMonth() + 1;   // 获取开始月
        let startDay = date.getDate();          //获取开始日

        if (startDay === 1) value -= 1;          //开始日为1号，增加月数减1

        let year = parseInt(value / 12);     //月数换算年数
        value = value % 12;
        startMonth = startMonth + value;
        let endMonth = null;    //结束月
        if (startMonth > 12) {     //开始月加 余月数 大于12 表示还有满年，年数加1 结束月减12
          year += 1;
          endMonth = startMonth - 12;
        } else endMonth = startMonth;   //其他情况 结束月加月数

        let endYear = startYear + year; //结束年
        let endDay = null;  //结束日
        let d = new Date(endYear, endMonth, 0);         // 获取结束月当月最后一天
        if (startDay === 1) endDay = d.getDate();            // 开始日 为1号 结束日为结束月最后一天
        else if (endMonth === 2 && startDay > 29) endDay = d.getDate(); //结束月为2月 开始日 大于29号 结束日为当月(2月)最后一天
        else if (startDay - 1 === d.getDate()) endDay = d.getDate() - 1; //开始日减1 等于结束月最后一天 结束日为当月最后一天减1
        else if (startDay >= 2) endDay = startDay - 1;   //开始日大于等于2 结束日为开始日减1
        // 月份从0开始，所以结束月减1 endMonth - 1
        this.formPublish.endDate = timeFormat(new Date(endYear, endMonth - 1, endDay));
        //获取房间金额
        that.getSignAmount();
      }
    },

    //获取房间金额方法
    getSignAmount() {
      let that = this;
      //解构数据
      let {apartmentUuid} = this.currentHouse;
      let {startDate, endDate} = that.formPublish;
      that.loading = true;
      //调用接口，根据租赁时间查询该房间每月租金
      getSignAmount({apartmentUuid, startDate, endDate}).then(res => {
        //获取数据
        let {info = {}} = res;
        info = info || {};
        //解构金额
        let {displayAmount = 0, bone = 0, tipAmount = 0} = info;
        displayAmount = displayAmount || 0;
        bone = bone || 0;
        that.formPublish.bone = info.bone;
        // 2024-7-19-陈守亮-新增续租添加押金
        tipAmount = tipAmount || 0;
        //金额转换，并赋值展示
        that.formPublish.amount = ParseFloatFormat(displayAmount);
        that.formPublish.tip = ParseFloatFormat(tipAmount);
        that.loading = false;
      });
    },
    enterpriseClick(response){
      // kitchenPic: [],//厨房照片数组
      //     drawingPic: [],//客厅照片数组
      //     restaurantPic: [],//餐厅照片数组
      //     bedroomPic: [],//卧室照片数组  bedroomPic
      //     otherPic: [],//其他照片数组
      console.log(response)
      const uuid = response.file.response.returnObject.info.uuid;
      let isType = response.isType
      if(isType === '厨房') {
        const hasElement = this.kitchenPic.includes(uuid);
        if(!hasElement)   this.kitchenPic.push(uuid)
      }
      if(isType === '客厅') {
        // response.fileList.forEach(item => {
        //   let itemUuid =   item.response.returnObject.info.uuid
        //   const hasElement = this.drawingPic.includes(itemUuid);
        //   if(!hasElement)   this.drawingPic.push(itemUuid)
        // })
        const hasElement = this.drawingPic.includes(uuid);
        if(!hasElement)   this.drawingPic.push(uuid)
        console.log(this.drawingPic)
      }
      if(isType === '餐厅') {
        const hasElement = this.restaurantPic.includes(uuid);
        if(!hasElement)   this.restaurantPic.push(uuid)
      }
      if(isType === '卧室') {
        // alert('卧室')
        // const hasElement = this.bedroomPic.includes(uuid);
        // if(!hasElement)   this.bedroomPic.push(uuid)
        console.log(response)

        this.bedroomPic.push( response.info.uuid)
        // response.fileList.forEach(item => {
        //   // alert('卧室')
        //   let itemUuid =   item.response.info.uuid
        //   alert(itemUuid)
        //   const hasElement = this.bedroomPic.includes(itemUuid);
        //   alert(hasElement)
        //  this.bedroomPic.push(itemUuid)
        // })
      }
      if(isType === '其他') {
        this.otherPic.push( response.info.uuid)
        // response.fileList.forEach(item => {
        //   let itemUuid =   item.response.info.uuid
        //   const hasElement = this.otherPic.includes(itemUuid);
        //   if(!hasElement)   this.otherPic.push(itemUuid)
        // })
      }
      // if(isType === '紧急联系人身份证国徽面') {
      //   const hasElement = this.contactReverseIdcardList.includes(uuid);
      //   if(!hasElement)   this.contactReverseIdcardList.push(uuid)
      // }
    },
    enterpriseRemove(response){
      // kitchenPic: [],//厨房照片数组
      //     drawingPic: [],//客厅照片数组
      //     restaurantPic: [],//餐厅照片数组
      //     bedroomPic: [],//卧室照片数组
      //     otherPic: [],//其他照片数组
      console.log(response)
      let {fileList,isType} = response
      console.log(fileList)
      let list = []
      if(fileList) {
        fileList.forEach(item => {
          list.push(item.response.returnObject.info.uuid)
        })
      }
      if(isType === '厨房') this.kitchenPic = []
      if(isType === '卧室') this.bedroomPic = list
      if(isType === '客厅') this.drawingPic = []
      if(isType === '餐厅') this.restaurantPic = []
      if(isType === '其他') this.otherPic = list
    },
  },
  watch: {
    'formPublish.monthSize'(value) {
      if (value && value !== 0 && this.dialogVisible) this.setEndDate(value);
    },
  }
}
</script>

<style scoped>

</style>
