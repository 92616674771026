<template>
    <section class="log-management padding_10_15">
        <div class="top-bar bg-white">
            <el-form ref="searchForm" :model="formSearch" inline size="small" :rules="{}">
                <el-form-item>
                    <el-input v-model="formSearch.keyword" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
                <el-button @click="handleReset" size="small">重置</el-button>
                </el-form-item>
            </el-form>

        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLogs" :columns="tableColumn" :query="formSearch" :height="830">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

    </section>
</template>

<script>
    import { tableColumn } from "./data";
    import { privacyAgreementList } from "@/api/log";
    export default {
        name: "operation-log",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {
                    operateModule: null,
                    operateType: null,
                },
                detailShow: false,
                detailData: {}
            };
        },
        components:{
        },
        mounted() {

        },
        methods: {
            getLogs(params) {
                return privacyAgreementList(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            xiangQing(data) {
                this.detailData = {...data};
                this.detailShow = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .log-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>