<template>
    <r-e-dialog title="选择优惠券" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh" :show-footer="false"
                  width="500px">
        <div style="height: 500px;overflow:scroll;">
         <div style="color:#000;padding:10px;font-weight:bold;font-size:16px">可用券({{beUseCount}}张)</div>
            <div v-for="(item, index) in beUseList" :key="index" class="flex justify-between flex-warp" 
             style="background-color:#fff;border-radius: 10px; padding: 15px 10px;align-items: center;margin-bottom:10px">
                <div style="color: #567722;">
                    <div style="margin-bottom:10px;font-size:18px;">￥{{item.amount /100 }}</div>
                    <div style="text-align:center">{{item.useRule}}</div>
                </div>
                <div style="width:200px">
                    <div style="color:#000">{{item.couponName}}</div>
                    <div style="font-size:12px;text-align:center">有效期：{{item.date}}</div>
                </div>
                <div style="color: #FCCA00;font-size: 18px;">
                  {{ item.statusName}} 
                  <el-checkbox 
                    :label="item.uuid" 
                    v-model="couponUuids">
                    <span></span> 
                  </el-checkbox>
                </div>
            </div>
            <div style="color:#000;padding:10px;font-weight:bold;font-size:16px">不可用券({{lapseCount}}张)</div>
            <div v-for="(item, index) in lapseList" :key="index" class="flex justify-between flex-warp" 
             style="background-color:#fff;border-radius: 10px; padding: 15px 10px;align-items: center;margin-bottom:10px">
                <div style="color: #000;">
                    <div style="margin-bottom:10px;font-size:18px;">￥{{item.amount /100 }}</div>
                    <div style="text-align:center">{{item.useRule}}</div>
                </div>
                <div style="width:200px">
                    <div style="color:#000">{{item.couponName}}</div>
                    <div style="font-size:12px;text-align:center">有效期：{{item.date}}</div>
                </div>
                <div style="color: #000;font-size: 18px;margin-right:30px">
                    {{ item.statusName}} 
                </div>
            </div>
        </div>
        <div   style="padding-left:10px;padding-bottom:10px">已选择{{ couponUuids.length }} 张，优惠金额共计：{{ totalInYuan  }} 元</div>
        <div v-if="active < 1" style="text-align:center;    background-color: #fff;padding: 5px 0px;">
            <el-button slot="footer" @click="clickCancel" size="small">取消</el-button>
            <el-button  slot="footer" type="primary" size="small" @click="clickSubmit">确定</el-button>
        </div>
    </r-e-dialog>
</template>

<script>
    import {createFullImageUrl} from "@/components/Upload/index"
    import {getShopContractPage,contractAudit} from "@/api/shops-contract";
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    import {getLeasorMineAPI} from "@/api/house-resource";
    export default {
        name: "layer-shenhe",
        data() {
            return {
                dialogVisible: false,
                imgList: [],
                lookDialogVisible: false,
                dialogImageUrl: '',
                formPublish: {
                    uuid: null
                },
                loadingOptions: {
                    lock: true,
                    text: "请您稍后...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                beUseCount:'',
                beUseList:[],
                lapseCount:'',
                lapseList:[],
        //         beUseList: [
        //     { uuid: 'uuid1', amount: 1500000, useRule: '满100减20', couponName: '优惠券1', date: '2024-12-31', statusName: '可用' },
        //     { uuid: 'uuid2', amount: 2000000, useRule: '满200减50', couponName: '优惠券2', date: '2024-12-31', statusName: '可用' },
        //     // 更多优惠券数据...
        // ],
        selectedUuid: '',  // 存储当前选中的uuid
        // couponUuids: []    // 存储所有选中的uuid
            }
        },
        props: {
            couponUuids: {
      type: Array,
      default: () => []  // 如果父组件没有传递 couponUuids，默认值为空数组
    },
    active:Number,
  },
  mounted() {
    // 打印父组件传递的 couponUuids 数据
    console.log('从父组件传递的 couponUuids:', this.couponUuids);
    console.log('从父组件传递的 active 状态:', this.active);
  },
        methods: {
           async openDialog(data) {
                console.log("datasss",data);
                // console.log('this.couponUuids)',this.couponUuids);
                const param = {};

                // 如果 data 中有 leasorUuid，则使用 leasorUuid，否则使用 uuid
                if (data.leasorUuid) {
                    param.leasorUuid = data.leasorUuid;
                } else if (data.uuid) {
                    param.contractUuid = data.uuid;
                }
                // let {uuid} = data;
                // this.formPublish.uuid=uuid;
                // let {contractPictures} = data;
                // this.imgList = contractPictures !== null && contractPictures !== "" ? contractPictures.split(";").filter(item => item !== "") : [];
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                this.dialogVisible = true;
                await getLeasorMineAPI(param).then(res => {
                    console.log('res',res);
                    const { info:{beUseCount,beUseList,lapseCount,lapseList}} = res;
                    console.log('beUseCount,beUseList,lapseCount,lapseList',beUseCount,beUseList,lapseCount,lapseList);
                    this.beUseCount = beUseCount;
                    this.lapseCount = lapseCount;
                    this.beUseList = beUseList;
                    this.lapseList = lapseList;
                }).finally(() => loading.close());
            },
            createFullImageUrl(uuid) {
                return createFullImageUrl(uuid)
            },
            look(url) {
                this.lookDialogVisible = true;
                this.dialogImageUrl = url;
            },
            clickSubmit(){
                this.clickCancel()
                this.$emit('confirm', {
                couponAmount: this.totalInCents,
                couponUuids: this.couponUuids
            });
            },
            clickCancel(){
                this.dialogVisible = false;
                this.$emit("handleSearch");
                this.imgList = [];
                this.formPublish = {uuid: null};
            },
        },
        computed: {
            totalInCents() {
                // 过滤出已选中的优惠券
                const selectedCoupons = this.beUseList.filter(coupon => this.couponUuids.includes(coupon.uuid));
                console.log('选中的优惠券',selectedCoupons);

                // 累加选中优惠券的金额（以分为单位）
                return selectedCoupons.reduce((total, coupon) => total + coupon.amount, 0);
            },
            totalInYuan() {
                // 获取以分为单位的总金额，并转换为元
                return this.totalInCents / 100;
            }
        }
    }
</script>

<style scoped>
    /deep/  .el-dialog__body{
    background-color: #f5f6f6;
    padding:0px;
}

</style>