<template>
  <r-e-dialog title="水电充值" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="400px"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

      <el-form-item label="类型" prop="type">
        <el-select style="width: 200px" v-model="ruleForm.type" placeholder="请选择充值类型">
          <el-option label="水费充值" value="水费充值"></el-option>
          <el-option label="电费充值" value="电费充值"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <el-input style="width: 200px" v-model="ruleForm.amount" placeholder="请输入充值金额" ></el-input>
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMethod" >
        <el-select style="width: 200px"  v-model="ruleForm.paymentMethod" placeholder="请选择支付方式">
          <el-option label="现金" value="现金"></el-option>
          <el-option label="转账" value="转账"></el-option>
          <el-option label="刷卡" value="刷卡"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付时间" prop="name">
        <el-input style="width: 200px" v-model="ruleForm.cancelTime" :disabled="true" ></el-input>
      </el-form-item>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {czhiputApi} from "@/api/house-resource";
export default {
  name: "dialog-save-tenant",
  data(){
    return {
      dialogVisible: false,
      tenantData:{},
      ruleForm: {
        type: '',
        amount: '',
        paymentMethod: '',
        apartmentUuid:'',
        cancelTime: new Date().toLocaleString()
      },
      // .toLocaleString()
      rules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { min: 1, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择充值类型', trigger: 'change' }
        ],
        paymentMethod: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        signedDate: [
          { required: true, message: '请获取时间', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {

  },
  methods:{
    clickSubmit(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
          //调用接口保存
          console.log(this.tenantData)
          let uuid  = this.uuid
          let max = ''
          // let reg1 = new RegExp(".","g"); // 加'g'，删除字符串里所有的"a"
          // console.log(this.ruleForm)
          // this.ruleForm.cancelTime = this.ruleForm.cancelTime.replaceAll('/', '-')
          let jieliufa = this.ruleForm.amount.indexOf('.')
          console.log(jieliufa)
          if(jieliufa !== -1){
            max = this.ruleForm.amount.split(".")[1]
            console.log(max,max.length)
          }
          let myamount = ''
          if(max.length === 1){
            myamount = this.ruleForm.amount.split(".").join("")
            myamount = `${myamount}0`
          }else if(max.length === 2){
            myamount = this.ruleForm.amount.split(".").join("")
            myamount = `${myamount}`
          }else {
            myamount = `${this.ruleForm.amount}00`
          }
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          console.log(formattedDate);
          this.ruleForm.cancelTime = formattedDate
          console.log(this.ruleForm)
          czhiputApi(uuid,{...this.ruleForm,amount:myamount}).then(res => {
            console.log(res)
            loading.close();

            this.dialogVisible = false
            max = ''
            this.ruleForm = {
              type: '',
              amount: '',
              paymentMethod: '',
            }

            this.$message.success(res.msg);
            // this.$parent.getDeposit()
            this.$emit('getDeposit');
            this.$bus.$emit('showMsg',)
          }).catch(err => {
            loading.close();
            this.$message.error('请求失败请联系管理员！');
          })
        } else {
          this.$message.error('请完善表单相关信息！');
          return false;
        }
      })
    },
    clickCancel(){
      // alert(2)
      this.dialogVisible = false

    },

  },

}
</script>

<style scoped>

</style>