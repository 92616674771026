<template>
    <r-e-dialog ref="orderTableRef" title="查看" :footerBtnName="footerBtnName" class="layer-contract-detail" :visible.sync="dialogVisible" :showFooter="showFooter" fullscreen
                @close="clickCancel"  @click-submit="clickSubmit" @click-cancel="clickCancel">
        <div style="height: 79vh;">
            <el-steps :active="4" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                <el-step title="申请-管家申请">
                    <div slot="description">
                        <div>申请人: {{ submitter }}</div>
                        <div>申请时间: {{ submitDate }}</div>
                    </div>
                </el-step>
                <el-step title="片管员预审">
                    <div slot="description">
                        <div>确认人: {{ personChargeUserName }}</div>
                        <div>确认时间: {{ personChargeDate }}</div>
                    </div>
                </el-step>
                <el-step title="负责人审核">
                    <div slot="description">
                        <div>确认人: {{ confirmUser }}</div>
                        <div>确认时间: {{ confirmDate }}</div>
                    </div>
                </el-step>
                <!--                    v-if="checkOurData.totalAmount < 0"-->
                <el-step title="财务部审核">
                    <div slot="description">
                        <div>处理人: {{ handleUser }}</div>
                        <div>处理时间: {{ handleDate }}</div>
                    </div>
                </el-step>
                <el-step title="查看"></el-step>
            </el-steps>
            <div style="height: 90%;width: 100%;">
                <div style="height: 100%;display: flex;flex-flow: column wrap;">
                    <div class="padding_0_30 boder-right width33b">
                        <div class="title">房源地址</div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>{{ checkOurData.aprtmentName }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="title">租户信息</div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>{{ checkOurData.leasor }}</div>
                        </div>
                    </div>
                    <div class="flex padding_0_30 boder-right width33b">
                        <div style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;">
                            <span>退房时间 </span>
                            <span>{{ checkOurData.checkOutDate }}</span>
                        </div>
                    </div>

                    <div class="flex boder-right width33b padding_0_30" style="margin-top: 5px"
                         v-if="checkOurData.reasonCode===1">
                        <div
                            style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;margin-right: 25px">
                            <span>优惠金额 </span>
                            <span> {{ numberFormat(checkOurData.gzfTicket.amount || 0) }}</span>
                        </div>
                        <div @click="clickCoupon"
                            style="cursor:pointer;background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;margin-right: 25px;display: flex;align-items: center;">
                            <i class="el-icon-s-ticket" style="font-size:20px; color: #409EFF;margin-right:5px;"></i><div style="margin-right:10px"> 优惠券 </div>
                            <div style="color:#d22424;"> 已选({{ selectedCouponCount }}张)优惠券金额{{checkOurData.couponAmount / 100}}元></div>
                        </div>
                    </div>

                    <div class="padding_10_30 fw600 boder-right width33b">应退费用</div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-center width300">费用周期</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <div class="text-left width100">
                                    <el-badge :hidden="checkOurData.depositCount<=1" :value="checkOurData.depositCount">
                                        <div style="width: 35px;">
                                            <el-link type="primary" @click="goBill('押金',checkOurData.depositUuids)">押金
                                            </el-link>
                                        </div>
                                    </el-badge>
                                </div>
                            </div>
                            <div class="text-center width300">{{ checkOurData.depositDate }}</div>
                            <div class="text-right width100">{{ checkOurData.depositAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.rentalCount<=1" :value="checkOurData.rentalCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应退房租',checkOurData.rentalUuids)">
                                            房租
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.rentalDate }}</div>
                            <div class="text-right width100">{{ checkOurData.rentalAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.serviceCount<=1" :value="checkOurData.serviceCount">
                                    <div style="width: 50px;">
                                        <el-link type="primary" @click="goBill('应退服务费',checkOurData.serviceUuids)">
                                            服务费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.serviceDate }}</div>
                            <div class="text-right width100">{{ checkOurData.serviceAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">房租余额</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.rentalBalance }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">杂费余额</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.expenseBalance }}</div>
                        </div>
                    </div>
                  <div class="padding_0_30 boder-right width33b">
                    <div class="flex justify-between row bg-F8F8F8">
                      <div class="text-left width100">押金余额</div>
                      <div class="text-center width300">----</div>
                      <div class="text-right width100">{{ checkOurData.depoTemporaryBalance }}</div>
                    </div>
                  </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>
                                应退租金额（元）
                                <span style="margin-left: 20px" class="fw600">{{ checkOurData.refundAmopunt }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="title">退租原因</div>
                        <el-button :type="checkOurData.reasonCode===1?'primary':''"
                                   style="font-size: 14px; padding: 8px 15px;">
                            到 期
                        </el-button>
                        <el-button :type="checkOurData.reasonCode===2?'primary':''"
                                   style="font-size: 14px; padding: 8px 15px;">
                            违 约
                        </el-button>
                    </div>

                    <div class="padding_10_30 fw600 boder-right width33b">应扣费用</div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-center width300">费用周期</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidRentalCount<=1"
                                          :value="checkOurData.noPaidRentalCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣房租',checkOurData.noPaidRentalUuids)">
                                            房租
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidRentalDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidRentalAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidServiceCount<=1"
                                          :value="checkOurData.noPaidServiceCount">
                                    <div style="width: 50px;">
                                        <el-link type="primary"
                                                 @click="goBill('应扣服务费',checkOurData.noPaidServiceUuids)">
                                            服务费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidServiceDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidServiceAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidWaterCount<=1"
                                          :value="checkOurData.noPaidWaterCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣水费',checkOurData.noPaidWaterUuids)">
                                            水费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidWaterDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidWaterAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidElectCount<=1"
                                          :value="checkOurData.noPaidElectCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣电费',checkOurData.noPaidElectUuids)">
                                            电费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidElectDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidElectAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">清洁费</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.clean }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">家具损坏费</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.damage }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">其他</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.other }}</div>
                        </div>
                    </div>

                    <div v-if="checkOurData.reasonCode===2" class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row">
                            <div class="text-left width100">违约金</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">{{ checkOurData.dedit }}</div>
                        </div>
                    </div>

                    <div class="padding_0_30 boder-right width33b">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>
                                扣除租客金额（元）
                                <span style="margin-left: 20px" class="fw600">{{ checkOurData.payAmount }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex align-baseline padding_10_30 fw600 width33b">
                        <div style="width: 50px;">总计:</div>
                        <div>
                            <div style="font-size: 24px;">{{ checkOurData.totalAmount }}</div>
                            <div v-if="checkOurData.totalAmount<0" style="font-size: 12px;color:#FF6740;">
                                退还给租客{{ -checkOurData.totalAmount }}
                            </div>
                            <div v-else style="font-size: 12px;color:#FF6740;">向租客收取{{ checkOurData.totalAmount }}</div>
                        </div>
                    </div>

                    <div class="padding_10_30 width33b">
                        <div style="margin-bottom: 10px" class="fw600">图片</div>
                        <div style="padding-left: 10px;width: 100%;display: flex;flex-wrap: wrap" >
                            <div v-for="(item,index) in imgList" :key="index">
                                <div class="flex justify-center"
                                     style="background: #f5f7fa;width: 98px;height: 98px;border: 1px solid #F1F1F3;border-radius: 6px;margin: 0 5px;padding: 5px;">
                                    <el-image :src="createFullImageUrl(item)" class="flex align-center"
                                              :preview-src-list="imgList.map((item => createFullImageUrl(item)))">
                                        <div slot="error" style="font-size: 30px;">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                        <div slot="placeholder">加载中...</div>
                                    </el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div style="width: 33%;padding: 10px 30px">
                    <div style="font-weight: 600;margin-bottom: 10px">上传视频</div>
                    <div style="padding-left: 20px;" >
                      <upload-video disabledtype="true"  accept=".mp4" :deletetype="false"  :uuid="checkOurData.videoUrl"/>
                    </div>
                  </div>
                    <div v-if="checkOurData.totalAmount < 0" style="width: 33%;padding: 10px 30px;">
                        <div class="title">退还</div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px"><span style="color: red;margin-right: 4px">*</span>汇款人 :</div>
                            <div>
                                <el-input v-model="checkOurData.remitter" disabled size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px"><span style="color: red;">*</span>银行卡号:</div>
                            <div>
                                <el-input v-model="checkOurData.cardNum" disabled size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px"><span style="color: red;">*</span>开户行:</div>
                            <div>
                                <el-input v-model="checkOurData.bank" disabled size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkOurData.totalAmount > 0" style="width: 33%;padding: 10px 30px;">
                        <div class="title">收取</div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px">收取方式 :</div>
                            <div>
                                <el-select v-model="checkOurData.paymentMethod" size="mini" disabled
                                           placeholder="请选择付款方式">
                                    <el-option v-for="(item,index) in paymentTypeList" :key="index"
                                               :label="item.codeName" :value="item.code"/>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="padding_10_30 width33b">
                        <div style="padding-bottom: 10px" class="fw600">备注</div>
                        <el-input type="textarea" v-model="checkOurData.comment" :rows="3" style="width: 100%;"
                                  placeholder="请输入内容" disabled/>
                    </div>
                    <div class="width33b padding_10_30">
                        <el-badge :value="checkOutDisposeLog.length">
                            <el-button type="primary" @click="handleRecordSwitch=true">处理记录</el-button>
                        </el-badge>
                    </div>
                </div>
            </div>
        </div>
        <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px"
                    :showFooter="false" @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            </r-e-table>
        </r-e-dialog>

        <r-e-dialog title="处理记录" :visible.sync="handleRecordSwitch" show-footer top="5vh" width="1000px"
                    :showFooter="false" @click-cancel="handleRecordSwitch=false" v-dialog-drag>
            <r-e-table ref="tableHandleRecordRef" :columns="handleRecordColumns" :data="checkOutDisposeLog" :height="500" border
                       :showPagination="false"/>
        </r-e-dialog>

        <dialog-coupon ref="dialogCouponRef"  @confirm="handleConfirm"  :active="active"  :couponUuids="couponUuids" ></dialog-coupon>
    </r-e-dialog>
</template>

<script>
import {MessageInfo,MessageSuccess} from "@custom/message";
import {timeFormat, numberFormat} from "@custom/index";
import {getWordInfo,getWordInfoApi} from "@/api/work-order-management";
import {createFullImageUrl} from "@/components/Upload/index";
import {getPaybillUuidsList} from "@/api/bill";
import {houseBillColumns} from "@/views/rental-management/house-resource/data";
import uploadVideo from "@/components/Upload/video-upload-perfect.vue";

export default {
    name: "dialog-check-out-work-order-view",
    data() {
        return {
            showFooter:false,
            footerBtnName:['取消','撤回'],
            dialogVisible: false,
            checkOurData: {
                contractUuid: null,
                aprtmentName: '0',
                leasor: '0',
                checkOutDate: null,
                depositAmount: null,
                depositDate: null,
                rentalDate: null,
                rentalAmount: null,
                serviceDate: null,
                serviceAmount: null,
                rentalBalance: null,
                expenseBalance: null,
                depoTemporaryBalance:null,
                noPaidRentalDate: null,
                noPaidRentalAmount: null,
                noPaidServiceDate: null,
                noPaidServiceAmount: null,
                noPaidWaterDate: null,
                noPaidWaterAmount: null,
                noPaidElectDate: null,
                noPaidElectAmount: null,
                clean: '0.00',
                damage: '0.00',
                other: '0.00',
                dedit: '0.00',
                payAmount: 0,
                refundAmopunt: 0,
                totalAmount: 0,
                reasonCode: 1,
                reasonName: '到期',
                remitter: null,
                bank: null,
                cardNum: null,
                comment: null,
                paymentMethod: null,
                gzfTicket: {}
            },
            paymentTypeList: [],
            imgList: [],
            submitter: null,    //申请人
            submitDate: null,   //申请时间
            personChargeUserName: null, //片管员
            personChargeDate: null, //片管员操作时间
            confirmUser: null,  //租赁部
            confirmDate: null,  //租赁部操作时间
            handleUser: null,   //财务部
            handleDate: null,   //财务部操作时间
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            billTitle: "",
            billSwitch: false,
            houseBillColumns: houseBillColumns(this),
            billList: [],
            billUuids: null,
            checkOutDisposeLog: [],
            handleRecordSwitch: false,
            handleRecordColumns: [
                {
                    prop: "name",
                    label: "处理人",
                    width: 100
                },
                {
                    prop: "operatingState",
                    label: "操作",
                    width: 100
                },
                {
                    prop: "remark",
                    label: "驳回原因",
                },
                {
                    prop: "controlsDate",
                    label: "时间",
                    width: 150
                },
            ],
            selectedCouponCount:0,//// 已选优惠券数量
        };
    },
    components: {uploadVideo,
        dialogCoupon: () => import("./dialog-coupon"),},
    methods: {
      clickSubmit() {
        const loading = this.$loading({
          lock: true,
          text: "正在撤回请您稍等...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        getWordInfoApi({uuid:this.uuid }).then(res => {
          console.log(res)
          loading.close();
          MessageSuccess('撤回成功')
          this.clickCancel()
        }).catch(err => {
          loading.close();
          console.log(err)})
      },
        async openDialog(data,showFooter = false) {
            const {uuid, submitter, createDate,contractUuid} = data;
            this.contractUuid = contractUuid
            this.submitter = submitter;
            this.showFooter = showFooter
            this.submitDate = timeFormat(new Date(createDate), "yyyy-MM-dd HH:mm:ss");
            this.uuid = uuid;
            await this.getWordInfo();
            this.dialogVisible = true;
        },
        async getWordInfo() {
            const that = this;
            let uuid = this.uuid;
            const loading = this.$loading({
                lock: true,
                text: "请您稍后正在打开...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let {info} = await getWordInfo(uuid).catch(() => loading.close());
            let {
                depositAmount, depositDate, rentalDate, rentalAmount, serviceDate, serviceAmount, rentalBalance,
                expenseBalance, noPaidRentalDate, noPaidRentalAmount, noPaidServiceDate, noPaidServiceAmount,
                noPaidWaterDate, noPaidWaterAmount, noPaidElectDate, noPaidElectAmount, aprtmentName, leasor,
                workPictures, comment, contractUuid, bank, cardNum, remitter, paymentMethod, checkOutDate,
                reasonCode, reasonName, clean, damage, other, dedit, totalAmount, refundAmopunt, payAmount,
                noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                serviceUuids, depositCount, depositUuids, gzfTicket, submitUserName, submitDate = null,
                disposeUserName, disposeDate = null, remittanceUserName, remittanceDate = null,
                personChargeUserName = null, personChargeDate = null, checkOutDisposeLog = [],videoUrl,depoTemporaryBalance,
                couponUuids,couponAmount
            } = info;
            gzfTicket = gzfTicket || {},
            this.couponUuids =couponUuids;
            if(couponUuids)this.selectedCouponCount = couponUuids.length;
            // this.submitter = submitUserName;
            // this.submitDate = submitDate ? timeFormat(new Date(submitDate), "yyyy-MM-dd HH:mm:ss") : '';
            if (checkOutDisposeLog.length > 0) {
                checkOutDisposeLog = checkOutDisposeLog.map(item => {
                    let {controlsDate} = item;
                    controlsDate = controlsDate ? timeFormat(new Date(controlsDate), "yyyy-MM-dd HH:mm:ss") : null;
                    return {...item, controlsDate}
                });
            }
            that.checkOutDisposeLog = checkOutDisposeLog;
            that.personChargeUserName = personChargeUserName ? personChargeUserName : '';
            that.personChargeDate = personChargeDate ? timeFormat(new Date(personChargeDate), "yyyy-MM-dd HH:mm:ss") : '';
            this.confirmUser = disposeUserName;
            this.confirmDate = disposeDate ? timeFormat(new Date(disposeDate), "yyyy-MM-dd HH:mm:ss") : '';
            this.handleUser = remittanceUserName;
            this.handleDate = remittanceDate ? timeFormat(new Date(remittanceDate), "yyyy-MM-dd HH:mm:ss") : '';
            depositAmount = parseFloat((depositAmount / 100)).toFixed(2);
            rentalAmount = parseFloat(rentalAmount / 100).toFixed(2);
            serviceAmount = parseFloat(serviceAmount / 100).toFixed(2);
            rentalBalance = parseFloat(rentalBalance / 100).toFixed(2);
            expenseBalance = parseFloat(expenseBalance / 100).toFixed(2);
            depoTemporaryBalance = parseFloat(depoTemporaryBalance / 100).toFixed(2);
            noPaidRentalAmount = parseFloat(noPaidRentalAmount / 100).toFixed(2);
            noPaidServiceAmount = parseFloat(noPaidServiceAmount / 100).toFixed(2);
            noPaidWaterAmount = parseFloat(noPaidWaterAmount / 100).toFixed(2);
            noPaidElectAmount = parseFloat(noPaidElectAmount / 100).toFixed(2);
            clean = parseFloat(clean / 100).toFixed(2);
            damage = parseFloat(damage / 100).toFixed(2);
            other = parseFloat(other / 100).toFixed(2);
            dedit = parseFloat(dedit / 100).toFixed(2);
            totalAmount = parseFloat(totalAmount / 100).toFixed(2);
            refundAmopunt = parseFloat(refundAmopunt / 100).toFixed(2);
            payAmount = parseFloat(payAmount / 100).toFixed(2);
            checkOutDate = timeFormat(new Date(checkOutDate));
            let oldCheckOurData = this.checkOurData;
            this.checkOurData = {
                ...oldCheckOurData, depositAmount, depositDate, rentalDate, rentalAmount, serviceDate, serviceAmount,
                rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount, noPaidServiceDate,
                noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate, noPaidElectAmount,
                aprtmentName, leasor, comment, contractUuid, bank, cardNum, remitter, paymentMethod, checkOutDate,
                reasonCode, reasonName, clean, damage, other, dedit, totalAmount, refundAmopunt, payAmount,
                noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                serviceUuids, depositCount, depositUuids, gzfTicket,videoUrl,depoTemporaryBalance,couponAmount
            }
            this.imgList = workPictures ? workPictures.split(";").filter(item => item !== "") : [];
            loading.close();
        },
        clickCancel() {
          // this.dialogVisible = false;
        // alert('6')
            // 1. 获取当前页数
            // const currentPage = this.$refs.orderTableRef.currentPage;
            // console.log('currentPage',currentPage);
            this.$emit('handleSearch');
            this.dialogVisible = false;
            this.checkOurData = {};
        },
        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },
        async goBill(type, data) {
            if (data) {
                let that = this;
                that.billTitle = type;
                that.billUuids = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                getPaybillUuidsList({uuids: data}).then(res => {
                    const {list} = res;
                    that.billList = list;
                    that.billSwitch = true;
                }).finally(() => loading.close());
            } else MessageInfo('无' + type + '账单');
        },
        billListClose() {
            this.billTitle = null;
            this.billUuids = null;
            this.billList = null;
            this.billSwitch = false;
        },

        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });
            return sums;
        },
        numberFormat(number) {
            return numberFormat(Number(number) / 100);
        },
        clickCoupon(){
            console.log('this.contractUuid',this.contractUuid);
            this.$refs["dialogCouponRef"].openDialog({ uuid: this.contractUuid, leasorUuid: this.leasorUuid });
        },
    },
    async created() {
        this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
        this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102 && item.code !== 500106);
    },
}
</script>

<style scoped>
.row {
    margin-bottom: 10px;
    padding: 10px 20px;
    border: 1px solid #D7D7D7;
}

.width300 {
    width: 300px;
}

.width100 {
    width: 100px;
}

.bg-F8F8F8 {
    background-color: #F8F8F8;
}

.bg-E6E6E6 {
    background-color: #E6E6E6;
}

.bg-E2F5FF {
    background-color: #E2F5FF;
}

.width33b {
    width: 33%;
}

.padding_0_30 {
    padding: 0 30px;
}

.padding_10_30 {
    padding: 10px 30px;
}

.fw600 {
    font-weight: 600;
}

.boder-right {
    border-right: 1px solid #D7D7D7;
}

.title {
    border-left: 5px #F5A623 solid;
    padding-left: 5px;
    margin: 15px 0;
    font-weight: 600;
}
</style>
