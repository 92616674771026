<template>
  <r-e-dialog title="余额充值" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="470px"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

      <el-form-item label="类型" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择充值类型" style="width: 280px">
          <el-option label="房租充值" value="房租充值"></el-option>
          <el-option label="其他费用充值" value="其他费用充值"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <el-input style="width: 280px" v-model="ruleForm.amount" placeholder="请输入充值金额" :disabled="ruleForm.paymentMethod === '优惠券'"></el-input>
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMethod" >
        <el-select  v-model="ruleForm.paymentMethod" placeholder="请选择支付方式" style="width: 280px">
          <el-option label="现金" value="现金"></el-option>
          <el-option label="转账" value="转账"></el-option>
          <el-option label="刷卡" value="刷卡"></el-option>
          <el-option label="其他" value="其他"></el-option>
          <el-option label="优惠券" value="优惠券"></el-option>
        </el-select>
      </el-form-item>
      <div  class="flex"  @click="clickCoupon"   v-if="ruleForm.paymentMethod === '优惠券'"
                style="margin-bottom: 10px;border-radius: 5px;color:#333333;margin-left: 100px;display: flex;align-items: center;">
                            <i class="el-icon-s-ticket" style="font-size:20px; color: #409EFF;margin-right:5px;"></i><div style="margin-right:10px"> 优惠券 </div>
                            <div style="color:#d22424;">  可用优惠券({{couponCount}}张)，已选优惠券({{ selectedCouponCount }}张) ></div>
            </div>
      <el-form-item label="支付时间" prop="name" >
        <el-input style="width: 280px" v-model="ruleForm.cancelTime" :disabled="true" ></el-input>
      </el-form-item>
      <dialog-coupon ref="dialogCouponRef"  @confirm="handleConfirm" :active="0"></dialog-coupon>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {czhiputApi} from "@/api/house-resource";
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
export default {
  name: "dialog-save-tenant",
  data(){
    return {
      dialogVisible: false,
      tenantData:{},
      ruleForm: {
        type: '',
        amount: '',
        paymentMethod: '',
        cancelTime: new Date().toLocaleString()
      },
      selectedCouponCount:0,//// 已选优惠券数量
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      // .toLocaleString()
      rules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择充值类型', trigger: 'change' }
        ],
        paymentMethod: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        signedDate: [
          { required: true, message: '请获取时间', trigger: 'blur' }
        ]
      }
    };
  },
  props: {
    couponCount: {
      type: Number,
      required: true // 确保父组件传递了这个值
    }
  },
  components: {
    dialogCoupon: () => import("@/views/rental-management/work-order-management/components/dialog-coupon"),
  },
  mounted() {

  },
  created(){
    console.log('this.couponCountaaoao',this.couponCount)
    console.log('this.selectedCouponCount = 0;',this.selectedCouponCount );
    this.selectedCouponCount= 0;
  },
  methods:{
    // 点击优惠券
    clickCoupon(){
      console.log('console.log(this.tenantData)',this.tenantData)
      console.log('dataaaaaaleasorUuid',this.tenantData.uuid);
      this.$refs["dialogCouponRef"].openDialog({ leasorUuid: this.tenantData.uuid, });
    },
    clickSubmit(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //调用接口保存
          console.log(this.tenantData)
          let uuid  = this.tenantData.uuid
          // let max = ''
          // let reg1 = new RegExp(".","g"); // 加'g'，删除字符串里所有的"a"
          // console.log(this.ruleForm)
          // this.ruleForm.cancelTime = this.ruleForm.cancelTime.replaceAll('/', '-')
          let amountStr = String(this.ruleForm.amount);  // 转换为字符串
          let max = '';
          let myamount = '';

          // 查找小数点的位置
          let jieliufa = amountStr.indexOf('.');  
          console.log(jieliufa);

          // 如果存在小数点
          if (jieliufa !== -1) {
            // 提取小数部分
            max = amountStr.split(".")[1];
            console.log(max, max.length);

            // 根据小数部分的长度处理
            if (max.length === 1) {
              myamount = amountStr.replace(".", "") + "0";  // 例如：1.2 -> 120
            } else if (max.length === 2) {
              myamount = amountStr.replace(".", "");  // 例如：1.23 -> 123
            }
          } else {
            // 如果没有小数点，补上两位零
            myamount = amountStr + "00";
          }

          console.log('1',myamount);  // 输出优化后的 myamount
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          console.log(formattedDate);
          this.ruleForm.cancelTime = formattedDate
          console.log(this.ruleForm)
          const loadingOptions = this.loadingOptions;
          const loading = this.$loading({...loadingOptions});
          czhiputApi(uuid,{ ...this.ruleForm,amount:myamount, useCouponUuids:this.couponUuids}).then(res => {
            loading.close()
            console.log(res)
            this.dialogVisible = false
            max = ''
            this.ruleForm = {
              type: '',
              amount: '',
              paymentMethod: '',
            }

            this.$message.success(res.msg);
            // this.$parent.getDeposit()
            this.$emit('getButtontoput');
          }).catch(err => {
            loading.close()
            this.$message.error('请求失败请联系管理员！');
          })
        } else {
          this.$message.error('请完善表单相关信息！');
          return false;
        }
      })
    },
    handleConfirm(payload) {
      console.log('that.unpaidAmount',this.unpaidAmount);
      // 当子组件发射 `confirm` 事件时，接收到数据并进行处理
      console.log('父组件接收到的总金额:', payload.couponAmount);
      console.log('父组件接收到的优惠券 UUID:', payload.couponUuids);
      this.couponUuids = payload.couponUuids;
      // 更新已选优惠券的数量
      if(payload.couponUuids) this.selectedCouponCount = payload.couponUuids.length;
      // console.log('amount',this.formPublish.amount);
      this.ruleForm.amount = payload.couponAmount/ 100

    },
    clickCancel(){
      // alert(2)
      this.dialogVisible = false
      this.selectedCouponCount = 0;
      this.ruleForm = {
              type: '',
              amount: '',
              paymentMethod: '',
      }
      
    },

  },

}
</script>

<style scoped>

</style>