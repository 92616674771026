<template>
  <r-e-dialog title="上传附件" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <el-form ref="formPublish" label-width="90px" size="small">
      <div class="flex">
        <el-form-item label="房源信息">
          <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）"
                    style="width: 220px;"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="文件类型">
          <el-radio-group v-model="fileType">
            <el-radio-button :label="'pdf'">pdf文件</el-radio-button>
            <el-radio-button :label="'img'">图片</el-radio-button>
            <el-radio-button :label="'MP4'">视频</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="flex" v-if="fileType==='img'">
        <el-form-item label="照片附件" class="samplePhotos">
          <upload-pictureCard :uuidList="imgList" :limit="24" @on-success="handleSuccess"
                              @on-remove="handleRemove"/>
        </el-form-item>
      </div>
      <div class="flex" v-if="fileType==='pdf'">
        <el-form-item label="pdf附件" class="samplePhotos">
          <el-upload :action="uploadInterfaceIp" :headers="headersToken" :on-remove="handlePDFRemove"
                     :on-success="handlePDFSuccess" :limit="5" :before-upload="handleBeforeUpload"
                     :on-exceed="handlePDFExceed" :on-preview="onPreview" accept=".pdf"
                     :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>
          </el-upload>
        </el-form-item>
      </div>
      <!--        <div style="padding-left: 20px;" >-->
      <!--          <upload-video disabledtype="true"  accept=".mp4" :deletetype="false"  :uuid="checkOurData.videoUrl"/>-->
      <!--        </div>-->
      <div v-if="fileType==='MP4'">
        <div style="padding-left: 20px;" >
          <upload-video ref="uploadvideo"  accept=".mp4" :disabledtype="disabledtype" :deletetype="deletetype" @delete-video="deleteUploadedVideo" @on-success="handleUploadSuccess" :uuid="videoUrl"/>
        </div>
        <el-button v-if="videoUrl" size="mini" style="margin-left: 20px" @click="clonevideo">删除视频</el-button>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
import uploadPictureCard from "@/components/Upload/upload-picture-card";
import {baseConfig} from "@/utils/config";
import {tokenCookies} from "@storage/cookies";
import {photos} from "@/api/contract";
import {createFullImageUrl} from "@/components/Upload/index";
import uploadVideo from "@/components/Upload/video-upload-perfect.vue";
import el from "element-ui/src/locale/lang/el";

export default {
  name: "dialog-save-contract-file",
  components: {uploadVideo, uploadPictureCard},
  data() {
    return {
      videoUrl:'',
      videoUrltype:false,
      disabledtype:false,
      deletetype:false,
      dialogVisible: false,
      fileType: '',
      fileList: [],
      uploadInterfaceIp: baseConfig.baseURL + "/files/upload",
      headersToken: {
        "X-Access-Token": tokenCookies.get(),
      },
      filePDFList: [],
      imgList: [],
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
    }
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    },
    contractInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    clonevideo(){
      this.$refs.uploadvideo.deleteVideo()
      this.videoUrl = ''; // 清空视频 UUID
    },
    deleteUploadedVideo(){
      this.videoUrl = ''; // 清空视频 UUID
    },
    // 上传视频事件
    handleUploadSuccess({info}) {
      // 上传视频
      this.videoUrl = info.uuid;
      this.checkOurData.videoUrl = info.uuid;
    },
    //打开附件上传弹框方法
    openDialog(data) {
      console.log(data)
      //解构图片文件，PDF文件
      let {contractPhotos, pdfUrl,contractStatusCodeName,idType,videoUuids} = data;
      if(contractStatusCodeName === '待完善' && idType === '法人') this.videoUrltype = true
      //拆分头部，将头部剔除
      let imgList = contractPhotos !== null && contractPhotos !== "" ? contractPhotos.split("+-+").filter(item => item !== "") : [];
      let filePDFList = pdfUrl !== null && pdfUrl !== "" ? pdfUrl.split("+-+").filter(item => item !== "") : [];

      //将文件数组字符串，拆分出文件字符串数组
      this.imgList = imgList.length === 2 ? imgList[1].split(";") : [];
      this.filePDFList = filePDFList.length === 2 ? filePDFList[1].split(";") : [];
      this.videoUrl = videoUuids
      //循环PDF文件数组给PDF文件重命名
      for (let index = 0; index < this.filePDFList.length; index++) {
        this.fileList.push({
          name: `合同附件${index + 1}.pdf`, url: createFullImageUrl(this.filePDFList[index]),
          uuid: this.filePDFList[index]
        });
      }

      //打开附件上传弹框
      this.dialogVisible = true;
    },

    //图片上传成功事件
    handleSuccess({fileList}) {
      //获取全部上传成功图片uuid
      this.imgList = fileList.map(item => {
        let {name, response} = item;
        let resOk = response && typeof (response) !== undefined;
        if (resOk) {
          let {returnObject: {info: {uuid}}} = response;
          return uuid;
        } else {
          return name;
        }
      });
    },

    //图片删除成功回调
    handleRemove({fileList}) {
      //获取全部剩余图片uuid
      this.imgList = fileList.map(item => {
        let {name, response} = item;
        let resOk = response && typeof (response) !== undefined;
        if (resOk) {
          let {returnObject: {info: {uuid}}} = response;
          return uuid;
        } else {
          return name;
        }
      });
    },

    //PDF文件上传提交提醒
    handlePDFExceed() {
      MessageWarning(`PDF文件总共只能上传5个，请先删除已上传的文件！`);
    },

    //点击取消按钮事件
    clickCancel() {
      this.$emit("getContractDetailData", this.contractInfo.uuid);
      this.dialogVisible = false;
      this.fileType = '';
      this.fileList = [];
      this.filePDFList = [];
      this.imgList = [];
    },

    //点击确认按钮事件
    clickSubmit() {
      let that = this;
      //获取文件上传类型
      let fileType = this.fileType;
      //校验有没有选择文件上传类型
      if (fileType === "") {
        MessageWarning(`请选择上传文件类型`);
        return;
      }
      //获取文件uuid数组
      let filePDFList = that.filePDFList;
      let imgList = this.imgList;
      //判断数组长度是否为0，为零表示没有上传
      if (imgList.length === 0 && filePDFList.length === 0 && this.videoUrl === '') {
        MessageWarning(`请上传文件`);
        return;
      }
      // 校验视频
      // if(this.fileType === 'MP4'){
      //   if(this.videoUrl === '') {
      //     MessageWarning('请上传视频');
      //     loading.close();
      //     return;
      //   }
      // }
      //解构出合同uuid
      const {uuid} = that.contractInfo;
      //拼接图片文件头部，并将图片uuid数组拼接成图片uuid字符串
      const contractPhotos = "IMG+-+" + imgList.join(";");
      //拼接PDF文件头部，并将PDF文件uuid数组拼接成PDF文件uuid字符串
      const pdfUrl = "PDF+-+" + filePDFList.join(";");
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading(loadingOptions);

    if(this.videoUrltype){
      this.$confirm('是否结束合同待完善?', '警告', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        //调用接口上传合同附件
        photos({uuid, contractPhotos, pdfUrl, isRefine: true,videoUuids: this.videoUrl}).then(res => {
          loading.close();
          MessageSuccess('附件上传成功');
          that.clickCancel();
        }).catch(err => {
          MessageError('附件上传失败');
          loading.close();
        });
      }).catch(() => {
        photos({uuid, contractPhotos, pdfUrl, isRefine: false,videoUuids: this.videoUrl}).then(res => {
          loading.close();
          MessageSuccess('附件上传成功');
          that.clickCancel();
        }).catch(err => {
          MessageError('附件上传失败');
          loading.close();
        });
      });
    }else {
      photos({uuid, contractPhotos, pdfUrl, isRefine: false,videoUuids: this.videoUrl}).then(res => {
        loading.close();
        MessageSuccess('附件上传成功');
        that.clickCancel();
      }).catch(err => {
        MessageError('附件上传失败');
        loading.close();
      });
    }


    },

    //PDF文件上传前拦截事件
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const {name} = file;
        // 上传文件格式
        const acceptArray = ".pdf";
        const extensionIndex = name.lastIndexOf(".");       // 后缀序列
        const extension = name.substring(extensionIndex);   // 文件后缀
        this.fileName = name.substring(0, extensionIndex);  // 文件名称
        let isFileType = acceptArray.includes(extension);
        if (!isFileType) {
          MessageWarning(`请上传pdf格式的文件！`);
          reject(false);
        }
        resolve(isFileType);
      });
    },

    //移除PDF文件事件
    handlePDFRemove(file, fileList) {
      this.filePDFList = fileList.map(item => item.uuid);
      this.fileList = fileList;
    },

    //PDF文件上传成功事件
    handlePDFSuccess(response, file, fileList) {
      let {returnObject: {info: {uuid}}} = response;
      this.filePDFList.push(uuid);
      this.fileList.push({name: file.name, url: createFullImageUrl(uuid), uuid: uuid});
    },

    //预览PDF文件事件
    onPreview(file) {
      let tempwindow = window.open("", '_blank');
      tempwindow.location = file.url;
    }
  }
}
</script>

<style scoped>

</style>
