<template>
    <r-e-dialog :title="operationType==='add'?'添加人员':'修改人员'" class="layer-contract-detail" show-footer
                :visible.sync="addDialogVisible" top="10vh" width="400px" @click-submit="clickSubmit"
                @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="personnelData" :rules="rules">
            <div class="flex">
                <el-form-item label="登陆名" prop="username">
                    <el-input type="text" :disabled="operationType==='update'" v-model="personnelData.username" placeholder="请输入登录名"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="密码" prop="password">
                    <el-input type="text" v-model="personnelData.password" placeholder="请输入密码"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="姓名" prop="fullname">
                    <el-input type="text" v-model="personnelData.fullname" placeholder="请输入姓名"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号" prop="phone">
                    <el-input type="text" v-model="personnelData.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="邮箱" prop="">
                    <el-input type="text" v-model="personnelData.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
            </div>
        </el-form>

    </r-e-dialog>
</template>

<script>
    import { createUser,updateUser } from "@/api/personnel-management";
    import { MessageSuccess,MessageError } from "@custom/message";
    export default {
        name: "dialog-add-personnel",
        data(){
            return {
                addDialogVisible: false,
                operationType: "",
                personnelData: {
                    roles: ['GZF_VIEWER',"GZF_OPS"]
                },
                rules:{
                    username: [ { required: true, message: '登陆名不能为空', trigger: 'blur'} ],
                    phone: [ { required: true, message: '电话不能为空', trigger: 'blur'} ],
                    fullname: [ { required: true, message: '姓名不能为空', trigger: 'blur'}],
                    password: [ { required: true, message: '密码不能为空', trigger: 'blur'},],
                },
            };
        },
        methods:{
            openDialog(type,data){
                this.operationType = type;
                if (type === "add") this.personnelData = {roles: ['GZF_VIEWER', "GZF_OPS"]};
                else {
                    let {username, password, fullname, email, phone, roles} = data;
                    if (roles && roles.length !== 0) roles = roles.map(item => item.name);
                    this.personnelData = {username, password, fullname, email, phone, roles};
                }
                this.addDialogVisible = true;
            },
            clickSubmit(){
                let that=this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {username, password, fullname, email, phone, roles} = that.personnelData;
                        let paramData = {username, password, fullname, email, phone, roles};
                        if (that.operationType === "add") {
                            //2022/03/31 王江毅 调用接口添加人员
                            createUser(paramData).then(res => {
                                MessageSuccess('添加人员成功');
                                that.clickCancel();
                                loading.close();
                            }).catch(err => {
                                loading.close();
                                MessageError('添加人员失败');
                            });
                        } else {
                            //2022/03/31 王江毅 调用接口更新人员
                            updateUser(paramData).then(res => {
                                MessageSuccess('更新人员成功');
                                that.clickCancel();
                                loading.close();
                            }).catch(err => {
                                loading.close();
                                MessageError('更新人员失败');
                            });
                        }
                    }
                });
            },
            clickCancel(){
                //2022/03/21 调用父组件handleSearch方法 刷新
                this.$emit('handleSearch');
                this.addDialogVisible=false;
                this.personnelData={ roles: [ 'GZF_VIEWER',"GZF_OPS" ] };
            }
        }
    }
</script>

<style scoped>

</style>