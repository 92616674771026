import {timeFormat,numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    /*{
        prop: "businessModule",
        label: "操作模块",
    },*/
    {
        prop: "wechatOpenId",
        label: "用户"
    },
    {
        prop: "appletPhone",
        label: "授权手机号"
    },
    {
        prop: "operation",
        label: "操作"
    },
    {
        prop: "operationTime",
        label: "操作时间",
        render: (h, {row}) => {
            const operationTime = ! row.operationTime ? "" : timeFormat(new Date(row.operationTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", operationTime);
        }
    },
];

