<template>
    <section class="contract">

        <div v-if="zUuid || ziList.length === 0">
            <div style="text-align: right">
                <!--2022/03/21 王江毅 右上角 添加杂费账单-->
                <el-link type="primary" :underline="false" v-if="!isSign" @click="openDialog">添加租房合同</el-link>

            </div>
            <div class="table-title">租房合同记录</div>
            <r-e-table ref="communityTableRef" :dataRequest="getContractTableData" :columns="houseContractColumns"
                       :height="700">
                <el-table-column slot="toolbar" label="操作">
                    <template slot-scope="{ row, row: { contractStatusCode: code } }">
                        <div class="table-tools">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                  操作<i class="el-icon-arrow-down el-icon--right"/>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="handleClickDetail(row)">
                                        <span class="table-btn">查看详情</span>
                                    </el-dropdown-item>
                                    <!-- 2023/08/22 张晓瑜新增按钮 -->
                                    <el-dropdown-item  v-if="code !== 500705"
                                                    @click.native="contractFiling(row)">
                                        <span class="table-btn">合同备案</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="lookBills(row)">
                                        <span class="table-btn">查看账单</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500709" @click.native="perfectTheContract(row)">
                                        <span class="table-btn">完善合同</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500701 || code === 500702 || code === 500704"
                                                      @click.native="xuqian(row)">
                                        <span class="table-btn">续租</span>
                                    </el-dropdown-item>
<!--                                  // 2024-3-11-陈守亮-隐藏换房-->
                                    <el-dropdown-item v-if="code === 500701 && isbzfcode" @click.native="huanFang(row)">
                                        <span class="table-btn">换房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500701 || code === 500702"
                                                      @click.native="tuifang(row)">
                                        <span class="table-btn">退房</span>
                                    </el-dropdown-item>
<!--                                  <el-dropdown-item-->
<!--                                                    @click.native="tuifang(row)">-->
<!--                                    <span class="table-btn">退房</span>-->
<!--                                  </el-dropdown-item>-->
                                    <el-dropdown-item
                                        v-if="getButtonMenu('CANCELLATION') && code === 500701 ||code === 500704 ||code === 500706 ||code === 500709"
                                        @click.native="zuofei(row)">
                                        <span class="table-btn">作废</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item  v-if="code !== 500705"
                                                     @click.native="collection(row)">
                                        <span class="table-btn">催收</span>
                                    </el-dropdown-item>
                                    <!-- 2024/01/15 张晓瑜修改恢复按钮条件判断1 -->
<!--                                  <el-dropdown-item   v-if="code === 500703 || code === 500705 "  @click.native="recoverclick(row)">-->
<!--                                    <span class="table-btn">恢复</span>-->
<!--                                  </el-dropdown-item>-->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <div v-else>
            <div style="text-align: right">

                <!--2022/03/21 王江毅 右上角 添加杂费账单-->
                <!--                <el-link type="primary" :underline="false" v-if="!isSign" @click="openDialog">添加租房合同</el-link>-->
            </div>
            <div class="table-title">租房合同记录</div>
            <r-e-table ref="communityTableRef" :dataRequest="getSonAllList" :columns="houseContractBzfColumns"
                       :height="700">
                <el-table-column slot="toolbar" label="操作">
                    <template slot-scope="{ row, row: { contractStatusCode: code } }">
                        <div class="table-tools">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                  操作<i class="el-icon-arrow-down el-icon--right"/>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="handleClickDetail(row)">
                                        <span class="table-btn">查看详情</span>
                                    </el-dropdown-item>
                                    <!-- 2023/08/22 张晓瑜新增按钮 -->
                                    <el-dropdown-item v-if="code !== 500705"
                                                    @click.native="contractFiling(row)">
                                        <span class="table-btn">合同备案</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="lookBills(row)">
                                        <span class="table-btn">查看账单</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500709" @click.native="perfectTheContract(row)">
                                        <span class="table-btn">完善合同</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 || code === 500702 || code === 500704"
                                        @click.native="xuqian(row)">
                                        <span class="table-btn">续租</span>
                                    </el-dropdown-item>
<!--                                  // 2024-3-11-陈守亮-隐藏换房-->
                                    <el-dropdown-item v-if="code === 500701  && isbzfcode" @click.native="huanFang(row)">
                                        <span class="table-btn">换房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 || code === 500702"
                                        @click.native="tuifang(row)">
                                        <span class="table-btn">退房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="getButtonMenu('CANCELLATION') && code === 500701 || code === 500704 || code === 500706 || code === 500709 "
                                        @click.native="zuofei(row)">
                                        <span class="table-btn">作废</span>
                                    </el-dropdown-item>
                                    <!-- 2024/05/08 张晓瑜添加已作废的合同，隐藏催收和合同备案按钮  -->
                                    <el-dropdown-item  v-if="code !== 500705"
                                                     @click.native="collection(row)">
                                        <span class="table-btn">催收</span>
                                    </el-dropdown-item>

                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <r-e-dialog :title="dialogTitle" class="layer-contract-detail" :visible.sync="dialogVisible"
                    :show-footer="false" top="10vh">
            <r-e-descriptions :column="2" :schemes="houseLesseeSchemas" :data="contractInfo"
                              :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block',}">
                <div class="title" slot="title">承租人信息</div>
            </r-e-descriptions>

            <r-e-descriptions :column="2" :schemes="houseContractSchemas" :data="contractInfo"
                              :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block', }"
            >
                <div class="title" slot="title">合同信息</div>
            </r-e-descriptions>
            <!-- 身份证弹框 -->
            <dilalogImg ref="imgref"/>
          <dilalogImgList  ref="imgrefList"></dilalogImgList>
        </r-e-dialog>

        <!-- 2022/04/25 王江毅 添加合同dialog -->
        <dialog-add-contract ref="dialogAddContract" :currentHouse="currentHouse" @handleSearch="handleSearch"
                             :zUuid="zUuid"/>
        <!-- 2022/04/25 王江毅 添加合同附件dialog -->
        <dialog-save-contract-file ref="dialogSaveContractFile" :currentHouse="currentHouse"
                                   :contractInfo="contractInfo"
                                   @getContractDetailData="getContractDetailData"/>
        <!-- 2022/04/25 王江毅 查看合同附件dialog -->
        <dialog-look-contract-file ref="dialogLookContractFile"/>
        <!-- 2022/04/25 王江毅 退房dialog -->
        <dialog-check-out ref="dialogCheckOut" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/04/25 王江毅 续租dialog -->
        <dialog-contract-xuqian ref="dialogContractXuqian" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <dialog-contract-qingnian ref="dialogContractQingnian" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/05/09 王江毅 换房dialog -->
        <dialog-exchange-houses ref="dialogExchangeHouses" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/05/09 王江毅 同住人dialog -->
        <dialog-tongzhuren-list ref="dialogTongzhurenList" @getContractDetailData="getContractDetailData"/>
        <!-- 2022/05/16 查看账单dialog -->
        <dialog-look-zhangdan ref="dialogLookZhangdan"/>
        <!-- 2022/08/22 张晓瑜 合同备案dialog  -->
        <dialog-contract-filing ref="dialogContractFiling"/>
        <!-- 2024-1-11-陈守亮-合同恢复按钮-->
        <dialogRecover ref="dialogRecoverref"></dialogRecover>
        <!-- 2022/05/09 王江毅 合同备注dialog -->
        <r-e-dialog title="合同备注" class="layer-contract-detail" :visible.sync="dialogVisibleComment" show-footer
                    top="10vh" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="65px" size="small" :model="formPublish" :rules="rules">
                <el-form-item label="备注" prop="comment">
                    <el-input v-model="formPublish.comment" type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"
                              style="width: 95%" placeholder="请填写合同备注"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <el-dialog title="催收" :visible.sync="collectionDialogVisible" width="30%" :before-close="collectionClickCancel">
            <span>
                <el-radio-group v-model="collectionData.type">
                    <el-radio :label="0">小程序通知</el-radio>
                    <el-radio :label="1">短信通知</el-radio>
                </el-radio-group>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="collectionClickCancel">取 消</el-button>
                <el-button type="primary" @click="collectionClickSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 合同作废通知弹框    -->
      <el-dialog title="注意" :visible.sync="cancellationVisible" width="30%" :before-close="cancellationVisibleCancel">
        <div style="display: flex;margin-bottom: 12px">
          <div style="border-radius: 50%;background-color:Gold;text-align: center;width: 15px;height: 15px;color: White;font-weight: bold;padding-left: 3px;line-height: 15px">！</div><span style="margin-left: 12px">此操作将会作废合同，是否继续？</span>
        </div>
        <el-form :inline="true" :model="formcomment" class="demo-form-inline" ref="formNamecance">
          <el-form-item label="作废原因"  :rules="[
      { required: true, message: '请输入作废原因', trigger: 'blur' },
    ]">
            <el-input type="textarea" style="width: 420px;" :rows="3"  v-model="formcomment.formInlinecomment"></el-input>
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="cancellationVisibleCancel">取 消</el-button>
                <el-button type="primary" @click="cancellationVisibleSubmit">确 定</el-button>
            </span>
      </el-dialog>
    </section>
</template>

<script>
import * as houseData from "@/views/rental-management/house-resource/data";
import * as houseApi from "@/api/house-resource";
import {
  nullify,
  editComment,
  getApartmentData,
  contractSign,
  contractIsSign,
  getSonAllList,
  bzfInvalid,
  bzfContractInvalid,
  getAssetsverifyApi,
  getContractListByLeasor,
  getEqbContractUrlApi,
  getContracTimeApiworitr,
  getContracTimeApiwneedMeterElect
} from "@/api/contract";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {messageSend} from "@/api/message";
import {isbzf, isApartmentType, getHousecontpdf} from "@/api/house-resource";
import {buttonCookies} from "@storage/cookies";
import {downloadByData} from "@/utils/hooks/download";

export default {
    name: "contract",
    data() {
        return {
          formcomment:{
            formInlinecomment:null,
            uuid:null
          },
           cancellationVisible:false,
           isbzfcode:'',
            houseContractColumns: houseData.houseContractColumns(this),
            houseContractBzfColumns: houseData.houseContractBzfColumns(this),
            houseLesseeSchemas: houseData.houseLesseeSchemas(this),
            houseContractSchemas: houseData.houseContractSchemas(this),
            houseContractSchemas2: houseData.houseContractSchemas2(this),
            houseLesseeSchemas2: houseData.houseLesseeSchemas2(this),
            contractInfo: {},
            dialogVisible: false,
            dialogTitle: "租房合同详情",
            paymentTypeList: [],
            formPublish: {
                uuid: "",
                comment: "",
            },
            rules: {
                comment: [{required: true, message: "请输入备注", trigger: "blur"}],
            },
            dialogVisibleComment: false,
            loadingOptions: {
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },
            collectionDialogVisible: false,
            collectionData: {
                uuid: null,
                type: 0,
            },

            isSign: true,

            isbzf: false, //是否是保租房 true是，false不是
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({}),
        },
        zUuid: {
            type: String,
            default: null,
        },
        ziList: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        dialogAddContract: () => import("./components/dialog-add-contract"),
        dialogSaveContractFile: () => import("./components/dialog-save-contract-file"),
        dialogLookContractFile: () => import("./components/dialog-look-contract-file"),
        dialogCheckOut: () => import("./components/dialog-check-out"),
        dialogContractXuqian: () => import("./components/dialog-contract-xuqian"),
        dialogContractQingnian:()=>import("./components//dialog-contract-qingnian"),
        dialogExchangeHouses: () => import("./components/dialog-exchange-houses"),
        dialogTongzhurenList: () => import("./components/dialog-tongzhuren-list"),
        dialogLookZhangdan: () => import("./components/dialog-look-zhangdan"),
        dialogContractFiling: () => import("./components/dialog-contract-filing"),
        dilalogImg: () => import("./components/dilalog-img"),
        dilalogImgList: () => import("./components/dilalog-img-list"),
       dialogRecover: () => import("./components/dialog-recover.vue"),
    },
    async created() {
        const {apartmentUuid} = this.currentHouse;
        let uuid = this.zUuid || apartmentUuid;
        this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
        this.paymentTypeList = this.paymentTypeList.filter((item) => item.code !== 500101 && item.code !== 500102 && item.code !== 500106 && item.code !== 500107);
        let {info} = await contractIsSign(uuid);
        this.isSign = info;
        const s1 = await isbzf(uuid);
        this.isbzf = !s1.info;
        this.isbzfcode = s1.info
      // console.log(this.isbzfcode)
    },
  // mounted(){
  // //  2024-1-23-陈守亮-新增更新切换窗口状态
  //   let self = this
  //   document.addEventListener("visibilitychange", function() {
  //     if (document.visibilityState == "hidden") {
  //       //切离该页面时执行
  //     } else if (document.visibilityState == "visible") {
  //       //切换到该页面时执行
  //       self.handleSearch()
  //     }
  //   });
  // },

    methods: {
         // getImage(){
         //   alert('99')
         // },
        // 获取房源所有合同(无子房间)
        getContractTableData(params) {
            // 获取房源uuid
            const {apartmentUuid} = this.currentHouse;
            params.uuid = this.zUuid || apartmentUuid;
            return houseApi.getHouseContractApi(params);
        },
        // 获取房源所有合同(有子房间)
        getSonAllList(params) {
            // 获取房源uuid
            const {apartmentUuid} = this.currentHouse;
            params.uuid = apartmentUuid;
            return getSonAllList(params);
        },
        // 获取合同详情
        async getContractDetailData(uuid) {
            try {
                // 获取合同详情
                const {info} = await houseApi.getHouseContractDetailApi(uuid);
              console.log(info)
                this.contractInfo = info;
              if(this.contractInfo.electContractTypeCode  > 19){
                // 新增区分企业合个人详情
                console.log(this.houseLesseeSchemas)
                this.houseLesseeSchemas = this.houseLesseeSchemas2
                this.houseContractSchemas = this.houseContractSchemas2
              }
            } catch (e) {
                console.error("获取合同详情", e);
            }
        },

        //打开合同详情弹框
        handleClickDetail(row) {
            this.$newWindowstype = row.type
            // 打开详情弹窗
            this.dialogVisible = true;
            this.dialogTitle = `${row.leasorName}租房合同详情`;
            this.getContractDetailData(row.uuid);
        },

        //刷新合同列表
        handleSearch() {
            this.$refs["communityTableRef"].getTableData();
        },
      // 作废提示取消
      cancellationVisibleCancel(){
        let that = this;
        that.cancellationVisible = false
      },
      // 作废提示确定
      cancellationVisibleSubmit(){
        let that = this;
        if(that.formcomment.formInlinecomment === null || that.formcomment.formInlinecomment === '') {
          that.$message({
            message: '请输入作废原因',
            type: 'warning'
          });
          return false;
        }

        // const loading = that.$loading({
        //   lock: true,
        //   text: '合同作废中',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'
        // });

        // nullify({comment:that.formcomment.formInlinecomment,uuid:that.formcomment.uuid}).then(res => {
        //
        //   if(res.msg === '作废成功') {
        //     loading.close();
        //     that.$message({
        //       message: '作废成功',
        //       type: 'success'
        //     });
        //   }
        //   that.formcomment.formInlinecomment = null
          that.moneytransfer()
        // })

        that.cancellationVisible = false
      },
      moneytransfer(){
        let that = this;
        let getContractListByLeasorinfo = false
        getContractListByLeasor({uuid:that.formcomment.uuid}).then(res => {
          //接口判断是否纯在已缴费，存在是true，不存在是false
          if(res.info){
            getContractListByLeasorinfo = true
            that.$confirm('<span style="font-size: 14px">存在已缴费账单，是否将已缴费账单金额转移至余额?</span>'+ '\n<span style="color: red;font-size: 12px;">点击取消已缴费账单将会作废，如取消操作请点击右上角取消</span>', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              distinguishCancelAndClose:true,
              dangerouslyUseHTMLString: true,
              type: 'warning'
            }).then(() => {
              // 作废并且转移
              const loading = that.$loading({
                lock: true,
                text: '合同作废中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              // 确定转移
              nullify({uuid:that.formcomment.uuid,refundMoney:true,comment:that.formcomment.formInlinecomment,}).then(res => {
                console.log(res)
                if (that.isbzf) {
                  //作废诺诺电子合同
                  bzfContractInvalid(that.formcomment.uuid);
                }
                that.$message({
                  message: '转移成功',
                  type: 'success'
                });
                loading.close();
                that.handleSearch();
              }).catch(err => {
                loading.close();
              })

            }).catch((err) => {
              if(err === 'close'){
                // 点叉取消不走后面的
                return
              }
              console.log(err)
              // 作废并且不转移
              // alert('不转移')
              const loading = that.$loading({
                lock: true,
                text: '合同作废中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              // 不转移
              nullify({uuid:that.formcomment.uuid,refundMoney:false,comment:that.formcomment.formInlinecomment,}).then(res => {
                console.log(res)
                if (that.isbzf) {
                  //作废诺诺电子合同
                  bzfContractInvalid(that.formcomment.uuid);
                }
                loading.close();
                that.handleSearch();
              }).catch(err => {
                loading.close();
              })
            });
          }else {
            const loading = that.$loading({
              lock: true,
              text: '合同作废中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            // 不转移
            nullify({uuid:that.formcomment.uuid,refundMoney:false,comment:that.formcomment.formInlinecomment,}).then(res => {
              console.log(res)
              if (that.isbzf) {
                //作废诺诺电子合同
                bzfContractInvalid(that.formcomment.uuid);
              }
              loading.close();
              that.handleSearch();
            }).catch(err => {
              loading.close();
            })
            // loading.close();
            // that.handleSearch();
          }
        })
      },
        //合同作废
        zuofei(data) {
            let that = this;
          that.formcomment.formInlinecomment = null
          that.formcomment.uuid = data.uuid
          that.cancellationVisible = true

          // that.$alert('<div><span>作废原因：</span> <input type="text" id="textInput" name="textInput"></div>', '注意', {
          //   dangerouslyUseHTMLString: true,
          //   type: "warning",
          // }).then((value) => {
          //   console.log(value)
          // });
          // that.$prompt(' <h1>作废原因</h1><span>原因填写</span>', '提示', {
          //   confirmButtonText: '确定',
          //   dangerouslyUseHTMLString: true,
          //   type: "warning",
          //   title:'作废原因',
          //   cancelButtonText: '取消',
          //   inputPattern: /^.+$/,
          //   inputErrorMessage: '请输入作废原因',
          //   label:"活动区域"
          // }).then(({ value }) => {
          //   console.log(value)
          //   this.$message({
          //     type: 'success',
          //     message: '你的邮箱是: ' + value
          //   });
          // }).catch(() => {
          //   this.$message({
          //     type: 'info',
          //     message: '取消输入'
          //   });
          // });



            // that.$confirm("此操作将作废该合同, 是否继续?", "注意", {type: "warning"}).then(() => {
            //     const loadingOptions = that.loadingOptions;
            //     const loading = that.$loading({...loadingOptions});
            //     nullify(data).then((res) => {
            //         MessageSuccess("合同作废成功");
            //         if (that.isbzf) {
            //             //作废诺诺电子合同
            //             bzfContractInvalid(data.uuid);
            //         }
            //         that.handleSearch();
            //     }).finally(() => loading.close());
            // }).catch(() => {});
        },

        // 点击添加合同事件
        async openDialog() {
            const {apartmentUuid} = this.currentHouse;
            const uuid = this.zUuid || apartmentUuid;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            // 2023/09/18 张晓瑜新增查询房间是否有未核验资产
            const {info} = await getAssetsverifyApi({apartmentUuid: uuid}).finally(() => loading.close());
            if (info) {
                this.$confirm('该房源存在未核验资产, 请核验后在生成合同', '注意', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    //查询房间是否绑定房型
                    this.getApartmentList()
                }).catch(() => {
                });
            } else {
                //查询房间是否绑定房型
                this.getApartmentList()
            }
        },

        //查询房间是否绑定房型
       async getApartmentList() {
            const that = this;
            const {apartmentUuid, address} = that.currentHouse;
         console.log(
             that.currentHouse
         )
            const uuid = that.zUuid || apartmentUuid;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            // // 2024/07/03 张晓瑜修改根据不同房型展示不同 0 包租房 1青年公寓 2五象新区
             const ress = await isApartmentType(uuid);
            //查询房间是否绑定房型
            await   getApartmentData(uuid).then((res) => {
                //是保租房直接打开添加合同弹窗f合同备案
                if (ress.info === 0 || ress.info === 2 ) {
                  // if(ress.info === 0){
                    getContracTimeApiwneedMeterElect({uuid:uuid}).then((res) => {
                      console.log(res.info,'保租房水电')
                      //陈守亮-保租房提醒抄表，点击确定打开添加合同弹窗，点击取消，跳转智能管控
                      // if(res.info){
                      //   that.$confirm("添加租房合同前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
                      //     that.$refs["dialogAddContract"].openDialog(ress.info);
                      //   }).catch(() => {
                      //     this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                      //   });
                      // }else {
                      //   that.$refs["dialogAddContract"].openDialog(ress.info);
                      // }
                      // alert(res.info)
                      if(res.info){
                        this.$alert('当前电表抄表失败，且当前租户无24小时内手动抄表记录，请手动抄表后进行签约！', '注意', {
                          confirmButtonText: '确定',
                          callback: action => {
                            this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                            // this.$message({
                            //   type: 'info',
                            //   message: `action: ${ action }`
                            // });
                          }
                        });
                        return
                      }else {
                        that.$refs["dialogAddContract"].openDialog(ress.info);
                      }
                      // that.$refs["dialogAddContract"].openDialog(ress.info);
                    })
                  // }

                  // if(ress.info === 2){
                  //   that.$refs["dialogAddContract"].openDialog(ress.info);
                  //   return
                  // }

                  // alert(ress.info)
                } else {
                  const loadingOptions = that.loadingOptions;
                  let loading = that.$loading({...loadingOptions});
                  getContracTimeApiwneedMeterElect({uuid:uuid}).then(res => {
                    console.log(res.info,'保租房水电')
                    loading.close()
                    if(res.info){
                      this.$alert('当前电表抄表失败，且当前租户无24小时内手动抄表记录，请手动抄表后进行签约！', '注意', {
                        confirmButtonText: '确定',
                        callback: action => {
                          this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                          // this.$message({
                          //   type: 'info',
                          //   message: `action: ${ action }`
                          // });
                        }
                      });
                      return
                    }else {
                      this.$refs["dialogAddContract"].openDialog();
                    }
                  })




                    //不是保租房提醒抄表，点击确定打开添加合同弹窗，点击取消，跳转智能管控
                    // that.$confirm("添加租房合同前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
                    //     this.$refs["dialogAddContract"].openDialog();
                    // }).catch(() => {
                    //     this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                    // });
                }
            }).finally(() => loading.close());
        },

        //打开添加合同附件弹窗
        saveFile(data) {
            this.$refs["dialogSaveContractFile"].openDialog(data);
        },

        //打开浏览合同附件弹窗
        lookFile(data) {
            this.$refs["dialogLookContractFile"].openDialog(data);
        },

        //点击退房事件
        tuifang(data) {
          const {address,apartmentUuid} = this.currentHouse;
          const uuid = this.zUuid || apartmentUuid;
          console.log(data,this.currentHouse,'退房')
            //查询房间是否是保租房
            //是保租房直接打开退房弹窗--陈守亮
              getContracTimeApiworitr({uuid:uuid}).then(res => {
                console.log(res)
                if(res.info){
                  this.$alert("退房前需要手动抄表以进行结算，请抄表后重新发起退房", "注意", {type: "warning"}).then(() => {
                    this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                  }).catch(() => {});
                }else {
                    this.$refs["dialogCheckOut"].openDialog(data);
                }
              })
        },

        //点击续租事件
      async  xuqian(data) {
        console.log(data)
        const {address,apartmentUuid} = this.currentHouse;
        const uuid = this.zUuid || apartmentUuid;
        const ress = await isApartmentType(uuid);
          if(ress.info === 0 ||ress.info === 2){
            // alert('不是青年公寓')
            await this.getContractDetailData(data.uuid)
            this.$refs["dialogContractXuqian"].openDialog(data,this.contractInfo);
          }else {

            getContracTimeApiwneedMeterElect({uuid}).then(async(res) => {
              if(!res.info){
                await this.getContractDetailData(data.uuid)
                this.$refs["dialogContractQingnian"].openDialog(data,this.contractInfo);
              }else {
                this.$alert('当前电表抄表失败，且当前租户无24小时内手动抄表记录，请手动抄表后进行操作！', '注意', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                  }
                })
              }
              console.log(res)
            })
            // alert('青年公寓')
          }
        },

        //点击换房事件
      async  huanFang(data) {
            const {address,apartmentUuid} = this.currentHouse;
          const uuid = this.zUuid || apartmentUuid;
            //查询房间是否是保租房
            //是保租房直接打开换房弹窗
        await  getContracTimeApiwneedMeterElect({uuid:uuid}).then(res => {
          if(res.info){
            this.$alert('当前电表抄表失败，且当前租户无24小时内手动抄表记录，请手动抄表后进行操作！', '注意', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                // this.$message({
                //   type: 'info',
                //   message: `action: ${ action }`
                // });
              }
            })

          }else {
            if (this.isbzf) {

              this.$refs["dialogExchangeHouses"].openDialog(data);
            } else {
              //不是保租房提醒抄表，点击确定打开换房弹窗，点击取消，跳转智能管控
              this.$refs["dialogExchangeHouses"].openDialog(data);
              // this.$confirm("此操作前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
              //     this.$refs["dialogExchangeHouses"].openDialog(data);
              // }).catch(() => {
              //     this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
              // });
            }
          }
          })
            // if (this.isbzf) {
            //
            //     this.$refs["dialogExchangeHouses"].openDialog(data);
            // } else {
            //     //不是保租房提醒抄表，点击确定打开换房弹窗，点击取消，跳转智能管控
            //   this.$refs["dialogExchangeHouses"].openDialog(data);
            //     // this.$confirm("此操作前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
            //     //     this.$refs["dialogExchangeHouses"].openDialog(data);
            //     // }).catch(() => {
            //     //     this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
            //     // });
            // }
        },

        //点击同住人事件
        tongzhuren(data, type) {
            //调用组件方法，打开同住人弹框
            this.$refs["dialogTongzhurenList"].openDialog(data, type);
        },

        //点击查看账单事件
        lookBills(data) {
            //调用组件方法，打开查看账单弹框
            this.$refs["dialogLookZhangdan"].openDialog(data);
        },

        // 张晓瑜新增合同备案
        contractFiling(data) {
            //调用组件方法，打开新增合同备案弹框
            this.$refs["dialogContractFiling"].openDialog(data);
        },

        //合同备注编辑
        editComment(data) {
            let {uuid, comment} = data;
            this.formPublish.uuid = uuid;
            this.formPublish.comment = comment;
            //打开合同备注弹框
            this.dialogVisibleComment = true;
        },

        //合同备注编辑提交
        clickSubmit() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            const {uuid, comment} = that.formPublish;
            editComment({uuid, comment}).then((res) => {
                MessageSuccess("合同备注编辑成功");
                that.getContractDetailData(uuid);
                that.clickCancel();
            }).finally(() => loading.close());
        },

        //合同备注编辑关闭
        clickCancel() {
            this.dialogVisibleComment = false;
            this.$refs["formPublish"].resetFields();
        },

        //点击催收事件
        collection({uuid}) {
            this.collectionData.uuid = uuid;
            //打开催收弹框
            this.collectionDialogVisible = true;
        },

        //催收确认
        collectionClickSubmit() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            let {uuid, type} = that.collectionData;
            messageSend(uuid, type).then((res) => {
                MessageSuccess("催收成功");
                that.collectionClickCancel();
            }).finally(() => loading.close());
        },

        //催收取消
        collectionClickCancel() {
            this.collectionData.uuid = null;
            this.collectionData.type = 0;
            this.collectionDialogVisible = false;
        },
        //点击完善合同事件
        perfectTheContract(data) {
          //electContractPlatform 0无 1E签宝 2诺诺 3本系统企业pdf
          let myuuid = data.uuid
          console.log(data)
          // if(data.electContractPlatform === 3 && data.electContractTypeCode === 20){
          //   const loadingOptions = this.loadingOptions;
          //   const loading = this.$loading({...loadingOptions});
          //   getHousecontpdf({uuid:data.uuid}).then(res => {
          //     // console.log(res)
          //     downloadByData({data: res, filename: `企业签约.pdf`,});
          //     loading.close()
          //   }).catch(() => {loading.close()})
          //   return
          // }
          if(data.electContractPlatform === 3){
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            getHousecontpdf({uuid:data.uuid}).then(res => {
              // console.log(res)
              downloadByData({data: res, filename: `企业签约.pdf`,});
              loading.close()
            }).catch(() => {loading.close()})
            return
          }
          if(data.electContractTypeCode  < 0){
            MessageWarning("暂无电子合同");
            return
          }
          if(data.electContractTypeCode  === 10 && data.electContractPlatform  === 2){
            //   诺诺保租房整租
            let paramData = {contractUuid: myuuid, uuid:myuuid};
            //保存数据到localStorage
            localStorage.setItem("paramData", JSON.stringify(paramData));
            //新建路由
            const newWindows = this.$router.resolve({path: "/test-canvas"});
            //新界面打开
            window.open(newWindows.href, "_blank");
              return
          }
          if(data.electContractTypeCode  === 11 && data.electContractPlatform  === 2) {
            //   诺诺保租房合租
            // contractSign(data.uuid).then(res => {
            //   let {info: {data},} = res;
              let paramData = {contractUuid: myuuid, uuid:myuuid};
              //保存数据到localStorage
              localStorage.setItem("paramData", JSON.stringify(paramData));
              //新建路由
              const newWindows = this.$router.resolve({path: "/test-canvas-son"});
              //新界面打开
              window.open(newWindows.href, "_blank");
            // })
              return
          }

            // 给contractUuid赋值uuid
            data.contractUuid = data.uuid;
            console.log('data',data)
            //保存数据到localStorage
            localStorage.setItem("paramData", JSON.stringify(data));
            //新建路由
            // type 0 为父房间  1为套房子房间 2为217、五项四号点
            // if(data.type === 0){
            //   const newWindows = this.$router.resolve({path: "/test-canvas"});
            //   console.log(newWindows)
            //   //新界面打开
            //   window.open(newWindows.href, "_blank");
            // }else if(data.type === 1) {
            //   const newWindows = this.$router.resolve({path: "/test-canvas-son"});
            //   console.log(newWindows)
            //   //新界面打开
            //   window.open(newWindows.href, "_blank");
            // }else if(data.type === 2){
            //   //解构出e签宝签署url
            //   const {url} = data;
            //   //新界面打开
            //   let tempwindow = window.open("", "_blank");
            //   //新界面更换地址
            //   tempwindow.location = url;
            // }
            // 2024/08/19 张晓瑜修改为合同都跳转为e签宝的
              const {url} = data;
              //新界面打开
              let tempwindow = window.open("", "_blank");
              //新界面更换地址
              tempwindow.location = url;

        },

        // base64字符串转pdf类型Bolb对象
        base64ToBlob(code) {
            //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
            code = code.replace(/[\n\r]/g, "");
            var raw = window.atob(code);
            let rawLength = raw.length;
            //转换成pdf.js能直接解析的Uint8Array类型
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: "application/pdf"}); //转成pdf类型
        },
      //
      getEQBFlowUrl(uuid){
        const that = this;
        const loadingOptions = that.loadingOptions;
        let loading = that.$loading({...loadingOptions});
        getEqbContractUrlApi({contractUuid: uuid, uuid}).then((res) => {
          console.log('res',res);
          let {msg} = res;
          if (msg) {
            let tempwindow = window.open("", '_blank');
            tempwindow.location = msg;
          }
        }).finally(() => loading.close());
      },

        //e签宝电子合同重新签署
        contractSign(uuid) {
            let that = this;
            this.$confirm("是否重新签署当前电子合同?", "注意", {type: "warning"}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                //调用接口
                contractSign(uuid).then((res) => {
                    let {info: {data},} = res;
                    if (data) {
                        //获取签署地址
                        let {url} = data;
                        //新界面打开
                        let tempwindow = window.open("", "_blank");
                        //新界面更换地址
                        tempwindow.location = url;

                      // if(that.$newWindowstype === 0){
                      //   // 给contractUuid赋值uuid
                      //   let paramData = {contractUuid: uuid, uuid};
                      //   //保存数据到localStorage
                      //   localStorage.setItem("paramData", JSON.stringify(paramData));
                      //   //新建路由
                      //   const newWindows = this.$router.resolve({path: "/test-canvas"});
                      //   //新界面打开
                      //   window.open(newWindows.href, "_blank");
                      //   //刷新合同详情
                      //   that.getContractDetailData(uuid);
                      // }else {
                      //   // 给contractUuid赋值uuid
                      //   let paramData = {contractUuid: uuid, uuid};
                      //   //保存数据到localStorage
                      //   localStorage.setItem("paramData", JSON.stringify(paramData));
                      //   //新建路由
                      //   const newWindows = this.$router.resolve({path: "/test-canvas-son"});
                      //   //新界面打开
                      //   window.open(newWindows.href, "_blank");
                      //   //刷新合同详情
                      //   that.getContractDetailData(uuid);
                      // }
                    }
                    MessageSuccess("生成合同成功");
                    //刷新合同详情
                    that.getContractDetailData(uuid);
                }).finally(() => loading.close());
            }).catch(() => {});
        },
      //e签宝电子合同重新签署-01
      contractSignTow(uuid,url) {
        let that = this;
        const loadingOptions = that.loadingOptions;
        const loading = that.$loading({...loadingOptions});
        let tempwindow = window.open("", "_blank");

        //新界面更换地址
        tempwindow.location = url;
        //调用接口
        that.getContractDetailData(uuid);
        loading.close()
      },
        //诺诺电子合同重新签署
        bzfInvalid(uuid,code) {
            let that = this;
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          //调用接口
          bzfInvalid(uuid).then((res) => {
            // 给contractUuid赋值uuid
            let paramData = {contractUuid: uuid, uuid};
            //保存数据到localStorage
            localStorage.setItem("paramData", JSON.stringify(paramData));
            //新建路由
            let newWindows  = ''
            if(code === 10)  newWindows = this.$router.resolve({path: "/test-canvas"});
            if(code === 11)  newWindows = this.$router.resolve({path: "/test-canvas-son"});
            //新界面打开
            window.open(newWindows.href, "_blank");
            //刷新合同详情
            that.getContractDetailData(uuid);
          }).finally(() => loading.close());

        },
        // 身份证展示
        immgshow(e) {
            this.$refs.imgref.dialogVisible = true;
            this.$refs.imgref.srcimg = e;
        },
      // 身份证展示
      immgshowpowerOfAttorney(e) {
        this.$refs.imgrefList.dialogVisible = true;
        this.$refs.imgrefList.srcimgList = e;
      },
      recoverclick(row){
          // alert('99')
        this.$refs.dialogRecoverref.recoverapiclick(row)
        this.$refs.dialogRecoverref.dialogVisible = true
      },
      // //获取按钮权限数组
      getButtonMenu(menu) {
        return JSON.parse(buttonCookies.get()).map(({url}) => url).some(v => v === menu);
      },
    },
    watch: {
        async zUuid(value) { // 子房间uuid变动时，查询当前房间有没有被出租
            const {apartmentUuid} = this.currentHouse;
            let uuid = this.zUuid || apartmentUuid;
            let {info} = await contractIsSign(uuid);
            this.isSign = info;
        },
    },
};
</script>

<style lang="scss" scoped>
.layer-contract-detail {
    .el-descriptions {
        margin-bottom: VH(30px);
    }

    .title {
        position: relative;
        padding-left: 10px;

        &::before {
            width: 5px;
            height: 100%;
            content: "";
            background-color: #f5a623;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    font-size: 12px;
}

.el-icon-arrow-down {
    font-size: 12px;
}
.el-textarea__inner{
  height: 300px;
}
</style>
