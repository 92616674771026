<template>
    <section>
        <!-- 2024/04/02 张晓瑜押金余额转移 -->
        <r-e-dialog title="押金余额转移" :visible.sync="dialogVisible" show-footer top="10vh" width="550px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="100px" size="small" :model="tenantData" :rules="rules">
                <div class="flex">
                    <el-form-item label="押金余额">
                        <el-input v-model="tenantData.depoTemporaryBalance" style="width: 365px;" disabled/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="转移金额">
                        <el-input v-model="tenantData.amount" style="width: 365px;" />
                    </el-form-item>
                </div>
                <!-- <div class="flex">
                    <el-form-item label="转移合同" prop="contractUuid">
                        <el-select v-model="formPublish.contractUuid" style="width: 365px;" placeholder="请选择付款方式">
                            <el-option v-for="(item,index) in contractList" :key="index" :label="item.contractNum"
                                       :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                </div> -->
                <div class="flex" style="margin-left: 30px;color: red">
                    注: 将押金余额转移至房租余额
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
import { updateDepositToContract} from "@/api/bill";
import {MessageWarning,MessageSuccess} from "@custom/message";
import {getDepositAmountapi,SaveDepositbalanceapi} from "@/api/tenant-management";

export default {
    name: "dialog-deposit",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                billUuid: null,
                contractUuid: null
            },
            rules: {
            // 自定义校验规则
                amount: [
                    { 
                        validator: (rule, value, callback) => {
                            // 获取押金金额
                            const depoTemporaryBalance = this.tenantData.depoTemporaryBalance;
                            // 转移金额不能大于押金金额
                            if (parseFloat(value) > parseFloat(depoTemporaryBalance)) {
                                callback(new Error('转移金额不能大于押金金额'));
                            } else {
                                callback();
                            }
                        }, 
                        trigger: 'blur' 
                    }
                ]
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            contractNum: null,
            contractList: [],
            tenantData: {
            depoTemporaryBalance: 0, // 押金金额
            amount: '' // 转移金额
        },
        };
    },
    components: {},
    methods: {
        openDialog(data){
                console.log('data11',data);
                if(data){
                    let {depoTemporaryBalance,uuid} = data;
                    this.tenantData = {depoTemporaryBalance,uuid}
                }
                this.dialogVisible=true;
            },
        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    // const {billUuid,contractUuid} = that.formPublish;
                    let {uuid, amount, } = that.tenantData;
                    amount *= 100;
                    console.log('提交',that.tenantData);
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                  amount  =    Math.round(amount)
                  console.log({uuid,amount},'{uuid,amount}')
                    SaveDepositbalanceapi({uuid,amount}).then(res => {
                        console.log('res',res);
                        MessageSuccess('转移成功');
                        that.clickCancel();
                        that.$emit("closeDrawer");
                    }).finally(() => loading.close());
                }
            });
        },
        async clickCancel() {
            this.tenantData =  {uuid: null, amount: null};
            await this.$emit('clickCancel');
            this.dialogVisible = false;
        },
    },
    created() {
    },
}
</script>

<style scoped>

</style>