import _ from "lodash";
import Layout from "@/layout/index";

// 动态判断权限并通过 addRoutes 动态添加的页面
export const asyncRoutes = [
    {
        path: "/",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "home",
                name: "Home",
                meta: {requireAuth: true, keepAlive: true, title: "首页"},
                component: () => import("@/views/home"),
            }
        ]
    },
    {
        path: "/data-screen",
        name: "data-screen",
        meta: { keepAlive: true, title: "数据大屏"},
        component: () => import("@/views/data-screen"),
    },
    {
        path: "/rental-management",
        component: Layout,
        meta: {title: "租房管理"},
        children: [
            // {
            //     path: "data-screen",
            //     name: "data-screen",
            //     meta: {requireAuth: true, keepAlive: true, title: "数据大屏幕"},
            //     component: () => import( /* webpackChunkName: "rental-management" */  "@/views/rental-management/data-screen"),
            // },
            {
                path: "house-distribution",
                name: "house-distribution",
                meta: {requireAuth: true, keepAlive: true, title: "房源分布"},
                component: () => import( /* webpackChunkName: "rental-management" */  "@/views/rental-management/house-distribution"),
            },
            {
                path: "community-management",
                name: "community-management",
                meta: {title: "小区管理", keepAlive: false},
                component: () => import( /* webpackChunkName: "rental-management" */"@/views/rental-management/community-management"),
            },
            {
                path: "house-resource",
                name: "house-resource",
                meta: {requireAuth: true, keepAlive: false, title: "房源管理"},
                component: () => import( /* webpackChunkName: "rental-management" */  "@/views/rental-management/house-resource"),
            },
            {
                path: "publish-create",
                name: "publish-create",
                meta: {requireAuth: true, keepAlive: true, title: "新增房型"},
                component: () => import("@/views/rental-management/publish-create/index.vue"),
            },
            {
                path: "publish-apartment",
                name: "publish-apartment",
                meta: {requireAuth: true, keepAlive: true, title: "发布列表"},
                component: () => import("@/views/rental-management/publish-apartment/index.vue"),
            },
            {
                path: "booking-management",
                name: "booking-management",
                meta: { keepAlive: true, title: "预约列表"},
                component: () => import( /* webpackChunkName: "rental-management" */"@/views/rental-management/booking-management"),
            },
            /*{
                path: "enterprise-contract",
                name: "enterprise-contract",
                meta: {title: "企业合同", keepAlive: true},
                component: () => import( /!* webpackChunkName: "rental-management" *!/"@/views/rental-management/enterprise-contract"),
            },*/
            {
                path: "individual-contract",
                name: "individual-contract",
                meta: {title: "合同管理", keepAlive: false},
                component: () => import( /* webpackChunkName: "rental-management" */"@/views/rental-management/individual-contract"),
            },
            {
                path: "tenant-management",
                name: "tenant-management",
                meta: {title: "租户管理", keepAlive: false},
                component: () => import( /* webpackChunkName: "rental-management" */"@/views/rental-management/tenant-management"),
            },
            {
                path: "blacklist-tenants", //黑名单二级界面
                name: "blacklist-tenants", //黑名单二级界面
                meta: {title: "黑名单", keepAlive: false},
                component: () => import("@/views/rental-management/tenant-management/blacklist-tenants"),
            },
            /*{
                path: "room-change-case",
                name: "room-change-case",
                meta: { title: "换房工单", keepAlive: true },
                component: () => import( /!* webpackChunkName: "rental-management" *!/"@/views/rental-management/room-change-case"),
            },
            {
                path: "check-out-case",
                name: "check-out-case",
                meta: { title: "退房工单", keepAlive: true },
                component: () => import( /!* webpackChunkName: "rental-management" *!/"@/views/rental-management/check-out-case"),
            },*/
            {
                path: "work-order-management",
                name: "work-order-management",
                meta: {title: "工单管理", keepAlive: true},
                component: () => import("@/views/rental-management/work-order-management"),
            },
            {
                path: "activity-management",
                name: "activity-management",
                meta: { title: "活动管理", keepAlive: true },
                component: () => import("@/views/rental-management/activity-management"),
            },
            {
                path: "coupon-manage",
                name: "coupon-manage",
                meta: { title: "优惠管理", keepAlive: true },
                component: () => import("@/views/rental-management/coupon-manage"),
            },
            {
                path: "claim-details",
                name: "claim-details",
                meta: { title: "领取详情", keepAlive: true },
                component: () => import("@/views/rental-management/claim-details"),
            },
            {
                path: "synthetical-management",
                name: "synthetical-management",
                meta: { title: "综合管理", keepAlive: true },
                component: () => import("@/views/rental-management/synthetical-management"),
            }
        ]
    },
    {
        path: "/property-management",
        component: Layout,
        children: [
            {
                path: "door-management",
                name: "door-management",
                meta: {title: "智能管控", keepAlive: false},
                component: () => import( /* webpackChunkName: "property-management" */"@/views/property-management/door-management"),
            },
            {
                path: "entrance-guard",
                name: "entrance-guard",
                meta: {title: "人脸门禁", keepAlive: true},
                component: () => import("@/views/property-management/entrance-guard"),
            },
            {
                path: "lock-install",
                name: "lock-install",
                meta: {title: "门锁安装", keepAlive: true},
                component: () => import("@/views/property-management/lock-install"),
            },
            // {
            //     path: "repair-management",
            //     name: "repair-management",
            //     meta: {title: "报修管理", keepAlive: true},
            //     component: () => import("@/views/property-management/repair-management"),
            // },
            {
                path: "lock-permission",
                name: "lock-permission",
                meta: {title: "门锁权限", keepAlive: true},
                component: () => import("@/views/property-management/lock-permission"),
            },
            {
                path: "staff-management",
                name: "staff-management",
                meta: {title: "工作人员", keepAlive: true},
                component: () => import("@/views/property-management/staff-management"),
            },
            {
                path: "property-charges",
                name: "property-charges",
                meta: {title: "物业收费", keepAlive: true},
                component: () => import("@/views/property-management/property-charges"),
            },
            {
                path: "parking-charge",
                name: "parking-charge",
                meta: {title: "停车收费", keepAlive: true},
                component: () => import("@/views/property-management/parking-charge"),
            },
        ]
    },
    {
        path: "/bill-management",
        component: Layout,
        children: [
            {
                path: "payment-record",
                name: "payment-record",
                meta: {title: "账单管理", keepAlive: true},
                component: () => import("@/views/bill-management/payment-record"),
            },
            {
                path: "payment-diary",
                name: "payment-diary",
                meta: { title: "收付日记", keepAlive: true },
                component: () => import("@/views/bill-management/payment-diary"),
            },
            {
                path: "invoice-management",
                name: "invoice-management",
                meta: {title: "票据管理", keepAlive: true},
                component: () => import("@/views/bill-management/invoice-management"),
            },
            {
                path: "batch-meter-reading",
                name: "batch-meter-reading",
                meta: {title: "支付流水", keepAlive: true},
                component: () => import("@/views/bill-management/batch-meter-reading"),
            },
            {
                path: "rent-withdrawal",
                name: "rent-withdrawal",
                meta: {title: "租金回笼", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/rentWithdrawal"),
            },
            {
                path: "water-electricity",
                name: "water-electricity",
                meta: {title: "水电统计", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/waterElectricity"),
            },
            {
                path: "deposit",
                name: "deposit",
                meta: {title: "押金统计", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/deposit"),
            },
            {
                path: "ding-jin",
                name: "ding-jin",
                meta: {title: "定金统计", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/dingJIn"),
            },
            {
                path: "rental-rate",
                name: "rental-rate",
                meta: {title: "出租情况", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/rentalRate"),
            },
            {
                path: "rent-statistics",
                name: "rent-statistics",
                meta: {title: "租金统计", keepAlive: true},
                component: () => import("@/views/bill-management/rent-withdrawal/components/rentStatistics"),
            },
            {
                path: "bill-details",
                name: "bill-details",
                meta: {title: "账单详情", keepAlive: false},
                component: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
            },
            {
                path: "multi-platform-payment",
                name: "multi-platform-payment",
                meta: {title: "多户支付", keepAlive: true},
                component: () => import("@/views/bill-management/multi-platform-payment"),
            },
            {
                path: "Invoice-review",
                name: "Invoice-review",
                meta: {title: "发票审核", keepAlive: true},
                component: () => import("@/views/bill-management/Invoice-review"),
            },
            {
                path: "arrears-bill",
                name: "arrears-bill",
                meta: {title: "欠费账单", keepAlive: true},
                component: () => import("@/views/bill-management/arrears-bill"),
            },
        ]
    },
    {
        path: "/statement-management",
        component: Layout,
        children: [
            {
                path: "graphic-statistics",
                name: "graphic-statistics",
                meta: {title: "图表统计", keepAlive: true},
                component: () => import( /* webpackChunkName: "statement-management" */"@/views/statement-management/graphic-statistics/index"),
            },
            {
                path: "statement-statistics",
                name: "statement-statistics",
                meta: {title: "报表统计", keepAlive: true},
                component: () => import( /* webpackChunkName: "statement-management" */"@/views/statement-management/statement-statistics/index"),
            },
        ]
    },
    {
        path: "/test-canvas",
        meta: {title: "测试", keepAlive: true},
        component: () => import("@/views/home/components/test-canvas.vue"),
    },
    {
        // 2023-12-21-公租房子房间合同
        path: "/test-canvas-son",
        meta: {title: "测试", keepAlive: true},
        component: () => import("@/views/home/components/test-canvas-son.vue"),
    },
    {
        path: "/assets-management",
        component: Layout,
        children: [
            {
                path: "assets-warehousing", //资产入库一级界面
                name: "assets-warehousing", //资产入库一级界面
                meta: {title: "资产入库", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/assets-one-level"),
            },
            {
                path: "assets-one-level", //资产入库二级界面
                name: "assets-one-level", //资产入库二级界面
                meta: {title: "资产入库", keepAlive: false},
                component: () => import("@/views/property-management/assets-management/part/assetsWarehousing"),
            },
            {
                path: "assets-details", //资产入库三级界面
                name: "assets-details", //资产入库三级界面
                meta: {title: "资产详情", keepAlive: false},
                component: () => import("@/views/property-management/assets-management/part/assets-details"),
            },
            {
                path: "assets-classification",
                name: "assets-classification",
                meta: {title: "资产分类", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/assetsClassification"),
            },
            {
                path: "supplier",
                name: "supplier",
                meta: {title: "供应厂商", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/supplier"),
            },
            {
                path: "inventory",
                name: "inventory",
                meta: {title: "资产盘点", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/inventory"),
            },
            {
                path: "inventory-details",
                name: "inventory-details",
                meta: {title: "盘点详情", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/components/inventory-details"),
            },
            {
                path: "warehouse",
                name: "warehouse",
                meta: {title: "库房管理", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/warehouse"),
            },
            {
                path: "assetTransfer",
                name: "assetTransfer",
                meta: {title: "资产调拨", keepAlive: true},
                component: () => import("@/views/property-management/assets-management/part/assetTransfer"),
            },
        ]
    },
    {
        path: "/system-management",
        component: Layout,
        children: [
            {
                path: "dictionary-management",
                name: "dictionary-management",
                meta: {title: "数据字典", keepAlive: true},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/dictionary-management/index.vue"),
            },
            {
                path: "personnel-management",
                name: "personnel-management",
                meta: {requireAuth: true, keepAlive: true, title: "人员管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/personnel-management"),
            },
            {
                path: "menu-management",
                name: "menu-management",
                meta: {requireAuth: true, keepAlive: true, title: "菜单管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/menu-management"),
            },
            {
                path: "role-management",
                name: "role-management",
                meta: {requireAuth: true, keepAlive: true, title: "角色管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/role-management"),
            },
            {
                path: "log-management",
                name: "log-management",
                meta: {requireAuth: true, keepAlive: true, title: "日志管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/log-management"),
            },
            // {
            //     path: "message-reminder",
            //     name: "message-reminder",
            //     meta: {requireAuth: true, keepAlive: true, title: "消息提醒"},
            //     component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/message-reminder"),
            // },
            {
                path: "notice-management",
                name: "notice-management",
                meta: {requireAuth: true, keepAlive: true, title: "公告管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/system-management/notice-management"),
            },
            {
                path: "message-list",
                name: "message-list",
                meta: {requireAuth: true, keepAlive: true, title: "消息列表"},
                component: () => import("@/views/system-management/message-list"),
            },
            {
                path: "version-list",
                name: "version-list",
                meta: {requireAuth: true, keepAlive: true, title: "版本列表"},
                component: () => import("@/views/system-management/version-list"),
            },
            {
                path: "shortmessage-log",
                name: "shortmessage-log",
                // meta: { title: "领取详情", keepAlive: true },
                meta: {requireAuth: true, keepAlive: true, title: "短信日志"},
                component: () => import("@/views/system-management/shortmessage-log/index.vue"),
            },
            {
                path: "operation-log",
                name: "operation-log",
                meta: {requireAuth: true, keepAlive: true, title: "操作日志"},
                component: () => import("@/views/system-management/operation-log"),
            },
            {
                path: "message-reminder",
                name: "message-reminder",
                meta: {requireAuth: true, keepAlive: true, title: "消息中心"},
                component: () => import("@/views/system-management/message-reminder"),
            },
            {
                path: "privacy-policy",
                name: "privacy-policy",
                meta: {requireAuth: true, keepAlive: true, title: "隐私政策"},
                component: () => import("@/views/system-management/privacy-policy"),
            },
            {
                path: "message-reminder",
                name: "message-reminder",
                meta: {requireAuth: true, keepAlive: true, title: "消息中心"},
                component: () => import("@/views/system-management/message-reminder"),
            },
            {
                path: "meter-reminder",
                name: "meter-reminder",
                meta: {requireAuth: true, keepAlive: true, title: "电表提醒"},
                component: () => import("@/views/system-management/meter-reminder"),
            },
        ]
    },
    {
        path: "/shops-management",
        component: Layout,
        children: [
            {
                path: "shops-list",
                name: "shops-list",
                meta: {title: "商铺列表", keepAlive: true},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/shops-management/shops-list"),
            },
            {
                path: "shops-lease",
                name: "shops-lease",
                meta: {requireAuth: true, keepAlive: true, title: "商铺出租"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/shops-management/shops-lease"),
            },
            {
                path: "shops-release",
                name: "shops-release",
                meta: {requireAuth: true, keepAlive: true, title: "商铺发布"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/shops-management/shops-release"),
            },
            {
                path: "contract-management",
                name: "contract-management",
                meta: {requireAuth: true, keepAlive: true, title: "合同管理"},
                component: () => import( /* webpackChunkName: "system-management" */ "@/views/shops-management/contract-management"),
            },
            {
                path: "batch-meter-reading",
                name: "shops-management-batch-meter-reading",
                meta: {title: "支付流水", keepAlive: true},
                component: () => import("@/views/shops-management/batch-meter-reading"),
            },
        ]
    },
    {
        path: "*",
        redirect: "/404",
    }
];
