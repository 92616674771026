import request from '@http';

// 获取智能门锁列表
export function getDoorListApi(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/lock/an/lock/list',
        params,
    })
}

// 2022/04/01 王江毅 获取房源列表
export function getBaseinfoApartment(params) {
    return request({
        method: 'GET',
        // url: 'api/gzf/baseinfo/apartment/',
        url: `/api/gzf/baseinfo/apartment/bycummunity/page`,
        params
    })
}

// 2022/04/01 王江毅 发送门锁密码
export function dcLock(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}`,
        params
    })
}

//2022/04/06 王江毅 清空门锁密码 
export function lockClear(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}/clear`,
        params
    })
}

// 2022/04/01 王江毅 获取房间水电读数
export function hydropower(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${uuid}`
    })
}

//2022/04/05 王江毅 获取门锁密码列表
export function getLocks(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/locks/${params.uuid}`,
        params
    })
}

//2022/04/08 王江毅 修改水电表号 api/gzf/dc/hydropower/{uuid}/num
export function setHydropowerNum(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/num`,
        params
    })
}

// 2022/04/12 王江毅 设置密码
export function lockTemporary(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}/temporary`,
        params
    })
}

// 2022/04/13 王江毅 水电表抄表回显
// export function hydropowerBill(params) {
//     return request({
//         method: 'GET',
//         url: `/api/gzf/dc/hydropower/${params.uuid}/bill`,
//         params
//     })
// }
// 2024/09/15 张晓瑜 水电表抄表回显
export function hydropowerBill(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/billNew`,
        params
    })
}


// 2022/04/13 王江毅 水电表抄表
export function hydropowerSettlement(data) {
    return request({
        method: 'post',
        url: `/api/gzf/dc/hydropower/${data.uuid}/settlementNew`,
        data
    })
}

// 2022/04/14 王江毅 水电表抄表数据列表 /api/gzf/dc/hydropower/{uuid}/list
export function getHydropowerList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/list`,
        params
    })
}
// 2022/04/20 王江毅 水电表关闸 api/gzf/dc/hydropower/{uuid}/close
export function getHydropowerClose(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/close`,
        params
    })
}

// 2022/04/20 王江毅 密码不可用
export function passDel(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/pass/${uuid}/del`
    })
}

//  2022/05/27 王江毅 开锁记录
export function openLocksLog(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/open/locks/${uuid}`,
        params
    })
}

// 2022/09/05 王江毅 根据水电表记录查账单
export function byHyUuid(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/payBill/byHyUuid`,
        params
    })
}

// 2022/10/08 王江毅 添加临时密码获取权限
export function getMenu(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/system/menu/password/save/${uuid}`
    })
}
// 2023/03/09 王江毅 换表信息
export function getDeviceInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/exchange/device/info`,
        params
    })
}
// 2023/03/09 王江毅 换表提交
export function exchangeDevice(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/exchange/device`,
        data,
    })
}

// 2023/03/09 王江毅 换锁提交
export function deviceLock(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/exchange/device/lock`,
        params,
    })
}

// 2023/08/18 张晓瑜 设置密码(iotTag = 1)
export function iotTagLockTemporary(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/bzf/lock/online/password`,
        params
    })
}

// 2023/09/18 王江毅 抄表记录数据异常时提交修改
export function dcHydropowerUpdate(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/update`,
        params
    })
}

// 2023/11/15 王江毅 启禁用管理卡
export function enableCardAPI(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/lock/update/key/state`,
        params
    })
}

// 2023/11/15 王江毅 同步门禁卡钥匙
export function synchronizationKeyInfoAPI(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/lock/synchronization/key/info`,
        params
    })
}

//  2023-3-13-陈守亮-操作记录
export function tableoperatingRecordapi(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/lock/operating/log/api/web/list`,
        params
    })
}
// 2024/06/11 张晓瑜新增绑定电表
export function bindingElectricityMeters(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/new/meter`,
        params
    })
}
//  2024-7-23-张晓瑜-控闸记录
export function getcontrolGateRecordapi(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/meter/log/page`,
        params
    })
}
//  2024-7-23-张晓瑜-抄表记录
export function getmanMeterRecordapi(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/hydropowerManLog/manMeterRecord`,
        params
    })
}

// 2024-11-06 张晓瑜判断房间是否是套房
export function getApartmentlMainUuidApi(uuid) {
    return request({
        method: 'Get',
        url: '/api/gzf/baseinfo/apartment/one/' + uuid,
    });
}
// 2024/11/06 张晓瑜 水电表合计计算
export function getCalculationData(data) {
    return request({
        method: 'post',
        url: `/api/gzf/dc/hydropower/getCalculationData/${data.uuid}`,
        data                                   
        
    })
}
// 2024/12/30 张晓瑜 水电表电表回显计算金额
export function getElectCalculationAmount(data) {
    return request({
        method: 'post',
        url: `/api/gzf/dc/hydropower/elect_calculate_amount`,
        data                                   
        
    })
}
// 2024/12/30 张晓瑜新增数据库获取电表数据回显(页面初始数据)
export function getElectDatabaseAPI(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/elect_database_meter_data_show`,
        params
    })
}
// 2024/12/27 张晓瑜新增电表实时抄表回显(页面初始数据)
export function getElectMetereadingAPI(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/elect_real_time_meter_reading_show`,
        params
    })
}
// 2024/12/30 张晓瑜新增水电表抄表提交
export function hydropowerSaveAPI(data) {
    return request({
        method: 'post',
        url: '/api/gzf/dc/hydropower/elect_meter_reading_save',
        data
    })
}
// 2024/12/30 张晓瑜新增换表一天之内无抄表提示
export function getChangeElectMeterTipAPI(uuid) {
    return request({
        method: 'get',
        url: '/api/gzf/dc/hydropower/changeElectMeterTip/' + uuid,
    })
}
// 2024/12/31 张晓瑜新增换表回显接口
export function changeElectMeterShowAP(uuid) {
    return request({
        method: 'GET',
        url:  '/api/gzf/dc/hydropower/changeElectMeterShow/' + uuid,
    })
}
// 2024/12/31 张晓瑜新增换表确定接口
export function changeElectMeterSaveAPI(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/hydropower/changeElectMeterSave`,
        data,
    })
}
